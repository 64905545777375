import React, { useMemo } from "react";
import { marked } from "marked";
import DOMPurify from "dompurify";

interface Props {
  text: string;
}

export default function SafeMarkdown(props: Props) {
  const html = useMemo(() => {
    return DOMPurify.sanitize(marked.parseInline(props.text));
  }, [props.text]);

  return <div dangerouslySetInnerHTML={{ __html: html }} />;
}
