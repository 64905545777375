import useProfile from "@/hooks/useProfile";
import IconLogOut from "@/icons/IconLogOut";
import IconSettingsGear from "@/icons/IconSettingsGear";
import React from "react";
import { Link } from "react-router-dom";

interface Props {
  onClose(): void;
}

export default function MobileProfileMenu(props: Props) {
  const { profile } = useProfile();

  return (
    <div className="fixed top-0 w-full h-screen bg-black bg-opacity-50 z-20 text-slate-gray-600">
      <div className="bg-white">
        <div
          className="relative flex flex-col justify-center items-center py-4 border-b"
          onClick={() => props.onClose()}
        >
          <button className="absolute right-0 top-0 h-14 w-14 text-center text-xl">
            <i className="fa fa-times"></i>
          </button>
          <div className="relative mb-4">
            <div>
              <img
                src={profile.picture}
                alt=""
                className="h-14 w-14 rounded-full"
              />
            </div>
            {/* <button
              className="absolute bg-white h-7 w-7 rounded-full border text-sm text-slate-gray-600"
              style={{ bottom: -6, right: -6 }}
            >
              <i className="fa fa-camera"></i>
            </button> */}
          </div>
          <div className="font-semibold">{profile.email}</div>
        </div>
        <div>
          <div className="flex items-center h-12 px-4">
            <div className="flex-none w-10">
              <div className="w-3 h-3 bg-success-green-600 rounded-full"></div>
            </div>
            <div className="flex-1">Available</div>
            <div className="flex-none ml-auto">
              <i className="fa-solid fa-angle-right"></i>
            </div>
          </div>
          <Link
            to="/profile"
            onClick={() => {
              props.onClose();
            }}
            className="flex items-center h-12 px-4"
          >
            <div className="flex-none w-10">
              <IconSettingsGear className="h-6" />
            </div>
            <div className="flex-1">Profile settings</div>
            <div className="flex-none ml-auto">
              <i className="fa-solid fa-angle-right"></i>
            </div>
          </Link>
          <Link to="/logout" className="flex items-center h-12 px-4">
            <div className="flex-none w-10">
              <IconLogOut className="h-6" />
            </div>
            <div className="flex-1">Log out</div>
          </Link>
        </div>
      </div>
    </div>
  );
}
