import React from "react";
import { IconProps } from "./types";

function IconArrowBack1(props: IconProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      {...props}
    >
      <g>
        <g>
          <path
            fill="currentColor"
            d="M13.83 19a1 1 0 01-.78-.37l-4.83-6a1 1 0 010-1.27l5-6a1 1 0 011.54 1.28L10.29 12l4.32 5.36a1 1 0 01-.78 1.64z"
            data-name="Path 555"
          ></path>
        </g>
      </g>
    </svg>
  );
}

export default IconArrowBack1;
