import React from "react";
import { IconProps } from "./types";

function IconMenuHamburger(props: IconProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      {...props}
    >
      <g fill="currentColor">
        <rect
          width="18"
          height="2"
          data-name="Rectangle 583"
          rx="0.95"
          transform="translate(3 11)"
        ></rect>
        <rect
          width="18"
          height="2"
          data-name="Rectangle 584"
          rx="0.95"
          transform="translate(3 16)"
        ></rect>
        <rect
          width="18"
          height="2"
          data-name="Rectangle 585"
          rx="0.95"
          transform="translate(3 6)"
        ></rect>
      </g>
    </svg>
  );
}

export default IconMenuHamburger;
