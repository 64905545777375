import React from "react";
import { IconProps } from "./types";

function SendMessageIcon(props: IconProps) {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g id="icon">
        <path
          id="Vector (Stroke)"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M2.63122 2.27636C2.74896 2.15678 2.92283 2.11144 3.08397 2.15831C7.11005 3.32924 10.9066 5.17898 14.3101 7.62779C14.4293 7.71357 14.5 7.85146 14.5 7.99833C14.5 8.14519 14.4293 8.28309 14.3101 8.36886C10.9068 10.8176 7.11046 12.6674 3.08459 13.8383C2.92346 13.8852 2.7496 13.8399 2.63185 13.7203C2.5141 13.6008 2.47146 13.4262 2.5208 13.2658L4.1411 7.99835L2.5202 2.73089C2.47084 2.57049 2.51348 2.39594 2.63122 2.27636ZM4.95587 8.45481L3.64579 12.7138C7.06498 11.6305 10.3031 10.0412 13.2521 7.99834C10.3029 5.95544 7.06464 4.36608 3.64528 3.2829L4.95583 7.54185H9.18351C9.43562 7.54185 9.63999 7.74622 9.63999 7.99833C9.63999 8.25044 9.43562 8.45481 9.18351 8.45481H4.95587Z"
          fill="white"
        />
      </g>
    </svg>
  );
}

export default SendMessageIcon;
