import Modal from "@velaro/velaro-shared/src/components/Modal";
import { Note } from "@/types";
import React from "react";
import AddNoteForm from "./AddNoteForm";
import NoteListItem from "./NoteListItem";

interface Props {
  title: string;
  subTitle?: string;
  onClose(): void;
  notes: Note[];
  onAddNote(text: string): void;
}

export default function ViewAllNotesModal(props: Props) {
  return (
    <Modal show={true}>
      <Modal.Header title={props.title} onClose={() => props.onClose()} />
      <Modal.Body>
        {props.subTitle && (
          <div className="font-semibold m-3">{props.subTitle}</div>
        )}
        <div className="flex flex-col space-y-2 m-3 p-3 border border-cornflower-blue-100 rounded">
          {props.notes.map((note) => (
            <div key={note.id}>
              <NoteListItem {...note} />
            </div>
          ))}
        </div>
        <div className="m-3">
          <AddNoteForm onSubmit={props.onAddNote} />
        </div>
      </Modal.Body>
    </Modal>
  );
}
