import React from "react";
import Avatar from "@/components/Avatar";
import SafeMarkdown from "@/components/SafeMarkdown";
import FormattedTime from "./FormattedTime";
import { ConversationSource } from "@/types";
import MessageItem from "./MessageItem";
import MessageAttachments from "./MessageAttachments";
import useConversationSourceLabel from "@/hooks/useConversationSourceLabel";
import { BotMessageGroupType } from "@/reducers/messageGroupReducer";

interface Props {
  item: BotMessageGroupType;
  conversationSource: ConversationSource;
  onImageLoad(): void;
}

export default function BotMessageGroup(props: Props) {
  const getSourceLabel = useConversationSourceLabel();

  const conversationSourceLabel = getSourceLabel(props.conversationSource);

  return (
    <div className="flex flex-col justify-start items-start w-full overflow-hidden ">
      {props.item.messages.map((message) => (
        <div
          key={message.id}
          className="flex flex-row justify-start items-start w-full overflow-hidden py-4 "
        >
          <div className="mr-4 flex-none">
            <Avatar
              name={props.item.user.name}
              url={props.item.user.picture}
              size={40}
            />
          </div>
          <div className="flex-1 overflow-hidden">
            <div
              key={message.id}
              className="flex flex-col items-start max-w-full"
            >
              {message.text && (
                <MessageItem className="pr-24">
                  <div className=" dark:bg-stone-200 dark:text-slate-gray-100 rounded-md rounded-bl-2xl ">
                    <div className="flex text-gray-400 text-sm font-normal font-['Rubik']">
                      <div className="mr-2">{message.bot?.name}</div>

                      <div>
                        <FormattedTime isoDate={message.timestamp} />
                      </div>
                    </div>
                    <div className="whitespace-pre-wrap break-words">
                      <SafeMarkdown text={message.text} />
                    </div>
                  </div>
                </MessageItem>
              )}
              <MessageAttachments
                attachments={message.attachments}
                onImageLoad={props.onImageLoad}
              />
            </div>
          </div>
        </div>
      ))}
    </div>
  );
}
