import useTranslations from "@/hooks/useTranslations";
import { Attribute } from "@/types";
import React from "react";

interface Props {
  attributes: Attribute[];
}
export default function Attributes({ attributes }: Props) {
  const translation = useTranslations();
  return (
    <div>
      <div className="mb-2 font-bold">
        {translation.key("conversation_attributes")}
      </div>
      <div>
        {attributes.map((a, i) => (
          <div className="pb-2" key={i}>
            <div>{a.name}</div>
            <div className="font-bold">{a.value}</div>
          </div>
        ))}
      </div>
    </div>
  );
}
