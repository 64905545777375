import React from "react";
import { IconProps } from "./types";

function IconPersonGroup(props: IconProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      {...props}
    >
      <g fill="currentColor" data-name="Group 1013">
        <path
          d="M12.817 12.846l-1.855-1.524a.892.892 0 00-1.254.123.9.9 0 00.123 1.255l1.855 1.522a1.558 1.558 0 01.57 1.206v5.909h1.782v-5.909a3.329 3.329 0 00-1.221-2.582"
          data-name="Path 1805"
        ></path>
        <path
          d="M4.329 11.445a.884.884 0 00-.6-.321.865.865 0 00-.652.2l-1.856 1.522A3.329 3.329 0 000 15.428v5.909h1.783v-5.909a1.552 1.552 0 01.569-1.205L4.207 12.7a.89.89 0 00.122-1.254"
          data-name="Path 1806"
        ></path>
        <path
          d="M7.019 1.788a4.743 4.743 0 104.743 4.743 4.749 4.749 0 00-4.743-4.743M9.98 6.531a2.96 2.96 0 11-2.961-2.96 2.964 2.964 0 012.961 2.96"
          data-name="Path 1807"
        ></path>
        <path
          d="M22.779 15.509l-1.855-1.524a.892.892 0 00-1.254.123.9.9 0 00.123 1.255l1.855 1.522a1.558 1.558 0 01.57 1.206V24H24v-5.909a3.329 3.329 0 00-1.221-2.582"
          data-name="Path 1808"
        ></path>
        <path
          d="M16.981 4.451a4.743 4.743 0 104.743 4.743 4.749 4.749 0 00-4.743-4.743m2.961 4.743a2.96 2.96 0 11-2.961-2.96 2.964 2.964 0 012.961 2.96"
          data-name="Path 1809"
        ></path>
      </g>
    </svg>
  );
}

export default IconPersonGroup;
