import React from "react";
import Panel from "../Panel";
import useSurveySubmissions from "@/hooks/useSurveySubmissions";
import LoadingSpinner from "@velaro/velaro-shared/src/components/LoadingSpinner";
import { SurveyAnswer } from "@/types";
import useTranslations from "@/hooks/useTranslations";

interface Props {
  conversationBaseId: number;
}

function truncate(str: string, n: number) {
  return str.length > n ? str.slice(0, n - 1) + "..." : str;
}

export default function SurveySubmissions(props: Props) {
  const { data: surveySubmissions, loading } = useSurveySubmissions(
    props.conversationBaseId
  );
  const translation = useTranslations();

  if (loading) {
    return <LoadingSpinner />;
  }

  if (surveySubmissions.length === 0) {
    return (
      <Panel title={translation.key("surveys")}>
        <div className="text-sm px-4 mb-4">
          <span className="italic font-light">
            {translation.key("no_survey_submissions_label")}
          </span>
        </div>
      </Panel>
    );
  }

  function renderSurveyAnswer(a: SurveyAnswer, i: number) {
    return (
      <div key={i} className="p-4">
        <div>{a.question}</div>
        <div className="font-bold"> {a.answer}</div>
      </div>
    );
  }

  return (
    <>
      {surveySubmissions.map((submission, i) => (
        <Panel key={i} title={truncate(submission.name, 40)}>
          {submission.answers.map(renderSurveyAnswer)}
        </Panel>
      ))}
    </>
  );
}
