import { UserStatusType } from "@/types";
import { createContext } from "react";

interface Props {
  status: UserStatusType;
  setStatus(status: UserStatusType): void;
}

export const defaultProps: Props = {
  status: "Unavailable",
  setStatus: () => {}
};

const StatusContext = createContext<Props>(defaultProps);

export default StatusContext;
