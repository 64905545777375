import React from "react";
import { NavLink } from "react-router-dom";
import { ConversationListItem } from "@/types";
import Avatar from "@/components/Avatar";
import useUnreadMessages from "@/hooks/useUnreadMessages";
import FormattedTime from "./FormattedTime";
import useConversationSourceLabel from "@/hooks/useConversationSourceLabel";
import format from "date-fns/format";
import { useDate } from "@/hooks/useDate";
import IconAutomation from "@/icons/IconAutomation";
import useTranslations from "@/hooks/useTranslations";
import IconClock from "@/icons/IconClock";

interface Props {
  conversation: ConversationListItem;
}

export default function ConversationItem({ conversation }: Props) {
  const unreadMessages = useUnreadMessages();
  const unreadMessageCount = unreadMessages.getCount(conversation.id);
  const { date } = useDate();
  const translation = useTranslations();

  const getSourceLabel = useConversationSourceLabel();

  const conversationSourceLabel = getSourceLabel(conversation.source);

  const hasUnreadMessages = unreadMessageCount > 0;

  function FormattedDate({ isoDate }: { isoDate: string }) {
    const date = new Date(isoDate);
    const now = new Date();
    const formattedDays = parseInt(format(date, "yyMMdd"));
    const formattedNowDays = parseInt(format(now, "yyMMdd"));
    const translation = useTranslations();
    if (formattedDays === formattedNowDays) {
      return <>{translation.key("today")}</>;
    }
    if (formattedNowDays - formattedDays === 1) {
      return <>{translation.key("yesterday")}</>;
    }
    const str = format(date, "MM-dd-yy");
    return <>{str}</>;
  }

  function FormattedWaitTime(currentDate: Date, isoDate: string) {
    const date = new Date(isoDate);
    const now = currentDate;
    const duration = now.getTime() - date.getTime();
    const translation = useTranslations();
    let hours = Math.floor(duration / 3600000);
    let minutes = Math.round(((duration % 86400000) % 3600000) / 60000);
    let minutesText = " " + translation.key("minutes");
    let hoursText = " " + translation.key("hours");
    if (minutes === 1) {
      minutesText = " " + translation.key("minute");
    }
    if (hours === 1) {
      hoursText = " " + translation.key("hour");
    }
    if (minutes === 60) {
      hours++;
      minutes = 0;
    }
    if (hours <= 0) {
      return translation.key("waiting") + " " + minutes + minutesText;
    }
    return (
      translation.key("waiting") +
      " " +
      hours +
      hoursText +
      " " +
      minutes +
      minutesText
    );
  }

  function FormattedUnassignTime(
    isoDate: string,
    unassign: number,
    currentDate: Date
  ) {
    const date = new Date(isoDate);
    const expirationDate = date.getTime() + unassign * 60000;
    const now = currentDate;
    const duration = expirationDate - now.getTime();
    const hours = Math.floor(duration / 3600000);
    const minutes = Math.round(((duration % 86400000) % 3600000) / 60000);
    const translation = useTranslations();
    let minutesText = " " + translation.key("minutes");
    let hoursText = " " + translation.key("hours");
    if (minutes === 1) {
      minutesText = " " + translation.key("minute");
    }
    if (hours === 1) {
      hoursText = " " + translation.key("hour");
    }
    if (hours <= 0) {
      return (
        translation.key("conversation_will_be_unassigned") +
        " " +
        minutes +
        minutesText
      );
    }
    return (
      translation.key("conversation_will_be_unassigned") +
      " " +
      hours +
      hoursText +
      minutes +
      minutesText
    );
  }

  return (
    <NavLink
      key={conversation.id}
      to={`/conversations/${conversation.id}`}
      className={({ isActive }) =>
        isActive
          ? "flex bg-cornflower-blue-50 dark:bg-stone-200 border-b"
          : "flex border-b dark:border-stone-100"
      }
    >
      <div className="w-full p-3 text-slate-gray-600 dark:text-white">
        <div className="flex">
          <div className="flex">
            <div className="mr-3">
              <Avatar
                url={conversation.pic}
                name={conversation.name}
                size={38}
              />
            </div>
            <div>
              <div className={hasUnreadMessages ? "font-bold" : ""}>
                {conversation.name}
              </div>
              <div className="text-xs text-slate-gray-400 dark:text-white">
                Via {conversationSourceLabel}
              </div>
            </div>
          </div>
          <div className="ml-auto">
            <div className="flex flex-row justify-end">
              <div className="flex flex-col text-sm font-semibold">
                <FormattedTime isoDate={conversation.startTimestamp} />
              </div>
            </div>
            <div className="flex flex-row justify-end">
              <div className="flex flex-col" style={{ fontSize: 12 }}>
                <FormattedDate isoDate={conversation.startTimestamp} />
              </div>
            </div>

            <div className="flex justify-end items-center">
              {/* <div>
                <i className="fa fa-star"></i>
              </div> */}
              {unreadMessageCount > 0 && (
                <div
                  className="bg-cornflower-blue-500 text-white w-4 h-4 rounded-full flex items-center justify-center"
                  style={{ fontSize: 11 }}
                >
                  {unreadMessageCount}
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="flex flex-row ">
          <div
            className={`mt-2 text-sm whitespace-nowrap overflow-hidden overflow-ellipsis w-3/4 ${
              hasUnreadMessages ? "font-bold" : ""
            }`}
          >
            {conversation.lastMessageText}
          </div>
          <div className="flex justify-end ml-auto">
            {conversation.automationJobId &&
              conversation.automationJobStatus === "in_progress" && (
                <div
                  className="flex flex-col p-1 bg-cornflower-blue-100 text-cornflower-blue-500 rounded-md font-semibold mr-2"
                  style={{ fontSize: 12 }}
                  title={translation.key("ongoing_bot_conversation")}
                >
                  <IconAutomation className="h-5 w-5" />
                </div>
              )}
            {conversation.automationJobId &&
              conversation.automationJobStatus !== "in_progress" &&
              !conversation.assignedUserId && (
                <div
                  className="flex flex-col p-1 bg-slate-100 text-slate-400 rounded-md font-semibold mr-2"
                  style={{ fontSize: 12 }}
                  title={translation.key("prior_bot_conversation")}
                >
                  <IconAutomation className="h-5 w-5" />
                </div>
              )}
            {/* Conversation is unassigned and waiting a response from an agent */}
            {!conversation.assignedUserId &&
              conversation.assignmentStatus === "Incoming" && (
                <div
                  className="flex flex-col p-1 bg-yellow-100 text-yellow-500 rounded-md font-semibold justify-center"
                  style={{ fontSize: 12 }}
                  title={FormattedWaitTime(date, conversation.startTimestamp)}
                >
                  <IconClock className="h-5 w-5" />
                </div>
              )}
            {/* Conversation is assigned and waiting a response from an agent*/}
            {conversation.assignedUserId &&
              !conversation.firstResponseTimestamp &&
              !conversation.unassignWarningTimestamp &&
              conversation.assignmentStatus === "Assigned" && (
                <div
                  className="flex flex-col p-1 bg-crimson-red-100 text-crimson-red-500 rounded-md font-semibold justify-center"
                  style={{ fontSize: 12 }}
                  title={FormattedWaitTime(date, conversation.startTimestamp)}
                >
                  {<IconClock className="h-5 w-5" />}
                </div>
              )}
            {/* Conversation will be unassigned warning*/}
            {conversation.assignedUserId &&
              conversation.unassignWarningTimestamp &&
              conversation.assignmentStatus === "Assigned" && (
                <div
                  className="flex flex-col p-1 bg-crimson-red-100 text-crimson-red-500 rounded-md font-semibold"
                  style={{ fontSize: 12 }}
                  title={FormattedUnassignTime(
                    conversation.unassignWarningTimestamp,
                    conversation.unassignTime,
                    date
                  )}
                >
                  <IconClock className="h-5 w-5" />
                </div>
              )}
          </div>
        </div>
      </div>

      {/* <div className="p-3">
        <div className="relative">
          <Avatar
            url={conversation.pic}
            name={conversation.name}
            size="2.5rem"
          />
        </div>
      </div>
      <div className="py-3 flex-1 overflow-hidden">
        <div>{conversation.name}</div>
        <div>VIA {conversation.source}</div>
        <div className="text-sm whitespace-nowrap overflow-hidden overflow-ellipsis">
          {conversation.lastMessageText}
        </div>
        <div className="text-xs whitespace-nowrap overflow-hidden overflow-ellipsis">
          {new Date(conversation.lastMessageTimestamp).toLocaleString()}
        </div>
      </div>
      <UnreadMessagesCount count={unreadMessages.getCount(conversation.id)} /> */}
    </NavLink>
  );
}
