import React from "react";
import { CannedResponse } from "@/types";

interface Props {
  token: string;
  cannedResponses: CannedResponse[];
  onSelect(response: CannedResponse): void;
}

export default function CannedResponseSelect(props: Props) {
  const scrollContainer = React.useRef<HTMLDivElement>(null);

  const [selectedIndex, setSelectedIndex] = React.useState(0);

  const filteredCannedResponses = React.useMemo(() => {
    const token = props.token.replace("/", "");

    return props.cannedResponses.filter((cannedResponse) => {
      return cannedResponse.shortCode.indexOf(token) === 0;
    });
  }, [props.cannedResponses, props.token]);

  // handle up, down, enter keydown events

  React.useEffect(() => {
    function handleEnter() {
      props.onSelect(filteredCannedResponses[selectedIndex]);
    }

    function handleUp() {
      setSelectedIndex((oldState) => Math.max(0, oldState - 1));
    }

    function handleDown() {
      setSelectedIndex((oldState) =>
        Math.min(filteredCannedResponses.length - 1, oldState + 1)
      );
    }

    function handle(e: any) {
      switch (e.key) {
        case "Enter":
          handleEnter();
          break;
        case "ArrowUp":
          handleUp();
          break;
        case "ArrowDown":
          handleDown();
          break;
      }
    }

    document.addEventListener("keydown", handle);
    return () => document.removeEventListener("keydown", handle);
  }, [filteredCannedResponses, props, selectedIndex]);

  // handle scrolling the parent when the index changes.
  React.useEffect(() => {
    const container = scrollContainer.current;

    if (!container) {
      return;
    }

    const selectedElement = container.children[selectedIndex];
    selectedElement.scrollIntoView();
  }, [selectedIndex]);

  if (filteredCannedResponses.length === 0) {
    return null;
  }

  return (
    <div className="relative">
      <div
        ref={scrollContainer}
        className="absolute bottom-0 left-0 right-0 bg-white shadow-lg border rounded my-3 mx-10 max-h-64 overflow-auto cursor-pointer"
      >
        {filteredCannedResponses.map((response, index) => {
          let className =
            "block w-full text-left px-3 py-2 text-sm truncate hover:bg-slate-gray-100";

          if (index === selectedIndex) {
            className += " bg-slate-gray-100";
          }

          return (
            <div
              className={className}
              key={response.id}
              onClick={() => {
                props.onSelect(response);
              }}
            >
              <strong>{response.shortCode}</strong> - {response.messageText}
            </div>
          );
        })}
      </div>
    </div>
  );
}
