import Modal from "@velaro/velaro-shared/src/components/Modal";
import useApi from "@/hooks/useApi";
import useNetSuiteSubsidiaries from "@/hooks/useNetSuiteSubsidiaries";
import { Contact, Tag } from "@/types";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import useToastAlert from "@/hooks/useToastAlert";
import LoadingSpinner from "@velaro/velaro-shared/src/components/LoadingSpinner";
import { Option } from "react-multi-select-component";
import Select, { SingleValue } from "react-select";
import { Severity } from "@velaro/velaro-shared/src/components/ToastAlert";

interface Props {
  contact: Contact;
  onClose(): void;
  recordType: string;
  selectedConfig: number;
}

interface NetSuiteListItem {
  value: number;
  text: string;
}

interface NetSuiteCreationField {
  netSuiteField: string;
  value: string;
  listItems?: Option[];
  id: string;
}

export default function NetSuiteModal(props: Props) {
  const [contact, setContact] = useState(props.contact);
  if (contact !== props.contact) {
    setContact(props.contact);
  }
  const [loading, setLoading] = useState(false);
  const [creationFields, setCreationFields] = useState<NetSuiteCreationField[]>(
    []
  );
  const api = useApi();
  const toastAlert = useToastAlert();

  // This is the REST way of creating an object - since not all objects are supported via REST yet, we've commented this for now
  // async function saveCustomer() {
  //   const resp = await api.messaging.post("NetSuite/Integrations/SaveRecord", {
  //     id: props.contact.id,
  //     email: props.contact.email,
  //     phone: props.contact.phone,
  //     name: props.contact.name,
  //     subsidiary: subsidiary
  //   });
  // }
  async function saveCustomer() {
    const fields: { name: any; value: any }[] = [];
    creationFields.forEach((nsField) => {
      const field = { name: nsField.netSuiteField, value: nsField.value };
      fields.push(field);
    });
    const resp = await api.messaging.post(
      `NetSuite/Integrations/RelayToRestlet/${props.selectedConfig}`,
      {
        relayType: "create",
        recordType: [props.recordType],
        fields: fields
      }
    );
    if (!resp.ok) {
      toastAlert.displayToast(
        Severity.Error,
        "Unexpected Error on field creation. Please see integration log for additional details"
      );
    }
  }

  useEffect(() => {
    async function getCreationFields(searchRecordType: string) {
      setLoading(true);
      setCreationFields([]);
      const resp = await api.messaging.post("NetSuite/Search/ShowOnCreate", {
        searchRecordType: [searchRecordType],
        entityId: contact.id,
        selectedConfig: props.selectedConfig
      });

      if (resp.ok) {
        const json = (await resp.json()) as any[];
        const mappedFields: NetSuiteCreationField[] = json.map((item) => {
          const netSuiteField = item.netSuiteField;
          const value = item.value;
          const id = item.id;

          // Extract list items
          const listItems: Option[] = [];

          // Check if listItems property exists
          if (item.listItems) {
            for (const recordType in item.listItems) {
              if (
                Object.prototype.hasOwnProperty.call(item.listItems, recordType)
              ) {
                for (const field in item.listItems[recordType]) {
                  if (
                    Object.prototype.hasOwnProperty.call(
                      item.listItems[recordType],
                      field
                    )
                  ) {
                    const fieldItems = item.listItems[recordType][field].map(
                      (item: NetSuiteListItem) => ({
                        value: item.value,
                        label: item.text.replace(/&nbsp;/gi, "")
                      })
                    );
                    listItems.push(...fieldItems);
                  }
                }
              }
            }
          }

          return {
            netSuiteField,
            value,
            listItems,
            id
          };
        });
        setCreationFields(mappedFields);
        setLoading(false);
      } else {
        toastAlert.displayToast(Severity.Error, "Failed to fetch fields");
      }
    }
    getCreationFields(props.recordType);
  }, [
    api.messaging,
    contact.id,
    props.recordType,
    props.selectedConfig,
    toastAlert
  ]);

  const updateCreationField = (
    index: number,
    creationField: NetSuiteCreationField
  ) => {
    const clone = [...creationFields];
    clone[index] = creationField;
    setCreationFields(clone);
  };

  return (
    <Modal show={true} maxWidth="max-w-3xl">
      <Modal.Header
        title={"Add NetSuite " + props.recordType}
        onClose={() => props.onClose()}
      />
      <Modal.Body>
        {loading && <LoadingSpinner />}
        <div
          className={`flex border-t border-b p-2 text-stone-600 dark:text-white dark:border-stone-200 w-full`}
        >
          <div className="flex flex-wrap overflow-none w-full">
            <div className="w-full">
              {creationFields.map((field, i) => (
                <div className="m-1 flex flex-col items-start w-full" key={i}>
                  {field.netSuiteField && (
                    <div className="flex items-center w-1/2">
                      <div className="pr-2">{field.netSuiteField}</div>
                      {field.listItems !== null &&
                      field.listItems !== undefined &&
                      field.listItems.length > 0 ? (
                        <Select
                          className="w-full h-max"
                          options={field.listItems}
                          value={field.listItems.find(
                            (option) => option.value === field.value
                          )}
                          onChange={(e) =>
                            updateCreationField(i, {
                              ...field,
                              value: e?.value
                            })
                          }
                        />
                      ) : (
                        <input
                          type="text"
                          defaultValue={field.value}
                          onChange={(e) =>
                            updateCreationField(i, {
                              ...field,
                              value: e.currentTarget.value
                            })
                          }
                          className="m-1 border w-1/2"
                        ></input>
                      )}
                    </div>
                  )}
                </div>
              ))}
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div className="flex items-center pt-3">
          <div className="ml-auto">
            <button
              className="btn btn-green"
              onClick={() => {
                saveCustomer();
                props.onClose();
              }}
            >
              Save
            </button>
          </div>
        </div>
      </Modal.Footer>
    </Modal>
  );
}
