import React from "react";
import { IconProps } from "./types";

function IconTinyCamera(props: IconProps) {
  return (
    <svg
      id="tiny-camera-icon"
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      <g
        id="Ellipse_42"
        data-name="Ellipse 42"
        fill="#fff"
        stroke="#e3e7e9"
        strokeWidth="1"
      >
        <circle cx="12" cy="12" r="12" stroke="none" />
        <circle cx="12" cy="12" r="11.5" fill="none" />
      </g>
      <g id="Group_206" data-name="Group 206" transform="translate(4.8 5.486)">
        <path
          id="Path_323"
          data-name="Path 323"
          d="M13.657,5.743H11.6V4.714A1.714,1.714,0,0,0,9.886,3H7.829A1.714,1.714,0,0,0,6.114,4.714V5.743H4.057A2.057,2.057,0,0,0,2,7.8v5.486a2.057,2.057,0,0,0,2.057,2.057h9.6a2.057,2.057,0,0,0,2.057-2.057V7.8A2.057,2.057,0,0,0,13.657,5.743ZM7.486,4.714a.343.343,0,0,1,.343-.343H9.886a.343.343,0,0,1,.343.343V5.743H7.486Zm6.857,8.571a.686.686,0,0,1-.686.686h-9.6a.686.686,0,0,1-.686-.686V7.8a.686.686,0,0,1,.686-.686h9.6a.686.686,0,0,1,.686.686Z"
          transform="translate(-2 -3)"
          fill="#353e44"
        />
        <path
          id="Path_324"
          data-name="Path 324"
          d="M10.9,10.5a2.4,2.4,0,1,0,2.4,2.4,2.4,2.4,0,0,0-2.4-2.4Zm0,3.429A1.029,1.029,0,1,1,11.929,12.9,1.029,1.029,0,0,1,10.9,13.929Z"
          transform="translate(-4.043 -5.357)"
          fill="#353e44"
        />
      </g>
    </svg>
  );
}

export default IconTinyCamera;
