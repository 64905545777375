import React from "react";
import { IconProps } from "./types";

function IconNotificationSettings(props: IconProps) {
  return (
    <svg
      id="notifications-settings-bell"
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="currentColor"
      {...props}
    >
      <rect
        id="Rectangle_603"
        data-name="Rectangle 603"
        width="23.476"
        height="24"
        opacity="0"
      />
      <path
        id="Path_2581"
        data-name="Path 2581"
        d="M22.241,6.632v.01l-.413-1.358a.146.146,0,0,0-.013-.04A1.186,1.186,0,0,0,20.31,4.47l-.18.059a.9.9,0,0,1-.838-.141l-.058-.043a.958.958,0,0,1-.363-.771V3.423a1.3,1.3,0,0,0-.359-.905,1.172,1.172,0,0,0-.844-.361H16.325a1.236,1.236,0,0,0-1.206,1.255v.129a1.057,1.057,0,0,1-.385.815l-.068.052a1,1,0,0,1-.939.157,1.109,1.109,0,0,0-.886.064,1.169,1.169,0,0,0-.592.761l-.433,1.4a1.267,1.267,0,0,0,.778,1.584h.086a.98.98,0,0,1,.591.658l.032.089a1.129,1.129,0,0,1-.121,1,1.3,1.3,0,0,0,.258,1.736l1.093.83a1.172,1.172,0,0,0,.71.248,1.026,1.026,0,0,0,.207,0,1.189,1.189,0,0,0,.791-.528l.12-.176a.949.949,0,0,1,.754-.416.924.924,0,0,1,.791.423l.064.088a.676.676,0,0,0,.043.061,1.164,1.164,0,0,0,1.653.225l1.059-.836a1.3,1.3,0,0,0,.263-1.741l-.138-.2a1.1,1.1,0,0,1-.132-.889,1.01,1.01,0,0,1,.649-.69l.1-.038a1.274,1.274,0,0,0,.771-1.584m-5.214,4.06a2.87,2.87,0,1,1,2.806-2.869,2.838,2.838,0,0,1-2.806,2.869"
        transform="translate(1.7 0.956)"
      />
      <path
        id="Path_2582"
        data-name="Path 2582"
        d="M19.39,14.124a.889.889,0,0,0-1-.339l-1.1.374c.133.228.269.454.42.672a24.939,24.939,0,0,1-4.627,1.016s-.01,0-.015,0a25.289,25.289,0,0,1-3.028.182h-.022a25.285,25.285,0,0,1-3.027-.182,24.939,24.939,0,0,1-4.637-1.018A11.133,11.133,0,0,0,4.3,8.493V7.9a5.981,5.981,0,0,1,5.07-6.059A5.55,5.55,0,0,1,12.9,2.6a.851.851,0,0,0,1.046-.149.914.914,0,0,0-.161-1.412A7.314,7.314,0,0,0,9.1.057,7.8,7.8,0,0,0,2.543,7.943V9.33A8.374,8.374,0,0,1,.215,14.677a.915.915,0,0,0-.19.805.889.889,0,0,0,.551.628,26.554,26.554,0,0,0,5.287,1.384,4.36,4.36,0,0,0,.18,1.58,4.2,4.2,0,0,0,3.984,2.97,4.065,4.065,0,0,0,1.273-.2,4.181,4.181,0,0,0,2.442-2.114,4.325,4.325,0,0,0,.441-2.227,26.627,26.627,0,0,0,5.293-1.384.9.9,0,0,0,.551-.629.915.915,0,0,0-.2-.818c-.155-.175-.3-.357-.435-.543M12.176,18.9a2.426,2.426,0,0,1-2.051,1.344,2.371,2.371,0,0,1-2.106-1.082,2.552,2.552,0,0,1-.4-1.454c.8.071,1.6.119,2.395.119a.2.2,0,0,0,.024,0c.8,0,1.6-.048,2.4-.119a2.5,2.5,0,0,1-.257,1.192"
        transform="translate(1.956 0.956)"
      />
    </svg>
  );
}

export default IconNotificationSettings;
