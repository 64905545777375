import React, { useEffect } from "react";
import useNotification from "@/hooks/useNotification";
import Toggle from "@velaro/velaro-shared/src/components/Toggle";
import useToastAlert from "@/hooks/useToastAlert";
import useTranslations from "@/hooks/useTranslations";
import useIsMobile from "@/hooks/useIsMobile";
import { is } from "date-fns/locale";

export default function NotificationSettingsPanel() {
  const { settings, updateSettings } = useNotification();
  const { toastSettings, updateToastSettings } = useToastAlert();
  const translation = useTranslations();
  const isMobile = useIsMobile();

  if (isMobile) {
    return (
      <div className="bg-white p-4 w-full">
        <div className="font-semibold text-lg mb-2">
          {translation.key("details")}
        </div>
        <div className="flex justify-between items-center mt-6">
          <label>{translation.key("new_convo_alerts_setting")}</label>
          <div className="w-12">
            <Toggle
              toggled={settings.newConversations}
              changeToggle={() => {
                updateSettings({
                  newConversations: !settings.newConversations
                });
              }}
            />
          </div>
        </div>
        <hr className="my-4 border-gray-200 w-full" />
        <div className="flex justify-between items-center mt-6">
          <label>{translation.key("show_conversation_assigned_alerts")}</label>
          <div className="w-12">
            <Toggle
              toggled={settings.conversationAssigned}
              changeToggle={() => {
                updateSettings({
                  conversationAssigned: !settings.conversationAssigned
                });
              }}
            />
          </div>
        </div>
        <hr className="my-4 border-gray-200 w-full" />
        <div className="flex justify-between items-center mt-6">
          <label>{translation.key("show_new_message_alerts")}</label>
          <div className="w-12">
            <Toggle
              toggled={settings.newMessages}
              changeToggle={() => {
                updateSettings({ newMessages: !settings.newMessages });
              }}
            />
          </div>
        </div>
        <hr className="my-4 border-gray-200 w-full" />
        <div className="flex justify-between items-center mt-6">
          <label>{translation.key("unassigned_convo_alerts_setting")}</label>
          <div className="w-12">
            <Toggle
              toggled={settings.unassignWarning}
              changeToggle={() => {
                updateSettings({ unassignWarning: !settings.unassignWarning });
              }}
            />
          </div>
        </div>
        <hr className="my-4 border-gray-200 w-full" />
        <div className="flex justify-between items-center mt-6">
          <label>{translation.key("text_alerts_setting")}</label>
          <div className="w-12">
            <Toggle
              toggled={settings.textAlerts}
              changeToggle={() => {
                updateSettings({ textAlerts: !settings.textAlerts });
              }}
            />
          </div>
        </div>
        <hr className="my-4 border-gray-200 w-full" />
        <div className="font-semibold text-lg mb-2">
          {translation.key("toast_notifications")}
        </div>
        <div className="flex justify-between items-center mt-6">
          <label>{translation.key("sticky_alerts")}</label>
          <div className="w-12">
            <Toggle
              toggled={toastSettings.stickyAlerts}
              changeToggle={() => {
                updateToastSettings({
                  stickyAlerts: !toastSettings.stickyAlerts
                });
              }}
            />
          </div>
        </div>
        <hr className="my-4 border-gray-200 w-full" />
        <div className="flex justify-between items-center mt-6">
          <label>{translation.key("alert_duration")}</label>
        </div>
        <select
          className="flex justify-between items-center input w-full mt-4"
          onChange={(e) =>
            updateToastSettings({
              stickyDuration: parseInt(e.target.value)
            })
          }
          value={toastSettings.stickyDuration}
        >
          <option value="5000">{translation.key("five_seconds")}</option>
          <option value="10000">{translation.key("ten_seconds")}</option>
          <option value="30000">{translation.key("thirty_seconds")}</option>
        </select>
      </div>
    );
  }

  return (
    <div className="bg-white rounded mr-8 w-4/5 p-8">
      <div className="font-semibold text-lg mb-2">
        {translation.key("details")}
      </div>
      <div className="flex justify-between items-center mt-6">
        <label>{translation.key("new_convo_alerts_setting")}</label>
        <Toggle
          toggled={settings.newConversations}
          changeToggle={() => {
            updateSettings({ newConversations: !settings.newConversations });
          }}
        />
      </div>
      <hr className="my-4 border-gray-200 w-full" />
      <div className="flex justify-between items-center mt-6">
        <label>{translation.key("show_conversation_assigned_alerts")}</label>
        <Toggle
          toggled={settings.conversationAssigned}
          changeToggle={() => {
            updateSettings({
              conversationAssigned: !settings.conversationAssigned
            });
          }}
        />
      </div>
      <hr className="my-4 border-gray-200 w-full" />
      <div className="flex justify-between items-center mt-6">
        <label>{translation.key("show_new_message_alerts")}</label>
        <Toggle
          toggled={settings.newMessages}
          changeToggle={() => {
            updateSettings({ newMessages: !settings.newMessages });
          }}
        />
      </div>
      <hr className="my-4 border-gray-200 w-full" />
      <div className="flex justify-between items-center mt-6">
        <label>{translation.key("unassigned_convo_alerts_setting")}</label>
        <Toggle
          toggled={settings.unassignWarning}
          changeToggle={() => {
            updateSettings({ unassignWarning: !settings.unassignWarning });
          }}
        />
      </div>
      <hr className="my-4 border-gray-200 w-full" />
      <div className="flex justify-between items-center mt-6">
        <label>{translation.key("text_alerts_setting")}</label>
        <Toggle
          toggled={settings.textAlerts}
          changeToggle={() => {
            updateSettings({ textAlerts: !settings.textAlerts });
          }}
        />
      </div>
      <hr className="my-4 border-gray-200 w-full" />
      <div className="font-semibold text-lg mb-2">
        {translation.key("toast_notifications")}
      </div>
      <div className="flex justify-between items-center mt-6">
        <label>{translation.key("sticky_alerts")}</label>
        <Toggle
          toggled={toastSettings.stickyAlerts}
          changeToggle={() => {
            updateToastSettings({ stickyAlerts: !toastSettings.stickyAlerts });
          }}
        />
      </div>
      <hr className="my-4 border-gray-200 w-full" />
      <div className="flex justify-between items-center mt-6">
        <label>{translation.key("alert_duration")}</label>
      </div>
      <select
        className="flex justify-between items-center input w-96 mt-4"
        onChange={(e) =>
          updateToastSettings({
            stickyDuration: parseInt(e.target.value)
          })
        }
        value={toastSettings.stickyDuration}
      >
        <option value="5000">{translation.key("five_seconds")}</option>
        <option value="10000">{translation.key("ten_seconds")}</option>
        <option value="30000">{translation.key("thirty_seconds")}</option>
      </select>
    </div>
  );
}
