import React from "react";
import LoadingSpinner from "@velaro/velaro-shared/src/components/LoadingSpinner";

export default function AppLoading() {
  return (
    <div className="flex h-screen items-center justify-center">
      <LoadingSpinner />
    </div>
  );
}
