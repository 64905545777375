import { Agent, Conversation, SkillListItem, TeamListItem } from "@/types";
import Modal from "@velaro/velaro-shared/src/components/Modal";
import React, { useCallback } from "react";
import AgentListItem from "./AgentListItem";
import IconClose from "@velaro/velaro-shared/src/icons/IconClose";
import useApi from "@/hooks/useApi";
import useScreenWidth from "@/hooks/useScreenWidth";
import useToastAlert from "@/hooks/useToastAlert";
import { Severity } from "@velaro/velaro-shared/src/components/ToastAlert";
import useTheme from "@/hooks/useTheme";
import useConversationNotes from "@/hooks/useConversationNotes";
import NoteEditor from "./Notes/NoteEditor";
import ListItem from "./ListItem";

interface Props {
  conversation: Conversation;
  agent: Agent | undefined | null;
  team: TeamListItem | undefined | null;
  skill: SkillListItem | undefined | null;
  onClose(): void;
  closeReassignModal(): void;
}

export default function ConfirmAssignmentModal(props: Props) {
  const api = useApi();
  const screenWidth = useScreenWidth();
  const toastAlert = useToastAlert();
  const [reassignmentNote, setReassignmentNote] = React.useState("");
  const conversationNotes = useConversationNotes(props.conversation.id);
  const { theme, setTheme } = useTheme();

  async function assignToAgent(agentId: number) {
    const response = await api.messaging.post("AssignAgent", {
      userId: agentId,
      conversationBaseId: props.conversation.id
    });

    if (!response.ok) {
      if (response.status === 400) {
        alert(await response.text());
      } else {
        toastAlert.displayToast(Severity.Error, "an unexpected error occurred");
      }
    } else {
      props.onClose();
      props.closeReassignModal();
    }
  }

  async function assignToTeam(teamId: number, teamName: string) {
    const response = await api.messaging.post("Teams/AssignTeam", {
      teamId: teamId,
      conversationBaseId: props.conversation.id
    });
    if (!response.ok) {
      if (response.status === 400) {
        alert(await response.text());
      } else {
        toastAlert.displayToast(Severity.Error, "an unexpected error occurred");
      }
    } else {
      toastAlert.displayToast(
        Severity.Success,
        "Conversation reassigned to team: " + teamName
      );
    }
    props.onClose();
    props.closeReassignModal();
  }

  async function assignToSkill(skillId: number, skillName: string) {
    const response = await api.messaging.post("Skills/AssignSkill", {
      skillId: skillId,
      conversationBaseId: props.conversation.id
    });
    if (!response.ok) {
      toastAlert.displayToast(Severity.Error, "an unexpected error occurred");
    } else {
      toastAlert.displayToast(
        Severity.Success,
        "Conversation reassigned to skill: " + skillName
      );
    }
    props.onClose();
    props.closeReassignModal();
  }

  const setReassignmentInfo = useCallback((text: string) => {
    setReassignmentNote(text);
  }, []);

  function reassignConversation() {
    if (props.agent) {
      console.log("Reassigning conversation to agent: ", props.agent);
      assignToAgent(props.agent.id);
    } else if (props.team) {
      console.log("Reassigning conversation to team: ", props.team);
      assignToTeam(props.team.id, props.team.name);
    } else if (props.skill) {
      console.log("Reassigning conversation to skill: ", props.skill);
      assignToSkill(props.skill.id, props.skill.name);
    } else {
      console.log("No agent or team selected to reassign conversation to");
    }
    if (reassignmentNote) {
      conversationNotes.add(reassignmentNote);
    }
  }
  return (
    <Modal show={true} maxWidth="max-w-[640px]">
      <Modal.Body>
        <div className="flex pb-6 items-center">
          {props.agent && (
            <div className="text-xl font-semibold">
              Confirm Agent Re-Assignment
            </div>
          )}
          {props.team && (
            <div className="text-xl font-semibold">
              Confirm Team Re-Assignment
            </div>
          )}
          {props.skill && (
            <div className="text-xl font-semibold">
              Confirm Skill Re-Assignment
            </div>
          )}
          <button
            className="ml-auto h-10 w-10 text-slate-gray-500"
            onClick={() => props.onClose()}
          >
            <IconClose />
          </button>
        </div>
        <div>
          <div className="flex flex-col text-sm leading-[21px]">
            <div>Message Transfer To</div>
            {props.agent && (
              <div>
                <AgentListItem
                  agent={props.agent}
                  showAvailability={false}
                  notSelectable={true}
                  setAgentInfo={() => null}
                />
              </div>
            )}
            {props.team && (
              <div>
                <ListItem
                  team={props.team}
                  notSelectable={true}
                  setTeamInfo={() => null}
                  setSkillInfo={() => null}
                />
              </div>
            )}
            {props.skill && (
              <div>
                <ListItem
                  skill={props.skill}
                  notSelectable={true}
                  setSkillInfo={() => null}
                  setTeamInfo={() => null}
                />
              </div>
            )}
            <div className="pt-4 pb-2">Notes (Optional)</div>
            <NoteEditor
              conversationBaseId={props.conversation.id}
              setReassignmentInfo={setReassignmentInfo}
            />
            <div className="flex flex-row gap-4 pt-6 justify-end">
              <button
                className="w-[120px] h-10 border-[1.5px] rounded-md border-cornflower-blue-500 bg-white text-cornflower-blue-500"
                onClick={() => props.onClose()}
              >
                Cancel
              </button>
              <button
                className="w-[120px] h-10 rounded-md border-cornflower-blue-500 bg-cornflower-blue-500 text-white"
                onClick={() => reassignConversation()}
              >
                Save
              </button>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}
