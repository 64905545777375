import React from "react";
import { IconProps } from "./types";

function IconInsightsReports(props: IconProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="28"
      height="28"
      viewBox="0 0 28 28"
      {...props}
    >
      <path fill="none" d="M0 0H28V28H0z" data-name="Rectangle 427"></path>
      <g
        fill="currentColor"
        data-name="Group 99"
        transform="translate(1.75 1.75)"
      >
        <path
          d="M12.375 4.5h4.455v11.289h-4.455z"
          data-name="Path 248"
          transform="translate(-5.693 8.711)"
        ></path>
        <path
          d="M5.625 23.625h4.455v7.351H5.625z"
          data-name="Path 249"
          transform="translate(-5.625 -6.476)"
        ></path>
        <path
          d="M19.125 19.125h4.455v9.451h-4.455z"
          data-name="Path 250"
          transform="translate(-5.761 -4.076)"
        ></path>
        <path
          d="M25.875 12.375h4.455v17.852h-4.455z"
          data-name="Path 251"
          transform="translate(-5.83 -5.727)"
        ></path>
        <path
          d="M21.645 7.036a.808.808 0 000-.218.663.663 0 00-.052-.176A1.139 1.139 0 0021.5 6.5a.777.777 0 00-.145-.176l-.124-.073a.715.715 0 00-.2-.1h-.207A.725.725 0 0020.609 6H15.43a1.036 1.036 0 100 2.072h2.931l-4.143 4.878-4.475-2.662a1.036 1.036 0 00-1.326.228l-5.179 6.215a1.037 1.037 0 001.6 1.326l4.609-5.531 4.423 2.652a1.036 1.036 0 001.315-.218l4.392-5.127v2.382a1.036 1.036 0 102.072 0z"
          data-name="Path 276"
          transform="translate(-2.998 -6)"
        ></path>
      </g>
    </svg>
  );
}

export default IconInsightsReports;
