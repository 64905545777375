import React from "react";
import { IconProps } from "./types";

function IconPlay(props: IconProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="currentColor"
      stroke="currentColor"
      {...props}
    >
      <rect width="24" height="24" fill="none" opacity="0" />
      <path
        d="M21,12a9,9,0,1,1-9-9A9,9,0,0,1,21,12Z"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
      />
      <path
        d="M15.91,11.672a.375.375,0,0,1,0,.656l-5.6,3.113a.375.375,0,0,1-.557-.328V8.887a.375.375,0,0,1,.557-.328Z"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
      />
    </svg>
  );
}

export default IconPlay;
