import React, { useEffect, useState } from "react";
import StatusContext from "@/context/StatusContext";
import { UserStatusType } from "@/types";
import useApi from "@/hooks/useApi";

export default function StatusProvider(props: { children: React.ReactNode }) {
  const [state, setState] = useState<UserStatusType>("Unavailable");
  const api = useApi();

  useEffect(() => {
    async function fetchStatus() {
      const resp = await api.messaging.get("UserStatus");
      const data = await resp.json();
      setState(data.status);
    }

    fetchStatus();
  }, [api.messaging]);

  function setStatus(status: UserStatusType) {
    setState(status);
    api.messaging.post("UserStatus", { status });
  }

  return (
    <StatusContext.Provider value={{ status: state, setStatus }}>
      {props.children}
    </StatusContext.Provider>
  );
}
