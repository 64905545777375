import React, { useState } from "react";

interface Props {
  title: string;
  children: React.ReactNode;
}

export default function Panel(props: Props) {
  const [expanded, setExpanded] = useState(true);

  let iconClassName = "transition fa-solid fa-angle-down";

  if (expanded) {
    iconClassName += " fa-rotate-180";
  }

  return (
    <div className="panel bg-white dark:bg-stone-300 rounded-md">
      <div
        className="panel-header flex items-center h-16 px-4 cursor-pointer"
        onClick={() => setExpanded((s) => !s)}
      >
        <div className="dark:text-white font-semibold text-zinc-700 text-base font-['Rubik'] leading-normal">
          {props.title}
        </div>
        <div className="ml-auto dark:text-white">
          {/* <button className="h-8 w-8">
            <i className="fa-solid fa-ellipsis-vertical"></i>
          </button> */}
          <button className="h-8 w-8">
            <i className={iconClassName}></i>
          </button>
        </div>
      </div>
      {expanded && (
        <div className="panel-body animated fadeIn dark:border-stone-100">
          {props.children}
        </div>
      )}
    </div>
  );
}
