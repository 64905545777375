import React from "react";
import { LinkMessageGroupType } from "@/reducers/messageGroupReducer";
import IconFormstackLogo from "@/icons/IconFormstackLogo";

interface Props {
  item: LinkMessageGroupType;
}

function openLink(url: string | undefined) {
  if (!url) return;
  window.open(url, "_blank");
}

export default function LinkMessageGroup(props: Props) {
  return (
    <div className="py-4 flex flex-col items-center justify-center">
      {props.item.messages.map((message) => (
        <div key={message.id}>
          <button key={message.id} onClick={() => openLink(message.link)}>
            <div
              className="flex flex-row px-4 text-xs items-center font-semibold border-2 rounded-md border-slate-gray-300 p-2 gap-2 my-2"
              style={{ color: message.color }}
            >
              <div className="h-10 w-8 pt-1">
                <IconFormstackLogo />
              </div>
              <div className="justify-center items-center">{message.text}</div>
            </div>
          </button>
        </div>
      ))}
    </div>
  );
}
