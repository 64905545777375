import React from "react";
import Toggle from "@velaro/velaro-shared/src/components/Toggle";
import useProfile from "@/hooks/useProfile";
import useSounds from "@/hooks/useSounds";
import IconPlay from "@/icons/IconPlay";
import { SoundType } from "@/types";
import useTranslations from "@/hooks/useTranslations";
import { use } from "i18next";
import useIsMobile from "@/hooks/useIsMobile";

export default function PreferencesPanel() {
  const { profile, setProfile } = useProfile();
  const { sounds, playSound } = useSounds();
  const translation = useTranslations();
  const isMobile = useIsMobile();

  if (isMobile) {
    return (
      <div className="bg-white p-4 w-full">
        <div className="font-semibold text-lg mb-2">
          {translation.key("sounds")}
        </div>
        <div className="flex justify-between items-center mt-6">
          <label>{translation.key("sounds_enabled")}</label>
          <div className="w-12">
            <Toggle
              toggled={profile.soundEnabled}
              changeToggle={() => {
                setProfile({ ...profile, soundEnabled: !profile.soundEnabled });
              }}
            />
          </div>
        </div>
        <hr className="my-4 border-gray-200 w-full" />
        <div className="w-full">
          <label>{translation.key("new_convo_sound")}</label>
          <div className="flex items-center mt-2 w-full">
            <select
              className="input w-full mb-2"
              onChange={(e) => {
                setProfile({
                  ...profile,
                  newConversationSound: e.currentTarget.value
                });
              }}
              value={profile.newConversationSound || ""}
            >
              {sounds?.map((sound, i) => (
                <option key={i} value={sound.fileName}>
                  {sound.label}
                </option>
              ))}
            </select>
            <div onClick={() => playSound("newConversation")}>
              <IconPlay className="ml-4 w-8 h-8 hover:cursor-pointer hover:text-cornflower-blue-500" />
            </div>
          </div>
        </div>
        <div>
          <label>{translation.key("new_message_alert")}</label>
          <div className="flex items-center mt-2">
            <select
              className="input w-full"
              onChange={(e) => {
                setProfile({
                  ...profile,
                  newMessageSound: e.currentTarget.value
                });
              }}
              value={profile.newMessageSound || ""}
            >
              {sounds?.map((sound, i) => (
                <option key={i} value={sound.fileName}>
                  {sound.label}
                </option>
              ))}
            </select>
            <div onClick={() => playSound("newMessage")}>
              <IconPlay className="ml-4 w-8 h-8 hover:cursor-pointer hover:text-cornflower-blue-500" />
            </div>
          </div>
        </div>
        <hr className="my-4 border-gray-200 w-full" />
        <div className="font-semibold text-lg mb-2">
          {translation.key("translations")}
        </div>
        <div className="flex justify-between items-center mt-6">
          <label>{translation.key("enable_chat_translations")}</label>
          <Toggle
            toggled={profile.chatTranslationsEnabled}
            changeToggle={() => {
              setProfile({
                ...profile,
                chatTranslationsEnabled: !profile.chatTranslationsEnabled
              });
            }}
          />
        </div>
      </div>
    );
  }

  return (
    <div className="bg-white rounded mr-8 w-4/5 p-8">
      <div className="font-semibold text-lg mb-2">
        {translation.key("sounds")}
      </div>
      <div className="flex justify-between items-center mt-6">
        <label>{translation.key("sounds_enabled")}</label>
        <Toggle
          toggled={profile.soundEnabled}
          changeToggle={() => {
            setProfile({ ...profile, soundEnabled: !profile.soundEnabled });
          }}
        />
      </div>
      <hr className="my-4 border-gray-200 w-full" />
      <div className="grid grid-cols-2 gap-12">
        <div>
          <label>{translation.key("new_convo_sound")}</label>
          <div className="flex items-center mt-2">
            <select
              className="input w-full "
              onChange={(e) => {
                setProfile({
                  ...profile,
                  newConversationSound: e.currentTarget.value
                });
              }}
              value={profile.newConversationSound || ""}
            >
              {sounds?.map((sound, i) => (
                <option key={i} value={sound.fileName}>
                  {sound.label}
                </option>
              ))}
            </select>
            <div onClick={() => playSound("newConversation")}>
              <IconPlay className="ml-4 w-8 h-8 hover:cursor-pointer hover:text-cornflower-blue-500" />
            </div>
          </div>
        </div>
        <div>
          <label>{translation.key("new_message_alert")}</label>
          <div className="flex items-center mt-2">
            <select
              className="input w-full"
              onChange={(e) => {
                setProfile({
                  ...profile,
                  newMessageSound: e.currentTarget.value
                });
              }}
              value={profile.newMessageSound || ""}
            >
              {sounds?.map((sound, i) => (
                <option key={i} value={sound.fileName}>
                  {sound.label}
                </option>
              ))}
            </select>
            <div onClick={() => playSound("newMessage")}>
              <IconPlay className="ml-4 w-8 h-8 hover:cursor-pointer hover:text-cornflower-blue-500" />
            </div>
          </div>
        </div>
      </div>
      <hr className="my-6 border-gray-200 w-full" />
      <div className="font-semibold text-lg mb-2">
        {translation.key("translations")}
      </div>
      <div className="flex justify-between items-center mt-6">
        <label>{translation.key("enable_chat_translations")}</label>
        <Toggle
          toggled={profile.chatTranslationsEnabled}
          changeToggle={() => {
            setProfile({
              ...profile,
              chatTranslationsEnabled: !profile.chatTranslationsEnabled
            });
          }}
        />
      </div>
    </div>
  );
}
