import React from "react";
import format from "date-fns/format";

interface Props {
  isoDate: string;
}

export default function FormattedTime(props: Props) {
  const date = new Date(props.isoDate);
  const str = format(date, "p");
  return <>{str}</>;
}
