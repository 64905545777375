import React from "react";
import { IconProps } from "./types";

function IconAutomation(props: IconProps) {
  const { className, ...rest } = props;
  return (
    <svg
      id="Automation"
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24.001"
      viewBox="0 0 24 24.001"
      className={className}
      {...rest}
    >
      <defs>
        <clipPath id="clip-path">
          <rect
            id="Rectangle_2120"
            data-name="Rectangle 2120"
            width="24"
            height="24.001"
            transform="translate(0 0)"
            fill="#fff"
          />
        </clipPath>
      </defs>
      <g
        id="Group_1016"
        data-name="Group 1016"
        clipPath="url(#clip-path)"
        fill="currentColor"
      >
        <path
          id="Path_2025"
          data-name="Path 2025"
          d="M17.206,16.543H8.177a3.322,3.322,0,0,1-3.321-3.32V9.341A3.324,3.324,0,0,1,8.177,6.024h9.029a3.324,3.324,0,0,1,3.323,3.317V13.22a3.322,3.322,0,0,1-3.322,3.323ZM8.177,7.458A1.889,1.889,0,0,0,6.289,9.341V13.22a1.89,1.89,0,0,0,1.887,1.886h9.03a1.889,1.889,0,0,0,1.887-1.886V9.341a1.889,1.889,0,0,0-1.887-1.883Z"
          transform="translate(-0.694 -0.86)"
        />
        <path
          id="Path_2026"
          data-name="Path 2026"
          d="M13.881,20.219a.715.715,0,0,1-.715-.715V17.342a.717.717,0,1,1,1.433,0V19.5a.714.714,0,0,1-.715.715"
          transform="translate(-1.881 -2.375)"
        />
        <path
          id="Path_2027"
          data-name="Path 2027"
          d="M20.734,26.736a.715.715,0,0,1-.715-.715v-2.7a2.872,2.872,0,0,0-2.983-2.739H7.989a2.871,2.871,0,0,0-2.983,2.739v2.649a.717.717,0,0,1-1.434,0V23.318A4.3,4.3,0,0,1,7.99,19.145h9.042a4.3,4.3,0,0,1,4.417,4.173v2.7a.714.714,0,0,1-.715.715"
          transform="translate(-0.51 -2.735)"
        />
        <path
          id="Path_2028"
          data-name="Path 2028"
          d="M18.116,12.388a.715.715,0,0,1-.715-.715.739.739,0,1,1-1.478,0,1.456,1.456,0,0,1,2.911,0,.715.715,0,0,1-.715.715"
          transform="translate(-2.275 -1.467)"
        />
        <path
          id="Path_2029"
          data-name="Path 2029"
          d="M10.875,12.388a.715.715,0,0,1-.715-.715.739.739,0,1,1-1.478,0,1.456,1.456,0,0,1,2.911,0,.715.715,0,0,1-.715.715"
          transform="translate(-1.24 -1.467)"
        />
        <path
          id="Path_2030"
          data-name="Path 2030"
          d="M13.881,7.187a.715.715,0,0,1-.715-.715V4.837a.717.717,0,1,1,1.433,0V6.469a.714.714,0,0,1-.715.715"
          transform="translate(-1.881 -0.588)"
        />
        <path
          id="Path_2031"
          data-name="Path 2031"
          d="M13.565,4.964a2.551,2.551,0,0,1-2.607-2.481,2.611,2.611,0,0,1,5.215,0,2.551,2.551,0,0,1-2.607,2.481m0-3.531a1.119,1.119,0,0,0-1.174,1.049,1.181,1.181,0,0,0,2.348,0,1.117,1.117,0,0,0-1.173-1.049"
          transform="translate(-1.565 0)"
        />
        <path
          id="Path_2032"
          data-name="Path 2032"
          d="M17.429,25.514a.715.715,0,0,1-.715-.715v-.821a.572.572,0,0,0-.608-.52H10.156a.571.571,0,0,0-.608.52v.79a.717.717,0,0,1-1.434,0v-.79a2,2,0,0,1,2.042-1.955h5.95a2,2,0,0,1,2.042,1.955V24.8a.715.715,0,0,1-.716.715"
          transform="translate(-1.159 -3.146)"
        />
      </g>
    </svg>
  );
}

export default IconAutomation;
