import { Contact, Conversation } from "@/types";
import React, { useState } from "react";
import NetSuiteModal from "./NetSuitePanel/NetSuiteModal";

interface NetSuiteSearchResult {
  recordType: string;
  id: string;
  fields: Record<string, any>;
  showInSearchFields: string[];
  showOnSelectFields: string[];
}

interface Props {
  searchResult: NetSuiteSearchResult;
  conversation: Conversation;
  showModal(): void;
}

export default function CrmSearchResult(props: Props) {
  const [expanded, setExpanded] = useState(false);
  const [contact, setContact] = useState({
    ...props.conversation.contact
  });
  if (contact.id !== props.conversation.contact.id) {
    setContact({
      ...props.conversation.contact
    });
  }

  let iconClassName = "transition fa-solid fa-angle-down";

  if (expanded) {
    iconClassName += " fa-rotate-180";
  }

  return (
    <div className="mb-4 border">
      <div className="px-4" key={props.searchResult.id}>
        <div className="panel-header flex items-center h-16 px-4">
          <div className="text-slate-gray-600 dark:text-white font-bold">
            {props.searchResult.recordType} -{" "}
            {props.searchResult.recordType === "SupportCase" &&
              props.searchResult.fields["title"]}
            {props.searchResult.fields["entityid"]}
          </div>
          <div className="ml-auto dark:text-white">
            <button className="h-8 w-8" onClick={() => setExpanded((s) => !s)}>
              <i className={iconClassName}></i>
            </button>
          </div>
        </div>
        <div>
          {Object.values(props.searchResult.showInSearchFields).map(
            (field, i) => (
              <div className="mb-1" key={i}>
                <div>
                  {field}: {props.searchResult.fields[field]}
                </div>
              </div>
            )
          )}
        </div>
      </div>
      {expanded && (
        <div className="px-4">
          {Object.values(
            props.searchResult.showOnSelectFields.filter(
              (item) => props.searchResult.showInSearchFields.indexOf(item) < 0
            )
          ).map((field, i) => (
            <div className="mb-1" key={i}>
              <div>
                {field}: {props.searchResult.fields[field]}
              </div>
            </div>
          ))}
          <div className="mb-4">
            <button
              type="button"
              className="h-7 flex items-center border rounded px-2 mr-1 mb-1 text-sm"
              onClick={() => {
                props.showModal();
              }}
            >
              <i className="fa fa-plus"></i>
              <span className="ml-1">
                Add {props.searchResult.recordType} to NetSuite
              </span>
            </button>
          </div>
        </div>
      )}
    </div>
  );
}
