import useTranslations from "@/hooks/useTranslations";
import React, { useMemo, useRef, useState } from "react";
import ReactDomServer from "react-dom/server";
import IconEmoji from "@/icons/IconEmoji";
import data from "@emoji-mart/data";
import Picker from "@emoji-mart/react";
import useScreenWidth from "@/hooks/useScreenWidth";
import useTheme from "@/hooks/useTheme";
import ReactQuill from "react-quill";
import "@/themes/quill.snow.css";
import IconBoldButton from "@velaro/velaro-shared/src/icons/IconBoldButton";
import IconItalicsButton from "@velaro/velaro-shared/src/icons/IconItalicsButton";
import IconUnderlineButton from "@velaro/velaro-shared/src/icons/IconUnderlineButton";
import IconStrikethroughButton from "@velaro/velaro-shared/src/icons/IconStrikethroughButton";
import IconLinkButton from "@velaro/velaro-shared/src/icons/IconLinkButton";
import IconListButton from "@velaro/velaro-shared/src/icons/IconListButton";

interface Props {
  setContactNote(note: string): void;
}

const modules = {
  toolbar: [
    ["bold", "italic", "underline", "strike", "link", { list: "bullet" }]
  ]
};

const icons = ReactQuill.Quill.import("ui/icons");
const boldIcon = ReactDomServer.renderToStaticMarkup(<IconBoldButton />);
const italicsIcon = ReactDomServer.renderToStaticMarkup(<IconItalicsButton />);
const underlineIcon = ReactDomServer.renderToStaticMarkup(
  <IconUnderlineButton />
);
const strikethroughIcon = ReactDomServer.renderToStaticMarkup(
  <IconStrikethroughButton />
);
const linkIcon = ReactDomServer.renderToStaticMarkup(<IconLinkButton />);
const listIcon = ReactDomServer.renderToStaticMarkup(<IconListButton />);
icons["bold"] = boldIcon;
icons["italic"] = italicsIcon;
icons["underline"] = underlineIcon;
icons["strike"] = strikethroughIcon;
icons["link"] = linkIcon;
icons["list"] = listIcon;

export default function ContactNote(props: Props) {
  const [text, setText] = React.useState("");
  const [attachments, setAttachments] = React.useState<File[]>([]);
  const [showEmojiPicker, setShowEmojiPicker] = React.useState(false);
  const [selectionStart, setSelectionStart] = React.useState(0);
  const [selectionEnd, setSelectionEnd] = React.useState(0);
  const screenWidth = useScreenWidth();
  const { theme, setTheme } = useTheme();
  const [value, setValue] = useState("");

  let emojiPickerStyling = "absolute top-[360px] left-0";
  if (screenWidth < 768) {
    emojiPickerStyling = "absolute bottom-28 inset-x-0";
  }

  React.useEffect(() => {
    props.setContactNote(value);
  }, [props, value]);

  return (
    <>
      <div className="border border-slate-gray-200 rounded-md">
        {/* <div>
          <input
            type="text"
            placeholder="Type your message"
            className="bg-inherit w-full h-[49px] px-4 py rounded-sm dark:bg-stone-600 dark:text-white focus:outline-none"
            value={text}
            onChange={(e) => handleText(e.target.value)}
          />
        </div> */}
        <ReactQuill
          className="flex flex-col border-none h-inherit w-full "
          placeholder="Type your message"
          theme="snow"
          value={value}
          onChange={setValue}
          modules={modules}
        />
        <div className="flex flex-col">
          <div className="flex flex-row h-12 text-slate-gray-500 items-center gap-0.5 pl-2">
            {/* <button>
              <UploadButton
                onAddAttachments={(files) => {
                  setAttachments((attachments) => [...attachments, ...files]);
                }}
              />
            </button> */}
            <button
              className="h-8 w-8 flex items-center justify-center"
              onClick={(e) => {
                setShowEmojiPicker(!showEmojiPicker);
                e.stopPropagation();
              }}
            >
              <IconEmoji />
            </button>
            {showEmojiPicker && (
              <div className={emojiPickerStyling}>
                <Picker
                  data={data}
                  theme={theme}
                  onEmojiSelect={(emoji: { native: string }) => {
                    setValue((oldState) => {
                      console.log(oldState.slice(0, oldState.length - 4));
                      return (
                        oldState.slice(0, oldState.length - 4) + emoji.native
                      );
                    });
                  }}
                  onClickOutside={() => setShowEmojiPicker(!showEmojiPicker)}
                  previewPosition={"none"}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
}
