import React from "react";
import { IconProps } from "./types";

function IconCamera(props: IconProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      {...props}
    >
      <g fill="currentColor" data-name="Group 206" transform="translate(3 4)">
        <path
          d="M17.111 6.556h-2.667V5.222A2.222 2.222 0 0012.222 3H9.556a2.222 2.222 0 00-2.223 2.222v1.334H4.667A2.667 2.667 0 002 9.222v7.111A2.667 2.667 0 004.667 19h12.444a2.667 2.667 0 002.667-2.667V9.222a2.667 2.667 0 00-2.667-2.666zm-8-1.333a.444.444 0 01.444-.444h2.667a.444.444 0 01.444.444v1.333H9.111zM18 16.333a.889.889 0 01-.889.889H4.667a.889.889 0 01-.889-.889V9.222a.889.889 0 01.889-.889h12.444a.889.889 0 01.889.889z"
          data-name="Path 323"
          transform="translate(-2 -3)"
        ></path>
        <path
          d="M11.611 10.5a3.111 3.111 0 103.111 3.111 3.111 3.111 0 00-3.111-3.111zm0 4.444a1.333 1.333 0 111.333-1.333 1.333 1.333 0 01-1.333 1.333z"
          data-name="Path 324"
          transform="translate(-2.722 -3.833)"
        ></path>
      </g>
    </svg>
  );
}

export default IconCamera;
