import React from "react";
import { IconProps } from "./types";

function IconPlus(props: IconProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      {...props}
    >
      <g data-name="plus" transform="translate(1.667 2)">
        <path
          fill="currentColor"
          d="M17.125 10.125h-5.25v-5.25a.875.875 0 00-1.75 0v5.25h-5.25a.875.875 0 000 1.75h5.25v5.25a.875.875 0 001.75 0v-5.25h5.25a.875.875 0 000-1.75z"
          data-name="Path 191"
          transform="translate(-.667 -1)"
        ></path>
      </g>
    </svg>
  );
}

export default IconPlus;
