import React from "react";
import { IconProps } from "./types";

function IconSentimentNegative(props: IconProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      {...props}
    >
      <path
        fill="currentColor"
        d="M16 16H0V0h16v16zM8 8.83a5.476 5.476 0 00-4.115 2.058.877.877 0 001.4 1.052A3.758 3.758 0 018 10.584a3.766 3.766 0 012.716 1.36.877.877 0 001.4-1.056A5.529 5.529 0 008 8.83zm2.561-4.268a.881.881 0 10.008 0zm-5.122 0a.881.881 0 10.008 0z"
        data-name="Exclusion 3"
      ></path>
    </svg>
  );
}

export default IconSentimentNegative;
