import React from "react";
import LeftNav from "./LeftNav";
import NewVersionModal from "./NewVersionModal";

interface Props {
  children: React.ReactNode;
}

export default function DesktopLayout({ children }: Props) {
  return (
    <div className="flex h-screen overflow-hidden">
      <LeftNav />
      <main className="flex-1 bg-slate-gray-100 text-slate-gray-600 dark:bg-stone-600 dark:text-stone-100 max-w-full min-w-0">
        {children}
      </main>
      <NewVersionModal />
    </div>
  );
}
