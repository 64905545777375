import React from "react";
import { IconProps } from "./types";

function IconPersonProfileSettings(props: IconProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="25"
      viewBox="0 0 25 25"
      {...props}
    >
      <g fill="currentColor" transform="translate(.5 .5)">
        <path
          d="M1.744 15.681a1.862 1.862 0 01.806-1.5l2.33-1.689A.71.71 0 005 11.4a.953.953 0 00-1.228-.1l-2.33 1.689A3.332 3.332 0 000 15.681v6.332h1.744z"
          data-name="Path 500"
          transform="translate(1 .675)"
        ></path>
        <path
          stroke="rgba(0,0,0,0)"
          strokeWidth="1"
          d="M14.635 14.131h0l-1.335-.967a.74.74 0 01-.315-.529.708.708 0 01.2-.555.923.923 0 01.675-.281.943.943 0 01.552.175l1.131.82-.424.627-.479.709z"
          data-name="Path 2024"
        ></path>
        <path
          d="M4.753 18.605l.012-1.448a.824.824 0 00-.865-.775.846.846 0 00-.879.764l-.007 5.678h1.739z"
          data-name="Path 502"
          transform="translate(.875 -.137)"
        ></path>
        <path
          d="M14.4 5.892A5.711 5.711 0 108.694 11.6 5.717 5.717 0 0014.4 5.892M8.694 9.858a3.966 3.966 0 113.966-3.966 3.971 3.971 0 01-3.966 3.966"
          data-name="Path 503"
          transform="translate(.876 1.13)"
        ></path>
        <path
          d="M23.758 17.2l-.458-1.468c0-.015-.009-.029-.014-.044a1.316 1.316 0 00-1.662-.835l-.2.064a1.011 1.011 0 01-.925-.152l-.064-.046a1.024 1.024 0 01-.4-.833v-.163a1.381 1.381 0 00-.4-.978 1.319 1.319 0 00-.932-.389h-1.479a1.348 1.348 0 00-1.331 1.355v.138a1.129 1.129 0 01-.425.879l-.076.058a1.122 1.122 0 01-1.036.169 1.243 1.243 0 00-.978.07 1.27 1.27 0 00-.654.819l-.476 1.513a1.361 1.361 0 00.858 1.711h.1a1.066 1.066 0 01.652.71l.035.1a1.2 1.2 0 01-.133 1.081 1.379 1.379 0 00.286 1.874l1.2.9a1.3 1.3 0 00.786.268 1.086 1.086 0 00.228 0 1.31 1.31 0 00.873-.564l.133-.191a1.051 1.051 0 01.832-.448 1.02 1.02 0 01.874.454l.069.095c.015.023.031.045.047.066a1.3 1.3 0 001.827.243l1.17-.9a1.384 1.384 0 00.29-1.879l-.151-.221a1.163 1.163 0 01-.146-.96 1.1 1.1 0 01.716-.745l.116-.041a1.372 1.372 0 00.849-1.7M18 20.211a2.037 2.037 0 112.037-2.037A2.037 2.037 0 0118 20.211"
          data-name="Path 524"
          transform="translate(.178)"
        ></path>
      </g>
    </svg>
  );
}

export default IconPersonProfileSettings;
