import React from "react";
import { IconProps } from "./types";

function IconTrash(props: IconProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      {...props}
    >
      <g
        fill="currentColor"
        data-name="Group 1015"
        transform="translate(1 .85)"
      >
        <path
          d="M15.987 20.536H6.416a1.891 1.891 0 01-1.88-1.676L2.431 7.08a.846.846 0 011.667-.3L6.21 18.615c.019.153.1.229.205.229h9.572a.2.2 0 00.2-.177l2.119-11.884a.846.846 0 011.667.3l-2.115 11.83a1.875 1.875 0 01-1.871 1.623"
          data-name="Path 519"
          transform="translate(-.201 -.328)"
        ></path>
        <path
          d="M11.923 17.536a.846.846 0 01-.846-.846V8.544a.846.846 0 111.692 0v8.146a.846.846 0 01-.846.846"
          data-name="Path 520"
          transform="translate(-.923 -.462)"
        ></path>
        <path
          d="M15.118 17.536a.631.631 0 01-.083 0 .844.844 0 01-.76-.923l.788-8.145a.846.846 0 111.684.163l-.788 8.146a.846.846 0 01-.842.764"
          data-name="Path 521"
          transform="translate(-1.189 -.462)"
        ></path>
        <path
          d="M8.656 17.536a.846.846 0 01-.842-.764l-.787-8.146a.846.846 0 111.684-.163l.789 8.145a.844.844 0 01-.76.923.631.631 0 01-.082 0"
          data-name="Path 522"
          transform="translate(-.585 -.462)"
        ></path>
        <path
          d="M21.154 6.635a.846.846 0 01-.846-.846 1.949 1.949 0 00-1.946-1.947H3.639a1.949 1.949 0 00-1.947 1.947.846.846 0 11-1.692 0A3.643 3.643 0 013.639 2.15h14.723A3.643 3.643 0 0122 5.789a.846.846 0 01-.846.846"
          data-name="Path 523"
        ></path>
      </g>
    </svg>
  );
}

export default IconTrash;
