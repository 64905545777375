import React from "react";

interface Props {
  totalCount: number;
  getCount(conversationBaseId: number): number;
  setCurrentconversationBaseId(conversationBaseId: number | undefined): void;
}

const UnreadMessagesContext = React.createContext<Props>({
  totalCount: 0,
  getCount() {
    return 0;
  },
  setCurrentconversationBaseId() {}
});

export default UnreadMessagesContext;
