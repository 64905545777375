import * as React from "react";
import {
  Route,
  Routes,
  Outlet,
  useLocation,
  redirect,
  RouterProvider,
  createBrowserRouter
} from "react-router-dom";
import LoginCallback from "@/pages/Auth/LoginCallback";
import LoginCallbackError from "@/pages/Auth/LoginCallbackError";
import Logout from "@/pages/Auth/Logout";
import LogoutCallback from "@/pages/Auth/LogoutCallback";
import Contacts from "@/pages/Contacts";
import Home from "@/pages/Home";
import Conversations from "@/pages/Conversations";
import Profile from "@/pages/Profile";
import Icons from "@/pages/Icons";
import PrivateRoute from "@/routes/PrivateRoute";
import ConversationDetail from "@/pages/Conversations/ConversationDetail";
import TranscriptView from "@/pages/Dashboard/TranscriptView";
import DashboardView from "@/pages/Dashboard/DashboardView";
import NoConversationSelected from "@/pages/Conversations/NoConversationSelected";
import useAuth from "@/hooks/useAuth";
import { useCallback } from "react";
import LoginUnauthorized from "@/pages/Auth/LoginUnauthorized";
import ContactDetails from "@/pages/Contacts/ContactDetails/ContactDetails";

const createAppRoutes = (mainLoader: (params: any) => any) => [
  {
    path: "/",
    element: <Home />,
    loader: mainLoader,
    children: [
      { path: "login/callback", element: <LoginCallback /> },
      { path: "login/callback/error", element: <LoginCallbackError /> },
      {
        path: "login/callback/unauthorized",
        element: <LoginUnauthorized />
      },
      { path: "logout", element: <Logout /> },
      { path: "logout/callback", element: <LogoutCallback /> },
      {
        element: <PrivateRoute />,
        children: [
          {
            path: "conversations/*",
            element: <Conversations />,
            children: [
              { index: true, element: <NoConversationSelected /> },
              {
                path: ":conversationBaseId",
                element: <ConversationDetail />
              }
            ]
          },
          {
            path: "contacts/",
            element: <Contacts />
          },
          {
            path: "contacts/:contactId",
            element: <ContactDetails />
          },
          {
            path: "profile/*",
            element: <Profile />
          },
          {
            path: "dashboard",
            element: <Outlet />,
            children: [
              { index: true, element: <DashboardView /> },
              {
                path: ":contactId/:conversationBaseId",
                element: <TranscriptView />
              }
            ]
          },
          {
            path: "icons/*",
            element: <Icons />
          }
        ]
      }
    ]
  }
];

export function AppRoutes() {
  const auth = useAuth();

  const mainLoader = useCallback(
    async ({ request }: any) => {
      const url = new URL(request.url);
      const route = url.pathname;
      if (auth.isAuthenticated() && route === "/") {
        return redirect("/conversations");
      }
      return null;
    },
    [auth]
  );

  const routes = createAppRoutes(mainLoader);
  const router = createBrowserRouter(routes);
  return <RouterProvider router={router} />;
}
