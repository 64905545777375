import React from "react";
import { IconProps } from "./types";

function IconMessaging(props: IconProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      {...props}
    >
      <g fill="currentColor" data-name="Group 93" transform="translate(2 3)">
        <ellipse
          cx="1"
          cy="1"
          data-name="Ellipse 26"
          rx="1"
          ry="1"
          transform="translate(9 7)"
        ></ellipse>
        <ellipse
          cx="1"
          cy="1"
          data-name="Ellipse 27"
          rx="1"
          ry="1"
          transform="translate(13 7)"
        ></ellipse>
        <ellipse
          cx="1"
          cy="1"
          data-name="Ellipse 28"
          rx="1"
          ry="1"
          transform="translate(5 7)"
        ></ellipse>
        <path
          d="M19 3H5a3 3 0 00-3 3v15a1 1 0 001.51.86L8 19.14a1 1 0 01.55-.14H19a3 3 0 003-3V6a3 3 0 00-3-3zm1 13a1 1 0 01-1 1H8.55a3 3 0 00-1.55.43l-3 1.8V6a1 1 0 011-1h14a1 1 0 011 1z"
          data-name="Path 239"
          transform="translate(-2 -3)"
        ></path>
      </g>
    </svg>
  );
}

export default IconMessaging;
