import React from "react";
import { Conversation } from "@/types";
import ResolveConversationModal from "./ResolveConversationModal";
import useTranslations from "@/hooks/useTranslations";
import ChatAssignmentModal from "./ChatAssignmentModal";
import IconPersonAdd from "@/icons/IconPersonAdd";
import IconFormstackLogo from "@/icons/IconFormstackLogo";
import FormstackModal from "./FormstackModal";
import IconAILogo from "@velaro/velaro-shared/src/icons/IconAICursor";
import ExpandTextIcon from "@velaro/velaro-shared/src/svg/stroke-icons/expand-01.svg?react";
import ShortenTextIcon from "@velaro/velaro-shared/src/svg/stroke-icons/minimize-01.svg?react";
import AIResponseIcon from "@velaro/velaro-shared/src/svg/stroke-icons/stars-02.svg?react";
import AdjustToneIcon from "@velaro/velaro-shared/src/svg/stroke-icons/settings-03.svg?react";
import FixSpellingIcon from "@velaro/velaro-shared/src/svg/stroke-icons/type-01.svg?react";
import MessageSmileIcon from "@velaro/velaro-shared/src/svg/stroke-icons/message-smile-circle.svg?react";
import MessageChatIcon from "@velaro/velaro-shared/src/svg/stroke-icons/message-chat-circle.svg?react";
import BriefcaseIcon from "@velaro/velaro-shared/src/svg/stroke-icons/briefcase-01.svg?react";
import useApi from "@/hooks/useApi";
import { set } from "lodash";
import IconArrowForward1 from "@/icons/IconArrowForward1";
import useToastAlert from "@/hooks/useToastAlert";
import { Severity } from "@velaro/velaro-shared/src/components/ToastAlert";

interface Props {
  conversation: Conversation;
  message: string;
  setLoadingAiResponse: (loadingAiResponse: boolean) => void;
  setShowAiNotice: (showAINotice: boolean) => void;
  setMessage: (message: string) => void;
}

export default function AIChatButton({
  conversation,
  message,
  setLoadingAiResponse,
  setShowAiNotice,
  setMessage
}: Props) {
  const translation = useTranslations();
  const api = useApi();
  const toastAlert = useToastAlert();
  const [openAiWindow, setOpenAiWindow] = React.useState(false);
  const [openToneWindow, setOpenToneWindow] = React.useState(false);

  function manageWindow() {
    setOpenAiWindow(!openAiWindow);
    setOpenToneWindow(false);
  }

  function errorMessage(error: string) {
    toastAlert.displayToast(Severity.Error, error);
    setOpenAiWindow(false);
    setOpenToneWindow(false);
  }

  async function expandText() {
    console.log("Expand text");
    if (message == null || message == "") {
      errorMessage("Please enter a message to expand.");
      return;
    }
    setMessage("Expanding Text...");
    setOpenAiWindow(false);
    setOpenToneWindow(false);
    setLoadingAiResponse(true);
    const response = await api.messaging.post("Conversations/ExpandText", {
      message
    });
    if (!response.ok) {
      return;
    }
    const data = await response.json();
    setMessage(data);
    setLoadingAiResponse(false);
    setShowAiNotice(true);
  }

  async function shortenText() {
    console.log("Shorten text");
    if (message == null || message == "") {
      errorMessage("Please enter a message to shorten.");
      return;
    }
    setMessage("Shortening Text...");
    setOpenAiWindow(false);
    setOpenToneWindow(false);
    setLoadingAiResponse(true);
    const response = await api.messaging.post("Conversations/ShortenText", {
      message
    });
    const data = await response.json();
    setMessage(data);
    setLoadingAiResponse(false);
    setShowAiNotice(true);
  }

  async function aiResponse() {
    console.log("AI response");
    setMessage("AI is generating a response...");
    setOpenAiWindow(false);
    setOpenToneWindow(false);
    setLoadingAiResponse(true);
    const response = await api.messaging.post("Conversations/AIResponse", {
      conversationBaseId: conversation.id
    });
    const data = await response.json();
    setMessage(data);
    setLoadingAiResponse(false);
    setShowAiNotice(true);
  }

  async function adjustTone(tone: string) {
    console.log("Adjust tone");
    if (message == null || message == "") {
      errorMessage("Please enter a message adjust tone.");
      return;
    }
    setMessage(`Adjusting tone to ${tone}...`);
    setOpenAiWindow(false);
    setOpenToneWindow(false);
    setLoadingAiResponse(true);
    const response = await api.messaging.post("Conversations/AdjustTone", {
      message,
      tone
    });
    const data = await response.json();
    setMessage(data);
    setLoadingAiResponse(false);
    setShowAiNotice(true);
  }

  async function fixSpelling() {
    console.log("Fix spelling and grammar");
    if (message == null || message == "") {
      errorMessage("No message entered to fix spelling and grammar.");
      return;
    }
    setMessage(`Fixing spelling and grammar...`);
    setOpenAiWindow(false);
    setOpenToneWindow(false);
    setLoadingAiResponse(true);
    const response = await api.messaging.post(
      "Conversations/SpellingGrammarFix",
      {
        message
      }
    );
    const data = await response.json();
    setMessage(data);
    setLoadingAiResponse(false);
    setShowAiNotice(true);
  }

  return (
    <>
      <div className="flex justify-center h-8 w-8">
        <button className="" onClick={manageWindow}>
          <div className="flex flex-col items-center">
            <IconAILogo className="h-6" />
            <div className="text-xs"></div>
          </div>
        </button>
      </div>
      {openAiWindow && (
        <div className="absolute w-64 shadow-lg rounded-[4px] border-[1px] border-slate-gray-200 top-[-145px] left-[106px] bg-white">
          <div className="flex flex-col p-2 text-left">
            <button
              className="h-[37px] group hover:bg-cornflower-blue-50 rounded-lg"
              onClick={expandText}
            >
              <div className="flex flex-row pl-4 gap-2 items-center text-slate-gray-800 group-hover:text-cornflower-blue-500">
                <ExpandTextIcon className="h-4 w-4 text-slate-gray-500 group-hover:text-cornflower-blue-500" />
                Expand text
              </div>
            </button>
            <button
              className="h-[37px] group hover:bg-cornflower-blue-50 rounded-lg"
              onClick={shortenText}
            >
              <div className="flex flex-row pl-4 gap-2 items-center text-slate-gray-800 group-hover:text-cornflower-blue-500">
                <ShortenTextIcon className="h-4 w-4 text-slate-gray-500 group-hover:text-cornflower-blue-500" />
                Shorten
              </div>
            </button>
            <button
              className="h-[37px] group hover:bg-cornflower-blue-50 rounded-lg"
              onClick={aiResponse}
            >
              <div className="flex flex-row pl-4 gap-2 items-center text-slate-gray-800 group-hover:text-cornflower-blue-500">
                <AIResponseIcon className="h-4 w-4 text-slate-gray-500 group-hover:text-cornflower-blue-500" />
                AI response
              </div>
            </button>
            <button
              className="h-[37px] group hover:bg-cornflower-blue-50 rounded-lg"
              onClick={() => setOpenToneWindow(!openToneWindow)}
            >
              <div className="flex flex-row pl-4 gap-2 items-center text-slate-gray-800 group-hover:text-cornflower-blue-500">
                <AdjustToneIcon className="h-4 w-4 text-slate-gray-500 group-hover:text-cornflower-blue-500" />
                Adjust tone
                <IconArrowForward1 className="ml-20 h-[22px] w-[22px] text-slate-gray-500" />
              </div>
            </button>
            <button
              className="h-[37px] group hover:bg-cornflower-blue-50 rounded-lg"
              onClick={fixSpelling}
            >
              <div className="flex flex-row pl-4 gap-2 items-center text-slate-gray-800 group-hover:text-cornflower-blue-500">
                <FixSpellingIcon className="h-4 w-4 text-slate-gray-500 group-hover:text-cornflower-blue-500" />
                Fix spelling and grammar
              </div>
            </button>
          </div>
        </div>
      )}
      {openToneWindow && (
        <div className="absolute w-56 h-32 top-[-115px] left-[368px] shadow-lg rounded-[4px] border-[1px] border-slate-gray-200 bg-white">
          <div className="flex flex-col p-2 text-left">
            <button
              className="h-[37px] group hover:bg-cornflower-blue-50 rounded-lg"
              onClick={() => adjustTone("friendly")}
            >
              <div className="flex flex-row pl-2 gap-2 items-center text-slate-gray-800 group-hover:text-cornflower-blue-500">
                <MessageSmileIcon className="h-6 w-6 text-slate-gray-500 group-hover:text-cornflower-blue-500" />
                More friendly
              </div>
            </button>
            <button
              className="h-[37px] group hover:bg-cornflower-blue-50 rounded-lg"
              onClick={() => adjustTone("casual")}
            >
              <div className="flex flex-row pl-2 gap-2 items-center text-slate-gray-800 group-hover:text-cornflower-blue-500">
                <MessageChatIcon className="h-6 w-6 text-slate-gray-500 group-hover:text-cornflower-blue-500" />
                More casual
              </div>
            </button>
            <button
              className="h-[37px] group hover:bg-cornflower-blue-50 rounded-lg"
              onClick={() => adjustTone("professional")}
            >
              <div className="flex flex-row pl-2 gap-2 items-center text-slate-gray-800 group-hover:text-cornflower-blue-500">
                <BriefcaseIcon className="h-6 w-6 text-slate-gray-500 group-hover:text-cornflower-blue-500" />
                More professional
              </div>
            </button>
          </div>
        </div>
      )}
    </>
  );
}
