import React, { useMemo, useState } from "react";
import IconNoConversation from "@velaro/velaro-shared/src/icons/IconNoConversation";
import FilledBadge from "@velaro/velaro-shared/src/components/Badges/FilledBadge";
import { Conversation } from "@/types";
import format from "date-fns/format";

interface Props {
  conversations: Conversation[];
}

export default function ConversationHistory(props: Props) {
  const [buttonText, setButtonText] = useState("Load more");
  const [shownConversations, setShownConversations] = useState<Conversation[]>(
    props.conversations.slice(0, 5)
  );
  function getStatusColor(status: string) {
    switch (status) {
      case "Open":
        return "bg-blue-100";
      case "Resolved":
        return "bg-emerald-100";
      default:
        return "bg-blue-100";
    }
  }

  function handleMoreOrLess() {
    if (buttonText === "Load more") {
      setShownConversations(props.conversations);
      setButtonText("Less");
    } else {
      setShownConversations(props.conversations.slice(0, 5));
      setButtonText("Load more");
    }
  }

  if (props.conversations.length === 0) {
    return (
      <div className="bg-white rounded-lg p-4 mb-4">
        <div className="text-sm font-semibold mb-4">Conversation History</div>
        <div className="w-full flex flex-col justify-center items-center">
          <div className="mb-4">
            <IconNoConversation />
          </div>
          <div className="text-slate-400 text-xs">
            There is no conversation available.
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="bg-white rounded-lg p-4 mb-4">
      <div className="text-sm font-semibold mb-4">Conversation History</div>
      {shownConversations.map((conversation, index) => (
        <div
          key={index}
          className="w-full px-3 py-2 mb-4 bg-slate-50 rounded border border-slate-100"
        >
          <div className="flex justify-between items-center">
            <div className="text-sm">{conversation.topic}</div>
            <FilledBadge
              label={conversation.status}
              size="sm"
              backgroundColor={getStatusColor(conversation.status)}
              textColor="text-slate-800"
            />
          </div>
          <div className="text-xs text-slate-500">
            {format(new Date(conversation.startTimestamp), "M/d/yyyy")}
          </div>
        </div>
      ))}
      {props.conversations.length > 5 && (
        <div
          className="text-xs text-blue-500 font-semibold hover: cursor-pointer"
          onClick={() => handleMoreOrLess()}
        >
          {buttonText}
        </div>
      )}
    </div>
  );
}
