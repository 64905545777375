import React from "react";
import debounce from "lodash/debounce";

function getContainerHeight(target: HTMLElement) {
  return target.scrollHeight - target.offsetHeight;
}

/**
 * This hook lets us know when we should attempt to load more
 * messages and repositions the scrollbar after messages have
 * been loaded.
 */

export default function useInfiniteScroll(
  ref: React.RefObject<HTMLElement>,
  items: any[],
  loadMore: () => void
) {
  const scrollBottom = React.useRef(0);
  const wasAtTop = React.useRef(false);

  const debouncedLoadMore = React.useMemo(() => {
    return debounce(loadMore, 200, { leading: true, trailing: false });
  }, [loadMore]);

  // Fire the loadMore fn when the scrollbar is near the top.

  React.useEffect(() => {
    const container = ref.current;

    if (!container) {
      return;
    }

    function handleScroll(e: any) {
      const containerHeight = getContainerHeight(e.currentTarget);

      if (e.currentTarget.scrollTop < 200) {
        wasAtTop.current = true;
        scrollBottom.current = containerHeight - e.currentTarget.scrollTop;
        debouncedLoadMore();
      } else {
        wasAtTop.current = false;
      }
    }

    container.addEventListener("scroll", handleScroll);

    return () => {
      container.removeEventListener("scroll", handleScroll);
    };
  }, [debouncedLoadMore, ref]);

  // Reposition the scrollbar after the new messages have been rendered,
  // but only if they were near the top. We don't want newly received
  // messages to reposition the scrollbar.

  React.useLayoutEffect(() => {
    if (!ref.current) {
      return;
    }

    if (scrollBottom.current === 0) {
      return;
    }

    if (!wasAtTop.current) {
      return;
    }

    const containerHeight = getContainerHeight(ref.current);
    ref.current.scrollTop = containerHeight - scrollBottom.current;
    wasAtTop.current = false;
  }, [items, ref, scrollBottom]);
}
