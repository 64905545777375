import React, { useState } from "react";
import InboxTabContent from "./InboxTabContent";
import useScreenWidth from "@/hooks/useScreenWidth";
import ConversationFilter from "./ConversationFilter";
import useInbox from "@/hooks/useInbox";
import useTranslations from "@/hooks/useTranslations";
import { useResizeFrame } from "@/hooks/useResizeFrame";
import useApi from "@/hooks/useApi";
import { useNavigate } from "react-router-dom";
import IconSearch from "@/icons/IconSearch";
import useIsMobile from "@/hooks/useIsMobile";
import useNotification from "@/hooks/useNotification";
import { TeriaryBlueButton } from "@velaro/velaro-shared/src/components/Buttons/Buttons";
import DefaultTabs from "@velaro/velaro-shared/src/components/tabs/DefaultTabs";

export default function Inbox() {
  const { settings, setSettings } = useInbox();
  const screenWidth = useScreenWidth();
  const translation = useTranslations();
  const api = useApi();
  const navigate = useNavigate();
  const { width, onMouseDown } = useResizeFrame("right", 400);
  const isMobile = useIsMobile();
  const notification = useNotification();

  async function resolveAllConversations() {
    await api.messaging.post(`Conversations/ResolveAll`);
    navigate("/conversations");
  }

  const myStyles = {
    width: isMobile ? "100%" : width + "px",
    maxWidth: isMobile ? undefined : "500px"
  };

  return (
    <>
      <div style={myStyles} className={`h-full bg-white flex flex-col`}>
        <>
          <div className="h-16 flex items-center">
            <div className="ml-4 text-slate-gray-600 dark:text-white font-bold">
              {translation.key("inbox")}
            </div>
            {import.meta.env.DEV && (
              <span className="w-full">
                <TeriaryBlueButton
                  label="Resolve All"
                  onClick={resolveAllConversations}
                />
              </span>
            )}
            <div className="ml-auto w-full">
              <ConversationFilter />
            </div>
          </div>

          <div className="m-4 grow shrink basis-0 p-3 bg-white rounded border border-zinc-200 justify-between items-center flex">
            <input
              className="text-base font-normal font-['Rubik'] leading-normal border-none border-0 outline-none w-full"
              placeholder={translation.key("search_here")}
              value={settings.searchQuery}
              onChange={(e) =>
                setSettings({ ...settings, searchQuery: e.target.value })
              }
            />
            <div className="justify-center items-center flex">
              <div className="w-6 h-6 relative">
                <IconSearch />
              </div>
            </div>
          </div>
          <div className="flex items-center justify-center">
            <DefaultTabs
              label={translation.key("mine")}
              active={settings.selectedTab === "mine"}
              onClick={() => {
                setSettings({
                  ...settings,
                  selectedTab: "mine"
                });
              }}
            />
            <DefaultTabs
              label={translation.key("all")}
              active={settings.selectedTab === "all"}
              onClick={() => {
                setSettings({
                  ...settings,
                  selectedTab: "all"
                });
              }}
            />
          </div>
        </>
        <div className="overflow-y-auto h-full">
          <InboxTabContent />
        </div>
      </div>
      <div
        onMouseDown={onMouseDown}
        className="h-full w-[2px] border-r-2 cursor-col-resize"
      ></div>
    </>
  );
}
