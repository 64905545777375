import useApi from "@/hooks/useApi";
import useToastAlert from "@/hooks/useToastAlert";
import { Contact, Conversation } from "@/types";
import { Severity } from "@velaro/velaro-shared/src/components/ToastAlert";
import IconClose from "@velaro/velaro-shared/src/icons/IconClose";
import React, { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import ContactDetailsHeader from "./ContactDetailsHeader";
import LoadingSpinner from "@velaro/velaro-shared/src/components/LoadingSpinner";
import ContactInformation from "./ContactInformation";
import ConversationHistory from "./ConversationHistory";
import EventTimeline from "./EventTimeline";
import PostSurvey from "./PostSurvey";
import FilePanel from "./FilePanel";
import ContactForm from "../ContactForm";
import Tags from "@/pages/Conversations/Tags";
import useTranslations from "@/hooks/useTranslations";
import useContactTags from "@/hooks/useContactTags";
import { TimelineEvent } from "../types";

export default function ContactDetails() {
  const { contactId: stringContactId } = useParams<{ contactId: string }>();
  const contactId = parseInt(stringContactId!);
  const [contact, setContact] = useState<Contact | null>(null);
  const [editing, setEditing] = useState(false);
  const [saving, setSaving] = useState(false);
  const [errors, setErrors] = useState<Record<string, string>>({});
  const [conversations, setConversations] = useState<Conversation[]>([]);
  const [events, setEvents] = useState<TimelineEvent[]>([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const api = useApi();
  const toastAlert = useToastAlert();
  const translation = useTranslations();
  const contactTags = useContactTags(contact?.id);

  React.useEffect(() => {
    async function load() {
      async function fetchContacts() {
        const response = await api.messaging.get(`Contacts/${contactId}`);
        if (!response.ok) {
          toastAlert.displayToast(Severity.Error, "Failed to fetch contact");
          return;
        }
        setContact(await response.json());
      }

      async function fetchConversations() {
        const response = await api.messaging.get(
          `Conversations/Contact/${contactId}`
        );
        if (!response.ok) {
          toastAlert.displayToast(
            Severity.Error,
            "Failed to fetch conversations"
          );
          return;
        }
        setConversations(await response.json());
      }

      async function fetchEvents() {
        const response = await api.messaging.get(
          `EventTimeline/Contact/${contactId}`
        );
        if (!response.ok) {
          toastAlert.displayToast(Severity.Error, "Failed to fetch events");
          return;
        }
        setEvents(await response.json());
      }

      await Promise.all([fetchContacts(), fetchConversations(), fetchEvents()]);
      setLoading(false);
    }

    load();
  }, [api.messaging, contactId, toastAlert]);

  if (loading) {
    return <LoadingSpinner />;
  }

  if (contact == null) {
    return <LoadingSpinner />;
  }

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setContact({ ...contact, [name]: value });
  };

  async function handleSave() {
    setSaving(true);
    const response = await api.messaging.put(`Contacts/${contactId}`, contact);
    if (response.ok) {
      setEditing(false);
      toastAlert.displayToast(Severity.Success, "Contact updated successfully");
    } else if (response.status === 400) {
      const errorResponse = await response.json();
      if (errorResponse.errors == null) {
        toastAlert.displayToast(Severity.Error, errorResponse);
        setSaving(false);
        return;
      }
      setErrors(errorResponse.errors);
    }
    setSaving(false);
  }

  return (
    <div className="p-4 h-full bg-slate-50 text-slate-800 overflow-y-auto">
      <div className="w-full flex justify-end mb-4">
        <div
          className="hover:cursor-pointer"
          onClick={() => navigate(`/contacts/`)}
        >
          <IconClose />
        </div>
      </div>
      <ContactDetailsHeader
        contact={contact}
        editing={editing}
        setEditing={setEditing}
        handleSave={handleSave}
        saving={saving}
      />
      {!editing && (
        <div className="grid grid-cols-3 gap-4">
          <div>
            <ContactInformation contact={contact} />
            <div className="bg-white rounded-lg p-4 mb-4">
              <Tags
                label={translation.key("tags")}
                labelSize="sm"
                badgeSize="sm"
                allTags={contactTags.all}
                selectedTags={contactTags.selected}
                onSave={contactTags.save}
                onRemove={contactTags.remove}
                createTag={contactTags.create}
                updateTag={contactTags.update}
                saving={contactTags.saving}
              />
            </div>
          </div>
          <div>
            <ConversationHistory conversations={conversations} />
            {/* <PostSurvey /> */}
            {/* <FilePanel /> */}
          </div>
          <div>
            <EventTimeline events={events} />
          </div>
        </div>
      )}
      {editing && (
        <div className="p-6 bg-white rounded-lg">
          <ContactForm
            contact={contact}
            setContact={setContact}
            errors={errors}
            handleChange={handleChange}
          />
        </div>
      )}
    </div>
  );
}
