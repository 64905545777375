import useConversationNotes from "@/hooks/useConversationNotes";
import useConversationTags from "@/hooks/useConversationTags";
import useConversationAttributes from "@/hooks/useConversationAttributes";
import React from "react";
import Notes from "../Notes";
import Tags from "../Tags";
import Panel from "./Panel";
import Attributes from "./ConversationAttributes";
import useTranslations from "@/hooks/useTranslations";

interface Props {
  conversationBaseId: number;
}

export default function ConversationDetailsPanel(props: Props) {
  const conversationNotes = useConversationNotes(props.conversationBaseId);
  const conversationTags = useConversationTags(props.conversationBaseId);
  const { conversationAttributes } = useConversationAttributes(
    props.conversationBaseId
  );
  const translation = useTranslations();

  return (
    <Panel title={translation.key("conversation_details")}>
      <div className="p-4">
        <div className="mb-4">
          <Notes
            label={translation.key("conversation_notes")}
            modalTitle="Conversation notes"
            notes={conversationNotes.data}
            onAddNote={conversationNotes.add}
            conversationBaseId={props.conversationBaseId}
          />
        </div>
        <div className="mb-4">
          <Tags
            label={translation.key("conversation_tags")}
            allTags={conversationTags.all}
            selectedTags={conversationTags.selected}
            onSave={conversationTags.save}
            onRemove={conversationTags.remove}
            createTag={conversationTags.create}
            updateTag={conversationTags.update}
            saving={conversationTags.saving}
          />
        </div>
        <div className="">
          <Attributes attributes={conversationAttributes} />
        </div>
      </div>
    </Panel>
  );
}
