import React from "react";
import AuthService from "../services/AuthService";
import RestService from "../services/RestService";

export default function useApi() {
  return React.useMemo(
    () => ({
      main: new RestService({
        rootUrl: `${import.meta.env.VITE_MAIN_API_URL!}/v1`,
        tokenAccessor: () => AuthService.legacyToken.token
      }),

      messaging: new RestService({
        rootUrl: import.meta.env.VITE_API_URL! as string,
        tokenAccessor: () => AuthService.token.token
      })
    }),
    []
  );
}
