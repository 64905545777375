import Avatar from "@/components/Avatar";
import { Contact } from "@/types";
import {
  PrimaryBlueButton,
  PrimaryGrayButton
} from "@velaro/velaro-shared/src/components/Buttons/Buttons";
import IconVerticalEllipsis from "@velaro/velaro-shared/src/icons/IconVerticalEllipsis";
import React from "react";

interface Props {
  contact: Contact;
  editing: boolean;
  setEditing: (editing: boolean) => void;
  handleSave: () => void;
  saving: boolean;
}

export default function ContactDetailsHeader({
  contact,
  editing,
  setEditing,
  handleSave,
  saving
}: Props) {
  function formatName(contact: Contact) {
    if (!contact) return "Guest";
    if (contact.firstName && contact.lastName) {
      return contact.firstName + " " + contact.lastName;
    }
    return (
      contact.firstName || contact.lastName || contact.fallbackName || "Guest"
    );
  }

  return (
    <div className="flex bg-white w-full rounded-lg p-6 justify-between items-center mb-4">
      <div className="flex">
        <Avatar
          name={formatName(contact)}
          url={contact?.picture}
          size="68px"
          fontSize="24px"
        />
        <div className="flex flex-col mx-4 justify-center">
          <div className="text-lg text-slate-800">{formatName(contact)}</div>
          {contact?.jobTitle && (
            <div className="text-slate-800 text-sm font-normal">
              {contact?.jobTitle}
            </div>
          )}
          {contact?.company && (
            <div className="text-slate-800 text-sm font-normal">
              {contact?.company}
            </div>
          )}
        </div>
      </div>
      <div className="flex items-center">
        <div className="mr-6">
          {editing && (
            <PrimaryBlueButton
              label="Save"
              onClick={() => handleSave()}
              disabled={saving}
            />
          )}
          {!editing && (
            <PrimaryGrayButton label="Edit" onClick={() => setEditing(true)} />
          )}
        </div>
        {/* <div className="hover:cursor-pointer">
          <IconVerticalEllipsis />
        </div> */}
      </div>
    </div>
  );
}
