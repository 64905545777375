import { useState, useCallback, useEffect } from "react";

export function useResizeFrame(direction: "left" | "right", baseWidth: number) {
  const [dragging, setDragging] = useState(false);
  const [x, setX] = useState(0);
  const [initialWidth, setInitialWidth] = useState(baseWidth);
  const [width, setWidth] = useState(baseWidth);

  const onMouseDown = useCallback(
    (e: any) => {
      setDragging(true);
      setX(e.clientX);
      setInitialWidth(width);
    },
    [width]
  );

  const resizeFrame = useCallback(
    (e: MouseEvent) => {
      if (!dragging || !x) {
        return;
      }

      const xDiff = Math.abs(x - e.clientX);

      let newW = 0;
      if (direction === "right") {
        newW = x > e.clientX ? initialWidth - xDiff : initialWidth + xDiff;
      } else {
        newW = x > e.clientX ? initialWidth + xDiff : initialWidth - xDiff;
      }
      setWidth(newW <= 500 ? newW : 500);
    },
    [direction, dragging, initialWidth, x]
  );

  useEffect(() => {
    document.addEventListener("mousemove", resizeFrame);
    document.addEventListener("mouseup", () => setDragging(false));
    return () => {
      document.removeEventListener("mousemove", resizeFrame);
      document.removeEventListener("mouseup", () => setDragging(false));
    };
  }, [resizeFrame]);

  return { onMouseDown, width };
}
