import useTranslations from "@/hooks/useTranslations";
import { DaySeparatorType } from "@/reducers/messageGroupReducer";
import isToday from "date-fns/isToday";
import isYesterday from "date-fns/isYesterday";
import React from "react";

interface Props {
  item: DaySeparatorType;
}

export default function DaySeparator(props: Props) {
  const date = new Date(props.item.timestamp);
  const translation = useTranslations();
  let dayString = date.toLocaleDateString();

  if (isToday(date)) {
    dayString = translation.key("today");
  }

  if (isYesterday(date)) {
    dayString = translation.key("yesterday");
  }

  return (
    <div className="py-6 flex items-center justify-center">
      <div className="grow shrink basis-0 h-px bg-slate-gray-500"></div>
      <div className="text-right text-slate-gray-500 text-xs font-normal font-['Rubik'] leading-none px-2.5 justify-end items-center gap-2.5 flex">
        {dayString}
      </div>
      <div className="grow shrink basis-0 h-px bg-slate-gray-500"></div>
    </div>
  );
}
