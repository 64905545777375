import useTranslations from "@/hooks/useTranslations";

import React from "react";

export default function EndConversationSeparator() {
  const translation = useTranslations();

  return (
    <div className="py-6 flex items-center justify-center">
      <div className="grow shrink basis-0 h-px bg-slate-gray-500"></div>
      <div className="text-right text-slate-gray-500 text-xs font-normal font-['Rubik'] leading-none px-2.5 justify-end items-center gap-2.5 flex">
        {translation.key("end_of_conversation")}
      </div>
      <div className="grow shrink basis-0 h-px bg-slate-gray-500"></div>
    </div>
  );
}
