import React from "react";
import { IconProps } from "./types";

function IconTagOutline(props: IconProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      {...props}
    >
      <g>
        <g fill="currentColor">
          <path
            d="M12.87 22a1.84 1.84 0 01-1.29-.53l-6.41-6.42a1 1 0 01-.29-.61L4 5.09A1 1 0 015.09 4l9.35.88a1 1 0 01.61.29l6.42 6.41a1.82 1.82 0 010 2.57l-7.32 7.32a1.82 1.82 0 01-1.28.53zm-6-8.11l6 6 7.05-7.05-6-6-7.81-.73z"
            data-name="Path 1749"
          ></path>
          <circle
            cx="1.5"
            cy="1.5"
            r="1.5"
            data-name="Ellipse 45"
            transform="translate(9 9)"
          ></circle>
        </g>
      </g>
    </svg>
  );
}

export default IconTagOutline;
