import React from "react";
import { IconProps } from "./types";

function IconSentimentPositive(props: IconProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      {...props}
    >
      <path
        fill="currentColor"
        d="M16 16H0V0h16v16zM4.585 8.833a.868.868 0 00-.528.178.878.878 0 00-.173 1.222A5.529 5.529 0 008 12.291a5.476 5.476 0 004.115-2.058.877.877 0 00-1.4-1.052A3.758 3.758 0 018 10.537a3.766 3.766 0 01-2.716-1.36.875.875 0 00-.699-.344zm5.976-4.271a.881.881 0 10.008 0zm-5.122 0a.881.881 0 10.008 0z"
        data-name="Exclusion 1"
      ></path>
    </svg>
  );
}

export default IconSentimentPositive;
