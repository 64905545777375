import React from "react";
import { IconProps } from "./types";

function IconNotes(props: IconProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      {...props}
    >
      <g
        fill="currentColor"
        data-name="Group 1002"
        transform="translate(4141 -532)"
      >
        <path
          d="M8 14v-4a3 3 0 013-3h3V2H2v12h6m2 2H1a1 1 0 01-1-1V1a1 1 0 011-1h14a1 1 0 011 1v8h-5a1 1 0 00-1 1z"
          data-name="Subtraction 8"
          transform="translate(-4137 536)"
        ></path>
        <path
          d="M-4125.969 545.5h3.932a.5.5 0 01.45.281.5.5 0 01-.056.527l-3.932 5.016a.5.5 0 01-.557.164.5.5 0 01-.337-.473V546a.5.5 0 01.5-.5z"
          data-name="Path 2008"
          transform="translate(.552 .516)"
        ></path>
      </g>
    </svg>
  );
}

export default IconNotes;
