import React from "react";
import { IconProps } from "./types";

function IconNotificationsBell(props: IconProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="currentColor"
      {...props}
    >
      <g data-name="Group 97" transform="translate(2 1)">
        <g fill="currentColor" data-name="Notifications bell">
          <path
            d="M21.777 18.5H3.981a1 1 0 01-.964-.717.987.987 0 01.409-1.111A5.065 5.065 0 004.674 15a14.183 14.183 0 001.284-6.5 6.921 6.921 0 1113.842 0 14.183 14.183 0 001.284 6.5 5.065 5.065 0 001.248 1.674.987.987 0 01.409 1.111 1 1 0 01-.964.715zm-15.671-2h13.546q-.113-.187-.226-.4a16.012 16.012 0 01-1.6-7.605 4.944 4.944 0 10-9.887 0 16.012 16.012 0 01-1.6 7.605q-.12.216-.233.4z"
            data-name="Path 176"
            transform="translate(-2.977 -1.5)"
          ></path>
          <path
            d="M18.221 33.461a5.128 5.128 0 01-4.11-1.851 1.008 1.008 0 01.18-1.4.981.981 0 011.387.182 3.209 3.209 0 002.544 1.071 3.209 3.209 0 002.544-1.071.981.981 0 011.387-.182 1.008 1.008 0 01.18 1.4 5.128 5.128 0 01-4.112 1.851z"
            data-name="Path 177"
            transform="translate(-8.617 -11.461)"
          ></path>
        </g>
      </g>
    </svg>
  );
}

export default IconNotificationsBell;
