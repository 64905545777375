import AuthService from "../services/AuthService";

interface Config {
  rootUrl: string;
  tokenAccessor: () => string;
}

export default class RestService {
  rootUrl = "";
  tokenAccessor: () => string = () => "";

  constructor(config: Config) {
    this.rootUrl = config.rootUrl;
    this.tokenAccessor = config.tokenAccessor;
  }

  get accessToken() {
    return this.tokenAccessor();
  }

  getCommonHeaders() {
    const headers = {
      Authorization: `Bearer ${this.accessToken}`,
      SiteId: AuthService.profile.siteId.toString()
    };

    return headers;
  }

  get(endpoint: string) {
    return fetch(`${this.rootUrl}/${endpoint}`, {
      method: "get",
      headers: {
        "Content-Type": "application/json",
        ...this.getCommonHeaders()
      }
    });
  }

  post(endpoint: string, data?: any) {
    return fetch(`${this.rootUrl}/${endpoint}`, {
      method: "post",
      body: data ? JSON.stringify(data) : undefined,
      headers: {
        "Content-Type": "application/json",
        ...this.getCommonHeaders()
      }
    });
  }

  postFormData(endpoint: string, formData: FormData) {
    return fetch(`${this.rootUrl}/${endpoint}`, {
      method: "post",
      body: formData,
      headers: {
        ...this.getCommonHeaders()
      }
    });
  }

  put(endpoint: string, data?: any) {
    return fetch(`${this.rootUrl}/${endpoint}`, {
      method: "put",
      body: data ? JSON.stringify(data) : undefined,
      headers: {
        "Content-Type": "application/json",
        ...this.getCommonHeaders()
      }
    });
  }

  delete(endpoint: string, data?: any) {
    return fetch(`${this.rootUrl}/${endpoint}`, {
      method: "delete",
      body: data ? JSON.stringify(data) : undefined,
      headers: {
        "Content-Type": "application/json",
        ...this.getCommonHeaders()
      }
    });
  }
}
