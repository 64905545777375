import React, { useEffect } from "react";
import SafeMarkdown from "@/components/SafeMarkdown";
import FormattedTime from "./FormattedTime";
import { Conversation } from "@/types";
import { format } from "date-fns";
import IconGreenCheckMarkCircle from "@velaro/velaro-shared/src/icons/IconGreenCheckMarkCircle";
import IconAutoCloseCircle from "@velaro/velaro-shared/src/icons/IconAutoCloseCircle";
import FormattedDate from "./FormattedDate";
import useTranslations from "@/hooks/useTranslations";

interface Props {
  conversation: Conversation;
  status: UpdateStatus;
}

export enum UpdateStatus {
  Resolved = "Resolved",
  AutoClosed = "AutoClosed"
}

export default function ChatUpdateMessageGroup(props: Props) {
  const startDate = format(
    new Date(props.conversation.startTimestamp),
    "M/d/yyyy"
  );
  const translation = useTranslations();
  var resolvedBy = props.conversation.resolvedBy;

  if (props.conversation.resolvedBy == null) {
    resolvedBy = "A user";
  }

  const statusText = {
    Resolved: resolvedBy + " " + translation.key("set_this_as") + " ",
    AutoClosed: translation.key("auto_closed_text") + " "
  };

  const iconVariants = {
    Resolved: <IconGreenCheckMarkCircle className="w-10 h-10" />,
    AutoClosed: <IconAutoCloseCircle className="w-10 h-10" />
  };

  const textColors = {
    Resolved: "text-success-green-500",
    AutoClosed: "text-crimson-red-500"
  };

  const textVariant = {
    Resolved: translation.key("resolved"),
    AutoClosed: translation.key("auto_closed")
  };

  return (
    <div className="flex flex-col justify-start items-start w-full overflow-hidden ">
      <div className="flex flex-row justify-start items-start w-full overflow-hidden py-4 ">
        <div className="mr-4 flex flex-col-reverse h-10 w-10">
          {
            iconVariants[
              UpdateStatus[props.status] as keyof typeof UpdateStatus
            ]
          }
        </div>
        <div className="flex-1 overflow-hidden">
          <div className="flex flex-col items-start max-w-full">
            <div className=" dark:bg-stone-200 dark:text-slate-gray-100 rounded-md rounded-bl-2xl ">
              <div className="flex text-slate-gray-500 text-sm font-normal font-['Rubik']">
                <div className="mr-2">
                  {translation.key("conversation_closed")}
                </div>
                <div className="mr-2">
                  <FormattedDate isoDate={props.conversation.startTimestamp} />
                </div>
                <div>
                  <FormattedTime isoDate={props.conversation.endTimestamp} />
                </div>
              </div>
              <div className="whitespace-pre-wrap break-words flex flex-row text-sm">
                <SafeMarkdown
                  text={
                    statusText[
                      UpdateStatus[props.status] as keyof typeof UpdateStatus
                    ]
                  }
                />
                <div
                  className={
                    textColors[
                      UpdateStatus[props.status] as keyof typeof UpdateStatus
                    ]
                  }
                >
                  {
                    textVariant[
                      UpdateStatus[props.status] as keyof typeof UpdateStatus
                    ]
                  }
                </div>
                <div className="text-slate-gray-500 text-sm">.</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
