import React from "react";

interface Props {
  children: React.ReactNode;
  className?: string;
}

export default function MessageItem(props: Props) {
  return (
    <div className={`${props.className || ""} message-item`}>
      {props.children}
    </div>
  );
}
