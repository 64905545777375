import { Contact, Conversation, ConversationStatus } from "@/types";
import { ConversationListAction } from "./conversationListReducer";

type SetConversationAction = {
  type: "SET_CONVERSATION";
  conversation: Conversation;
};

type AssignGroupAction = {
  type: "ASSIGN_GROUP";
  groupId: number | undefined;
};

type UpdateContactAction = {
  type: "UPDATE_CONTACT";
  contact: Contact;
};

type AssignUserAction = {
  type: "ASSIGN_USER";
  user: {
    id: number;
    userName: string;
    email: string;
    name: string;
    picture: string;
  };
};

type UnassignUserAction = {
  type: "UNASSIGN_USER";
};

type UpdateTopicAction = {
  type: "UPDATE_TOPIC";
  topic: string;
};

type ResolveConversationAction = {
  type: "RESOLVE_CONVERSATION";
  conversation: Conversation;
};

export type Action =
  | SetConversationAction
  | AssignGroupAction
  | UpdateContactAction
  | AssignUserAction
  | UnassignUserAction
  | UpdateTopicAction
  | ResolveConversationAction;

export default function conversationReducer(
  state: Conversation,
  action: Action
): Conversation {
  switch (action.type) {
    case "SET_CONVERSATION": {
      return { ...action.conversation };
    }
    case "ASSIGN_GROUP": {
      return { ...state, groupId: action.groupId };
    }
    case "UPDATE_CONTACT": {
      return {
        ...state,
        contact: { ...action.contact }
      };
    }
    case "ASSIGN_USER": {
      return {
        ...state,
        assignedUserId: action.user.id,
        assignedUser: { ...action.user }
      };
    }
    case "UNASSIGN_USER": {
      return {
        ...state,
        assignedUserId: undefined,
        assignedUser: undefined
      };
    }
    case "UPDATE_TOPIC": {
      return {
        ...state,
        topic: action.topic
      };
    }
    case "RESOLVE_CONVERSATION": {
      return ResolveConversation(state, action);
    }
    default: {
      throw new Error(`Unsupported action type: ${(action as any).type}`);
    }
  }
}

function ResolveConversation(
  state: Conversation,
  action: ResolveConversationAction
) {
  const clone = { ...state, status: "Resolved" } as Conversation;
  return clone;
}
