import React from "react";
import { IconProps } from "./types";

function IconAttachment(props: IconProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      {...props}
    >
      <path fill="none" d="M0 0H24V24H0z" data-name="Rectangle 1538"></path>
      <path
        fill="currentColor"
        d="M15.914 8.316l-1.268-1.268L8.3 13.39a2.691 2.691 0 003.808 3.81l7.613-7.611a4.485 4.485 0 10-6.343-6.342l-7.994 7.987-.017.017a6.256 6.256 0 008.848 8.849l.016-.017 5.456-5.455-1.269-1.268-5.456 5.454-.017.016a4.461 4.461 0 01-6.31-6.308l.017-.017 7.994-7.995a2.691 2.691 0 113.806 3.805l-7.613 7.611a.9.9 0 11-1.269-1.267l6.344-6.343z"
        data-name="Icon metro-attachment"
        transform="translate(-.535 .072)"
      ></path>
    </svg>
  );
}

export default IconAttachment;
