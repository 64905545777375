import React from "react";
import { IconProps } from "./types";

function IconPriorityFilled(props: IconProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      {...props}
    >
      <path fill="none" d="M0 0H24V24H0z" data-name="Rectangle 1996"></path>
      <path
        fill="currentColor"
        stroke="rgba(0,0,0,0)"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1"
        d="M12.001 19.349l-6.8 3.573 1.3-7.569-5.5-5.361 7.6-1.1L12.001 2l3.4 6.888 7.6 1.1-5.5 5.361 1.3 7.569zm-4.419-5.846v-.024l-.286-.254z"
        data-name="Path 1766"
      ></path>
    </svg>
  );
}

export default IconPriorityFilled;
