import React from "react";
import { IconProps } from "./types";

function IconLiveChatMenuLarge(props: IconProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="28"
      height="28"
      viewBox="0 0 28 28"
      {...props}
    >
      <g data-name="Group 306" transform="translate(0 .001)">
        <path fill="none" d="M0 0H28V28H0z" data-name="Rectangle 568"></path>
        <g
          fill="currentColor"
          data-name="Group 1010"
          transform="translate(1 2.999)"
        >
          <path
            d="M23.06 6.345H12.742a2.172 2.172 0 00-2.05 2.272v7.4a2.173 2.173 0 002.05 2.273h4.482l4.247 4.146V18.29h1.589a2.172 2.172 0 002.049-2.273v-7.4a2.171 2.171 0 00-2.049-2.272"
            data-name="Path 449"
            transform="translate(.892 1.278)"
          ></path>
          <path
            d="M15.329 0H2.539A2.692 2.692 0 000 2.817v9.171A2.691 2.691 0 002.539 14.8H4.51v5.14l5.263-5.14h5.556a2.691 2.691 0 002.539-2.816V2.817A2.692 2.692 0 0015.329 0m.771 11.988a.814.814 0 01-.768.852H9.1L6.281 15.6v-2.76H2.539a.814.814 0 01-.768-.852V2.816a.814.814 0 01.768-.852h12.79a.814.814 0 01.768.852z"
            data-name="Path 450"
          ></path>
          <path
            d="M12.992 5.014H4.113a.8.8 0 010-1.6h8.878a.8.8 0 010 1.6"
            data-name="Path 451"
            transform="translate(.277 1.285)"
          ></path>
          <path
            d="M8.679 8.028H4.114a.8.8 0 110-1.6h4.565a.8.8 0 110 1.6"
            data-name="Path 452"
            transform="translate(.277 1.536)"
          ></path>
        </g>
      </g>
      <path fill="none" d="M0 0H28V28H0z" data-name="Rectangle 569"></path>
    </svg>
  );
}

export default IconLiveChatMenuLarge;
