import { useMemo } from "react";

export default function useTimeZone() {
  const timeZone = useMemo(
    () => Intl.DateTimeFormat().resolvedOptions().timeZone,
    []
  );

  return timeZone;
}
