import { FormListItem, SkillListItem, TeamListItem } from "@/types";
import React from "react";

interface Props {
  skill?: SkillListItem;
  team?: TeamListItem;
  form?: FormListItem;
  notSelectable: boolean;
  selected?: boolean;
  setSkillInfo?(skill: SkillListItem): void;
  setTeamInfo?(team: TeamListItem): void;
  setFormInfo?(form: FormListItem): void;
  setPreview?(form: FormListItem | null): void;
}

export default function SkillItem(props: Props) {
  function activateSkillInfo() {
    if (props.skill != null && props.setSkillInfo)
      props.setSkillInfo(props.skill);
  }
  function activateTeamInfo() {
    if (props.team != null && props.setTeamInfo) props.setTeamInfo(props.team);
  }

  function activateFormInfo() {
    if (props.form != null && props.setFormInfo) props.setFormInfo(props.form);
  }

  function previewForm(form: FormListItem | null | undefined) {
    if (form != null && props.setPreview) {
      if (form != undefined && props.setPreview) {
        props.setPreview(form);
      }
    } else {
      if (props.setPreview) {
        props.setPreview(null);
      }
    }
  }

  return (
    <>
      {!props.notSelectable && props.skill && (
        <button
          className={
            props.selected
              ? "hover:bg-cornflower-blue-100 rounded-md bg-cornflower-blue-100"
              : "hover:bg-cornflower-blue-50 rounded-md"
          }
          onClick={() => activateSkillInfo()}
        >
          <div className="flex flex-row w-[352px] pb-2 py-2">
            <div className="flex pr-4"></div>
            <div className="flex w-[280px]">{props.skill.name}</div>
          </div>
        </button>
      )}
      {props.notSelectable && props.skill && (
        <div className="rounded-md">
          <div className="flex flex-row w-[352px] pb-2 py-4">
            <div className="flex w-[280px] text-sm leading-[21px] font-semibold items-center">
              {props.skill.name}
            </div>
          </div>
        </div>
      )}
      {!props.notSelectable && props.team && (
        <button
          className={
            props.selected
              ? "hover:bg-cornflower-blue-100 rounded-md bg-cornflower-blue-100"
              : "hover:bg-cornflower-blue-50 rounded-md"
          }
          onClick={() => activateTeamInfo()}
        >
          <div className="flex flex-row w-[352px] pb-2 py-2">
            <div className="flex pr-4"></div>
            <div className="flex w-[280px]">{props.team.name}</div>
          </div>
        </button>
      )}
      {props.notSelectable && props.team && (
        <div className="rounded-md">
          <div className="flex flex-row w-[352px] pb-2 py-4">
            <div className="flex w-[280px] text-sm leading-[21px] font-semibold items-center">
              {props.team.name}
            </div>
          </div>
        </div>
      )}
      {!props.notSelectable && props.form && (
        <button
          className={
            props.selected
              ? "hover:bg-cornflower-blue-100 rounded-md bg-cornflower-blue-100"
              : "hover:bg-cornflower-blue-50 rounded-md"
          }
          onClick={() => activateFormInfo()}
          onMouseEnter={() => previewForm(props.form)}
          onMouseLeave={() => previewForm(null)}
        >
          <div className="flex flex-row w-[352px] pb-2 py-2">
            <div className="flex pr-4"></div>
            <div className="flex w-[280px]">{props.form.name}</div>
          </div>
        </button>
      )}
      {props.notSelectable && props.form && (
        <div className="rounded-md">
          <div className="flex flex-row w-[352px] pb-2 py-4">
            <div className="flex w-[280px] text-sm leading-[21px] font-semibold items-center">
              {props.form.name}
            </div>
          </div>
        </div>
      )}
    </>
  );
}
