import React from "react";

interface Props {
  icon: string;
  value: string;
  onClick: () => void;
  placeholder?: string;
}

export default function Field({ icon, value, onClick, placeholder }: Props) {
  return (
    <div onClick={onClick} className="flex text-sm my-2">
      <div className="text-slate-gray-400 w-6">
        <i className={icon}></i>
      </div>
      {value && (
        <div className="text-slate-gray-600 dark:text-white">{value}</div>
      )}
      {!value && (
        <div className="text-slate-gray-400 dark:text-white">{placeholder}</div>
      )}
    </div>
  );
}
