import React from "react";
import { MessageGroupItemType } from "@/reducers/messageGroupReducer";
import AutoResponseGroup from "./AutoResponseGroup";
import ContactMessageGroup from "./ContactMessageGroup";
import DaySeparator from "./DaySeparator";
import UserMessageGroup from "./UserMessageGroup";
import { ConversationSource } from "@/types";
import BotMessageGroup from "./BotMessageGroup";
import SystemMessageGroup from "./SystemMessageGroup";
import StandardMessageGroup from "./StandardMessageGroup";
import LinkMessageGroup from "./LinkMessageGroup";

interface Props {
  contactId: number;
  item: MessageGroupItemType;
  onImageLoad(): void;
  conversationSource: ConversationSource;
}

export default function MessageGroupItem(props: Props) {
  switch (props.item.type) {
    case "AUTO_RESPONSE_MESSAGE_GROUP":
      return <AutoResponseGroup item={props.item} />;
    case "CONTACT_MESSAGE_GROUP":
      return (
        <StandardMessageGroup
          item={props.item}
          conversationSource={props.conversationSource}
          onImageLoad={props.onImageLoad}
        />
      );
    case "BOT_MESSAGE_GROUP":
      return (
        <BotMessageGroup
          item={props.item}
          conversationSource={props.conversationSource}
          onImageLoad={props.onImageLoad}
        />
      );
    case "DAY_SEPARATOR":
      return <DaySeparator item={props.item} />;
    case "USER_MESSAGE_GROUP":
      return (
        <StandardMessageGroup
          item={props.item}
          conversationSource={props.conversationSource}
          onImageLoad={props.onImageLoad}
        />
      );
    case "SYSTEM_MESSAGE_GROUP":
      return <SystemMessageGroup item={props.item} />;
    case "LINK_MESSAGE_GROUP":
      return <LinkMessageGroup item={props.item} />;
    default:
      return <div>{props.item} not implemented</div>;
  }
}
