import React from "react";
import { Conversation } from "@/types";
import ResolveConversationModal from "./ResolveConversationModal";
import useTranslations from "@/hooks/useTranslations";
import ChatAssignmentModal from "./ChatAssignmentModal";
import IconPersonAdd from "@/icons/IconPersonAdd";
import IconFormstackLogo from "@/icons/IconFormstackLogo";
import FormstackModal from "./FormstackModal";

interface Props {
  conversation: Conversation;
}

export default function FormstackButton(props: Props) {
  const [openAssignmentModal, setOpenFormstackModal] = React.useState(false);
  const translation = useTranslations();

  return (
    <>
      <div>
        <button
          className="h-6 w-14 pr-6 pl-1 pt-1.5"
          onClick={() => setOpenFormstackModal(true)}
        >
          <div className="flex flex-col items-center">
            <IconFormstackLogo className="h-6" />
            <div className="text-xs"></div>
          </div>
        </button>
      </div>
      {openAssignmentModal && (
        <FormstackModal
          conversation={props.conversation}
          onClose={() => setOpenFormstackModal(false)}
        />
      )}
    </>
  );
}
