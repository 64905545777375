import React from "react";
import PastConversationMessages from "./PastConversationMessages";
import ConversationPanelHeader from "./ConversationPanelHeader";
import FormattedTime from "./FormattedTime";
import useApi from "@/hooks/useApi";
import { Conversation } from "@/types";
import useToastAlert from "@/hooks/useToastAlert";
import { Severity } from "@velaro/velaro-shared/src/components/ToastAlert";
import usePastMessages from "@/hooks/usePastMessages";
import ChatUpdateMessageGroup from "./ChatUpdateMessageGroup";
import IconGreenCheckMarkCircle from "@velaro/velaro-shared/src/icons/IconGreenCheckMarkCircle";
import { UpdateStatus } from "./ChatUpdateMessageGroup";
import EndConversationSeparator from "./EndConversationSeparator";
import { useParams } from "react-router-dom";

interface Props {
  conversation: Conversation;
  expanded?: boolean;
  updatePastConvo(pastConvo: Partial<Conversation>, convoId: number): void;
}

export default function PastConversation(props: Props) {
  const [expanded, setExpanded] = React.useState(props.expanded || false);
  const api = useApi();
  const toastAlert = useToastAlert();
  const { messages } = usePastMessages(props.conversation.id);
  const { conversationBaseId } = useParams<{ conversationBaseId: string }>();

  const setTopic = React.useCallback(
    (topic: string, convoId: number) => {
      async function handleSubmit() {
        const resp = await api.messaging.put(`Conversations/${convoId}`, {
          topic
        });

        if (resp.ok) {
          props.updatePastConvo({ topic: topic }, props.conversation.id);
        } else {
          toastAlert.displayToast(
            Severity.Error,
            "An unexpected error occurred"
          );
        }
      }
      handleSubmit();
    },
    [api.messaging, props, toastAlert]
  );

  React.useEffect(() => {
    if (conversationBaseId != props.conversation.id.toString()) {
      setExpanded(false);
    } else {
      setExpanded(true);
    }
  }, [conversationBaseId, props.conversation.id]);

  return (
    <div className="bg-white rounded-lg border border-slate-gray-50 flex-col items-start gap-2 w-full">
      <ConversationPanelHeader
        conversation={props.conversation}
        expanded={expanded}
        onClick={() => setExpanded((e) => !e)}
        setTopic={(topic) => setTopic(topic, props.conversation.id)}
        messageCount={messages.filter((x) => x.type !== "system").length}
      />

      {expanded && (
        <div className=" px-4">
          <PastConversationMessages
            conversationBaseId={props.conversation.id}
            conversationSource={props.conversation.source}
            contactId={props.conversation.contact.id}
          />

          {props.conversation.status == "Resolved" &&
            props.conversation.resolvedReason == "Agent-Closed" && (
              <>
                <EndConversationSeparator />
                <div className=" flex items-center justify-center text-slate-gray-500 text-xs">
                  <ChatUpdateMessageGroup
                    conversation={props.conversation}
                    status={UpdateStatus.Resolved}
                  />
                </div>
              </>
            )}
          {props.conversation.status == "Resolved" &&
            props.conversation.resolvedReason == "Auto-Closed" && (
              <>
                <EndConversationSeparator />
                <div className=" flex items-center justify-center text-slate-gray-500 text-xs">
                  <ChatUpdateMessageGroup
                    conversation={props.conversation}
                    status={UpdateStatus.AutoClosed}
                  />
                </div>
              </>
            )}

          {props.conversation.status == "Resolved" &&
            props.conversation.resolvedBy == null &&
            props.conversation.resolvedReason == null && (
              <>
                <EndConversationSeparator />
                <div className=" flex items-center justify-center text-slate-gray-500 text-xs">
                  <ChatUpdateMessageGroup
                    conversation={props.conversation}
                    status={UpdateStatus.Resolved}
                  />
                </div>
              </>
            )}
        </div>
      )}
    </div>
  );
}
