import React from "react";
import { IconProps } from "./types";

function IconExternalLink(props: IconProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      {...props}
    >
      <path fill="none" d="M0 0H24V24H0z" data-name="Rectangle 425"></path>
      <g
        fill="currentColor"
        data-name="Group 98"
        transform="translate(3.429 4)"
      >
        <path
          d="M19.19 10.619a.952.952 0 00-.952.952v5.714a.952.952 0 01-.952.952H5.857a.952.952 0 01-.952-.952V5.857a.952.952 0 01.952-.957h5.714a.952.952 0 100-1.9H5.857A2.857 2.857 0 003 5.857v11.429a2.857 2.857 0 002.857 2.857h11.429a2.857 2.857 0 002.857-2.857v-5.715a.952.952 0 00-.953-.952z"
          data-name="Path 278"
          transform="translate(-3 -3)"
        ></path>
        <path
          d="M15.762 4.9h1.5l-5.99 5.981a.956.956 0 101.352 1.352l5.99-5.981v1.5a.952.952 0 101.9 0v-3.8A.952.952 0 0019.571 3h-3.81a.952.952 0 000 1.9z"
          data-name="Path 279"
          transform="translate(-3.381 -3)"
        ></path>
      </g>
    </svg>
  );
}

export default IconExternalLink;
