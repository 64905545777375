import { SoundModel, SoundType } from "@/types";
import useProfile from "./useProfile";

const sounds: SoundModel[] = [
  { label: "Double Chime", fileName: "default.mp3" },
  { label: "Magic Coin", fileName: "new_chat_1.mp3" },
  { label: "Alien", fileName: "new_message_1.mp3" },
  { label: "Da-Ding", fileName: "new_message_2.mp3" },
  { label: "Bop-Up", fileName: "new_message_3.mp3" }
];

export default function useSounds() {
  const { profile } = useProfile();

  const playSound = (soundType: SoundType) => {
    if (profile.soundEnabled) {
      let sound: HTMLAudioElement | null = null;
      const audioUrl = profile.soundUrl + "/" + profile[soundType + "Sound"];
      sound = new Audio(audioUrl);

      if (sound) {
        sound.play();
      } else {
        console.error("Missing audio file.", audioUrl);
      }
    } else {
      console.warn("Sounds are not enabled.");
    }
  };

  return { playSound, sounds };
}
