import IconAttachment from "@/icons/IconAttachment";
import React from "react";

interface Props {
  onAddAttachments(files: File[]): void;
}

export default function UploadButton(props: Props) {
  const fileInputRef = React.useRef<HTMLInputElement>(null);

  return (
    <>
      <input
        ref={fileInputRef}
        type="file"
        multiple
        style={{ display: "none" }}
        onChange={(e) => {
          const fileList = e.currentTarget.files;

          if (!fileList) {
            return;
          }

          const files: File[] = [];

          for (let i = 0; i < fileList.length; i++) {
            files.push(fileList[i]);
          }

          props.onAddAttachments(files);
        }}
      />
      <button
        className="h-8 w-8 flex items-center justify-center"
        onClick={() => {
          fileInputRef.current?.click();
        }}
      >
        <IconAttachment />
      </button>
    </>
  );
}
