import React, { useCallback, useMemo, useState } from "react";

import {
  HubConnectionBuilder,
  HubConnectionState,
  LogLevel
} from "@microsoft/signalr";
import ConnectionContext from "../context/ConnectionContext";
import AuthService from "../services/AuthService";
import eventMap from "@/hub/eventMap";
import hubEmitter from "@/hub/emitter";
import { EventEmitter } from "eventemitter3";
import { AppEvent } from "@/types";

export default function ConnectionProvider(props: {
  children: React.ReactNode;
}) {
  const [connectionState, setConnectionState] = useState<HubConnectionState>(
    HubConnectionState.Disconnected
  );

  const eventEmitter = useMemo(() => new EventEmitter(), []);

  const connection = useMemo(
    () =>
      new HubConnectionBuilder()
        .withUrl(`${import.meta.env.VITE_API_URL}/ClientHub`, {
          accessTokenFactory: () => AuthService.token.token
        })
        .withAutomaticReconnect({
          nextRetryDelayInMilliseconds: (retryContext) => {
            return Math.random() * 10000; // attempt to reconnect every 10 seconds
          }
        })
        .configureLogging(LogLevel.Debug)
        .build(),
    []
  );

  // register new events in eventMap.ts

  React.useEffect(() => {
    Object.keys(eventMap).forEach((event) => {
      connection.on(event, (...args) => {
        hubEmitter.emit(event, ...args);
      });
    });

    return () => {
      Object.keys(eventMap).forEach((event) => {
        hubEmitter.removeAllListeners(event);
        connection.off(event);
      });
    };
  }, [connection]);

  const startConnection = React.useCallback(async () => {
    try {
      if (connection.state !== HubConnectionState.Disconnected) {
        return;
      }
      await connection.start();
      eventEmitter.emit("connected");
      setConnectionState(HubConnectionState.Connected);
    } catch (err) {
      console.log(err);
      console.log("signalr connection failed retrying in 10 seconds");
      setTimeout(startConnection, 10000);
    }
  }, [connection, eventEmitter]);

  React.useEffect(() => {
    startConnection();
  }, [startConnection]);

  // handle reconnect events

  React.useEffect(() => {
    connection.onreconnecting((error) => {
      console.assert(connection.state === HubConnectionState.Reconnecting);
      console.log("reconnecting", error);
      setConnectionState(HubConnectionState.Reconnecting);
      // document.getElementById("messageInput").disabled = true;
      // const li = document.createElement("li");
      // li.textContent = `Connection lost due to error "${error}". Reconnecting.`;
      // document.getElementById("messagesList").appendChild(li);
    });

    connection.onreconnected((connectionId) => {
      console.assert(connection.state === HubConnectionState.Connected);
      console.log("reconnected", connectionId);
      setConnectionState(HubConnectionState.Connected);
      eventEmitter.emit("reconnected");

      // document.getElementById("messageInput").disabled = false;
      // const li = document.createElement("li");
      // li.textContent = `Connection reestablished. Connected with connectionId "${connectionId}".`;
      // document.getElementById("messagesList").appendChild(li);
    });

    connection.onclose((error) => {
      console.assert(connection.state === HubConnectionState.Disconnected);
      console.log("closed", error);
      setConnectionState(HubConnectionState.Disconnected);

      // document.getElementById("messageInput").disabled = true;
      // const li = document.createElement("li");
      // li.textContent = `Connection closed due to error "${error}". Try refreshing this page to restart the connection.`;
      // document.getElementById("messagesList").appendChild(li);
    });
  }, [connection, eventEmitter]);

  (window as any).testDisconnect = async () => {
    console.log("test disconnect");
    await connection.stop();
    setConnectionState(HubConnectionState.Disconnected);
  };

  (window as any).testReconnect = async () => {
    console.log("test reconnect");
    await startConnection();
  };

  const on = useCallback(
    (event: AppEvent, callback: () => void) => {
      eventEmitter.on(event, callback);
    },
    [eventEmitter]
  );

  const off = useCallback(
    (event: AppEvent, callback: () => void) => {
      eventEmitter.off(event, callback);
    },
    [eventEmitter]
  );

  return (
    <ConnectionContext.Provider
      value={{ connection, connectionState, on, off }}
    >
      {props.children}
    </ConnectionContext.Provider>
  );
}
