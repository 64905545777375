import React from "react";
import { IconProps } from "./types";

function IconSnooze1(props: IconProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      {...props}
    >
      <g
        fill="currentColor"
        data-name="Group 1006"
        transform="translate(1 .666)"
      >
        <path
          d="M9.988 20A9.987 9.987 0 010 10.5h2a7.992 7.992 0 106.487-8.359V.112A10.112 10.112 0 019.988 0a10 10 0 010 20z"
          data-name="Subtraction 3"
          transform="translate(2.013 2)"
        ></path>
        <path
          d="M16 11h-3V8a1 1 0 00-2 0v4a1 1 0 001 1h4a1 1 0 000-2z"
          data-name="Path 307"
        ></path>
        <path
          d="M1.166.306a.416.416 0 01-.307-.12.416.416 0 01-.121-.307v-.651a.654.654 0 01.084-.307A2.539 2.539 0 011-1.367l5.129-6.524h-4.8a.416.416 0 01-.307-.121.416.416 0 01-.121-.307v-.613a.416.416 0 01.121-.307.416.416 0 01.307-.121h6.622a.416.416 0 01.307.121.416.416 0 01.121.307v.688a.6.6 0 01-.084.3 3.062 3.062 0 01-.177.279L3.043-1.162h5.279a.427.427 0 01.316.112.427.427 0 01.112.316v.613a.416.416 0 01-.121.307.416.416 0 01-.307.121z"
          data-name="Path 310"
          transform="translate(-.738 9.694)"
        ></path>
      </g>
    </svg>
  );
}

export default IconSnooze1;
