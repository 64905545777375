import React from "react";
import { IconProps } from "./types";

function IconReassignmentArrow(props: IconProps) {
  return (
    <svg
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.33789 6.00021C4.06694 3.01108 7.29866 1 11.0001 1C16.5229 1 21.0001 5.47734 21.0001 11.0004C21.0001 16.5235 16.5229 21.0008 11.0001 21.0008C7.29866 21.0008 4.06694 18.9898 2.33789 16.0006M11 15.0006L15 11.0004M15 11.0004L11 7.00025M15 11.0004H1"
        stroke="#1052A9"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default IconReassignmentArrow;
