import React from "react";
import { IconProps } from "./types";

function IconGeneralSettings(props: IconProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="currentColor"
      {...props}
    >
      <defs>
        <clipPath id="a">
          <rect width="24" height="24" fill="#353e44" />
        </clipPath>
      </defs>
      <g clipPath="url(#a)">
        <path d="M20.8,4.076H3.2a.88.88,0,0,0-.881.88V19.044a.88.88,0,0,0,.881.88H20.8a.88.88,0,0,0,.881-.88V4.956a.88.88,0,0,0-.881-.88m-.88,14.087H4.076V5.837H19.924Z" />
        <path d="M5.837,6.717a.88.88,0,1,1-.88.88.88.88,0,0,1,.88-.88" />
        <path d="M8.478,6.717a.88.88,0,1,1-.88.88.88.88,0,0,1,.88-.88" />
        <path d="M11.12,6.717a.88.88,0,1,1-.88.88.88.88,0,0,1,.88-.88" />
        <path d="M15.478,13.893a2.558,2.558,0,0,0,.044-.572,2.562,2.562,0,0,0-.044-.573l-.969-.22a1.8,1.8,0,0,0-.176-.44l.528-.836a3.369,3.369,0,0,0-.792-.793l-.836.528-.441-.176-.22-.968H11.428l-.22.968-.441.176-.836-.528a3.369,3.369,0,0,0-.792.793l.528.836a1.8,1.8,0,0,0-.176.44l-.969.22a2.562,2.562,0,0,0-.044.573,2.558,2.558,0,0,0,.044.572l.969.22a1.784,1.784,0,0,0,.176.44l-.528.837a3.384,3.384,0,0,0,.792.792l.836-.528.441.176.22.968h1.144l.22-.968.441-.176.836.528a3.384,3.384,0,0,0,.792-.792l-.528-.837a1.784,1.784,0,0,0,.176-.44ZM12,14.2a.881.881,0,1,1,.88-.88.88.88,0,0,1-.88.88" />
      </g>
    </svg>
  );
}

export default IconGeneralSettings;
