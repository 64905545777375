import React from "react";

interface Props {
  children: string | number;
}

export default function TData({ children }: Props) {
  return (
    <td className="border-b dark:border-slate-gray-600 font-medium p-4 pl-8 text-slate-gray-800 dark:text-slate-gray-200 text-left">
      {children}
    </td>
  );
}
