import useApi from "@/hooks/useApi";
import useTimeZone from "@/hooks/useTimeZone";
import formatDistanceStrict from "date-fns/formatDistanceStrict";
import React, { useEffect, useState } from "react";

import {
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  Area,
  AreaChart,
  Label
} from "recharts";

interface Props {
  dateRange: string;
}

export default function AverageWaitTimeChart(props: Props) {
  const api = useApi();
  const timeZone = useTimeZone();
  const [data, setData] = useState([]);

  useEffect(() => {
    async function fetchData() {
      const resp = await api.messaging.post("Dashboard/Chart", {
        dateRange: props.dateRange,
        timeZone
      });
      setData(await resp.json());
    }

    fetchData();
  }, [api.messaging, props.dateRange, timeZone]);

  return (
    <ResponsiveContainer width="100%" height={300}>
      <AreaChart data={data}>
        <XAxis dataKey="name" hide />
        <YAxis tickFormatter={formatTime} hide />
        <Tooltip formatter={formatTime} />
        <Area
          type="monotone"
          dataKey="avg"
          stroke="red"
          fill="red"
          name="Average"
        />
        <Area
          type="monotone"
          dataKey="max"
          stroke="blue"
          fill="blue"
          name="Max"
        />
      </AreaChart>
    </ResponsiveContainer>
  );
}

function formatTime(value: number) {
  if (!value || value < 0) {
    return "N/A";
  }

  return formatDistanceStrict(0, value);
}
