import { CannedResponse } from "@/types";
import React from "react";
import useApi from "./useApi";

export default function useCannedResponses() {
  const [cannedResponses, setCannedResponses] = React.useState<
    CannedResponse[]
  >([]);

  const api = useApi();

  React.useEffect(() => {
    async function fetchCannedResponses() {
      const response = await api.messaging.get("CannedResponses/List");
      setCannedResponses(await response.json());
    }

    fetchCannedResponses();
  }, [api.messaging]);

  return { cannedResponses };
}
