import React from "react";
import { IconProps } from "./types";

function IconSettingsGear(props: IconProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="28"
      height="28"
      viewBox="0 0 28 28"
      {...props}
    >
      <g
        fill="currentColor"
        data-name="Group 1009"
        transform="translate(-359 -407.057)"
      >
        <path
          d="M23.7 9.813L22.528 9.8a10.06 10.06 0 00-1.768-3.17l.6-1a2.335 2.335 0 00-.81-3.195l-1.81-1.078a2.336 2.336 0 00-3.195.81l-.6 1.011a10.146 10.146 0 00-3.622-.049L10.748 2.1a2.331 2.331 0 00-3.172-.9L5.738 2.231a2.331 2.331 0 00-.9 3.173l.571 1.019A10.064 10.064 0 003.56 9.541l-1.173-.016a2.329 2.329 0 00-2.362 2.3l-.012.925-.013.926a2.33 2.33 0 002.3 2.361l1.172.016A10.091 10.091 0 005.24 19.22l-.6 1a2.33 2.33 0 00.81 3.195L7.261 24.5a2.335 2.335 0 003.195-.81l.6-1.01a10.14 10.14 0 003.62.049l.574 1.026a2.333 2.333 0 003.172.9l1.837-1.028a2.333 2.333 0 00.9-3.172l-.571-1.02a10.073 10.073 0 001.852-3.118l1.172.016a2.341 2.341 0 002.362-2.3l.013-.925.013-.933a2.335 2.335 0 00-2.3-2.362M23.974 14a.328.328 0 01-.1.233.388.388 0 01-.235.093l-1.906-.026h-.014a1 1 0 00-.961.726 8.056 8.056 0 01-2.1 3.536 1 1 0 00-.172 1.2l.928 1.66a.329.329 0 01-.127.45L17.448 22.9a.333.333 0 01-.448-.126l-.932-1.665a1 1 0 00-1.113-.482 8.13 8.13 0 01-4.113-.055 1 1 0 00-1.127.452l-.976 1.639a.329.329 0 01-.453.115L6.474 21.7a.333.333 0 01-.115-.454l.974-1.634a1 1 0 00-.141-1.206 8.084 8.084 0 01-2.005-3.592 1 1 0 00-.954-.752l-1.907-.026a.325.325 0 01-.232-.1A.331.331 0 012 13.7l.013-.925.012-.924a.334.334 0 01.335-.327l1.907.026a.981.981 0 00.975-.725 8.063 8.063 0 012.1-3.537 1 1 0 00.172-1.2l-.927-1.66a.327.327 0 01-.029-.251.323.323 0 01.157-.2l1.837-1.029a.327.327 0 01.248-.029.334.334 0 01.2.157l.931 1.665a1 1 0 001.114.482 8.118 8.118 0 014.113.056 1 1 0 001.127-.453l.975-1.639a.331.331 0 01.454-.115l1.809 1.078a.33.33 0 01.115.453l-.973 1.634a1 1 0 00.14 1.207 8.066 8.066 0 012.005 3.592 1 1 0 00.955.751l1.9.026a.331.331 0 01.327.335l-.012.925z"
          data-name="Path 496"
          transform="translate(360 408.151)"
        ></path>
        <path
          d="M12.575 6.131a6.359 6.359 0 00-.175 12.717h.089a6.359 6.359 0 00.082-12.717m2.955 9.482a4.225 4.225 0 01-3.1 1.235 4.359 4.359 0 01.058-8.719h.058a4.359 4.359 0 012.982 7.482"
          data-name="Path 497"
          transform="translate(360.511 408.586)"
        ></path>
      </g>
    </svg>
  );
}

export default IconSettingsGear;
