import React from "react";
import { IconProps } from "./types";

function IconContacts(props: IconProps) {
  return (
    <svg
      width="24"
      height="19"
      viewBox="0 0 24 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="Group 9657">
        <path
          id="Vector (Stroke)"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M1.00421 1.06964C1.64719 0.426654 2.51926 0.0654297 3.42857 0.0654297H20.5714C21.4807 0.0654297 22.3528 0.426654 22.9958 1.06964C23.6388 1.71262 24 2.58469 24 3.494V15.494C24 16.4033 23.6388 17.2754 22.9958 17.9184C22.3528 18.5613 21.4807 18.9226 20.5714 18.9226H3.42857C2.51926 18.9226 1.64719 18.5613 1.00421 17.9184C0.361224 17.2754 0 16.4033 0 15.494V3.494C0 2.58469 0.361224 1.71262 1.00421 1.06964ZM3.42857 1.77972C2.97391 1.77972 2.53788 1.96033 2.21639 2.28182C1.8949 2.60331 1.71429 3.03934 1.71429 3.494V15.494C1.71429 15.9487 1.8949 16.3847 2.21639 16.7062C2.53788 17.0277 2.97391 17.2083 3.42857 17.2083H20.5714C21.0261 17.2083 21.4621 17.0277 21.7836 16.7062C22.1051 16.3847 22.2857 15.9487 22.2857 15.494V3.494C22.2857 3.03934 22.1051 2.60331 21.7836 2.28182C21.4621 1.96033 21.0261 1.77972 20.5714 1.77972H3.42857ZM12.8571 6.06543C12.8571 5.59204 13.2409 5.20829 13.7143 5.20829H19.7143C20.1877 5.20829 20.5714 5.59204 20.5714 6.06543C20.5714 6.53882 20.1877 6.92257 19.7143 6.92257H13.7143C13.2409 6.92257 12.8571 6.53882 12.8571 6.06543ZM12.8571 9.494C12.8571 9.02061 13.2409 8.63686 13.7143 8.63686H19.7143C20.1877 8.63686 20.5714 9.02061 20.5714 9.494C20.5714 9.96739 20.1877 10.3511 19.7143 10.3511H13.7143C13.2409 10.3511 12.8571 9.96739 12.8571 9.494ZM12.8571 12.9226C12.8571 12.4492 13.2409 12.0654 13.7143 12.0654H19.7143C20.1877 12.0654 20.5714 12.4492 20.5714 12.9226C20.5714 13.396 20.1877 13.7797 19.7143 13.7797H13.7143C13.2409 13.7797 12.8571 13.396 12.8571 12.9226Z"
          fill="white"
        />
        <g id="Group 9654">
          <path
            id="Icon (Stroke)"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M4.97155 12.8934H9.2481C8.7062 12.1877 7.93664 11.7727 7.10982 11.7727C6.28301 11.7727 5.51344 12.1877 4.97155 12.8934ZM3.74399 12.3494C4.49412 11.2231 5.70782 10.457 7.10982 10.457C8.51183 10.457 9.72553 11.2231 10.4757 12.3494C10.4796 12.3554 10.4837 12.3615 10.4879 12.3677C10.5477 12.4574 10.6231 12.5705 10.6773 12.681C10.7467 12.8224 10.8021 12.9973 10.7894 13.2089C10.7792 13.3784 10.7238 13.5297 10.6618 13.6455C10.5998 13.7613 10.5046 13.8912 10.3691 13.9936C10.1862 14.132 9.99275 14.1767 9.84115 14.1943C9.71385 14.2091 9.56719 14.209 9.43866 14.209C9.43079 14.209 9.42299 14.209 9.41526 14.209H4.80439C4.79666 14.209 4.78886 14.209 4.78099 14.209C4.65246 14.209 4.50579 14.2091 4.37849 14.1943C4.22689 14.1767 4.03346 14.132 3.85052 13.9936C3.71505 13.8912 3.61987 13.7613 3.55786 13.6455C3.49585 13.5297 3.44047 13.3784 3.43029 13.2089C3.41759 12.9973 3.47299 12.8224 3.54231 12.681C3.5965 12.5705 3.67192 12.4574 3.7318 12.3677C3.73594 12.3615 3.74001 12.3554 3.74399 12.3494Z"
            fill="white"
          />
          <path
            id="Icon (Stroke)_2"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M7.08296 6.09492C6.4371 6.09492 5.91352 6.61849 5.91352 7.26436C5.91352 7.91022 6.4371 8.4338 7.08296 8.4338C7.72882 8.4338 8.2524 7.91022 8.2524 7.26436C8.2524 6.61849 7.72882 6.09492 7.08296 6.09492ZM4.5979 7.26436C4.5979 5.8919 5.7105 4.7793 7.08296 4.7793C8.45542 4.7793 9.56802 5.8919 9.56802 7.26436C9.56802 8.63682 8.45542 9.74941 7.08296 9.74941C5.7105 9.74941 4.5979 8.63682 4.5979 7.26436Z"
            fill="white"
          />
        </g>
      </g>
    </svg>
  );
}

export default IconContacts;
