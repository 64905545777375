import React from "react";
import { IconProps } from "./types";

function IconIndicatorOffline(props: IconProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      {...props}
    >
      <path
        fill="currentColor"
        d="M4027 1098a8 8 0 118-8 8.009 8.009 0 01-8 8zm0-6.429l3.4 3.4a.694.694 0 00.984 0 .7.7 0 000-.983l-3.4-3.4 3.4-3.4a.7.7 0 00-.983-.983l-3.4 3.4-3.4-3.4a.693.693 0 00-.983 0 .7.7 0 000 .983l3.4 3.4-3.4 3.4a.7.7 0 10.983.983l3.4-3.4z"
        data-name="Subtraction 9"
        transform="translate(-4019 -1082)"
      ></path>
    </svg>
  );
}

export default IconIndicatorOffline;
