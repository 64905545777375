import useInbox from "@/hooks/useInbox";
import IconBarsFilter from "@/icons/IconBarsFilter";
import { InboxGroupBy, InboxSortBy, InboxFilters } from "@/types";
import React, { useState, useEffect } from "react";
import IconCloseCircle from "@/icons/IconCloseCircle";
import { inboxGroupOptions, inboxSortOptions } from "@/constants";
import useTranslations from "@/hooks/useTranslations";
import FilterModal from "./FilterModal";

export default function ConversationFilter() {
  const [showFilterModal, setShowFilterModal] = useState(false);
  const { setSettings, settings } = useInbox();
  const translation = useTranslations();

  // function getStatusLabel(value: string) {
  //   const opt = inboxFilterOptions.find((opt) => opt.value === value);
  //   return opt?.label ?? value;
  // }

  // function getSortByLabel(value: string) {
  //   const opt = inboxSortOptions.find((opt) => opt.value === value);
  //   return opt?.label ?? value;
  // }

  // function getGroupByLabel(value: string) {
  //   const opt = inboxGroupOptions.find((opt) => opt.value === value);
  //   return opt?.label ?? value;
  // }

  return (
    <div className="text-sm w-full">
      <div className="flex flex-row">
        {/* <button
          className="flex font-semibold px-2 py-2 text-cornflower-blue-500 items-center justify-center"
          style={{ fontSize: "10px" }}
          onClick={() => {
            setSettings({
              ...settings,
              status: "all" as InboxStatus,
              sortBy: "newest" as InboxSortBy,
              groupBy: "none" as InboxGroupBy
              //selectedTab: "mine" as InboxTabType
            });
          }}
        >
          {translation.key("reset_filters")}
        </button> */}
        <button
          className="p-3 flex items-center ml-auto"
          onClick={(e) => {
            setShowFilterModal(!showFilterModal);
            e.stopPropagation();
          }}
        >
          <IconBarsFilter
            className="mr-2 dark:text-white"
            style={{ height: "16px", width: "16px" }}
          ></IconBarsFilter>
          {/* {translation.key("filter")} */}
        </button>
      </div>
      {/* <div className="flex flex-row right-0 pb-2">
        <div className="ml-auto flex flex-row font-semibold text-xs">
          {settings.status !== "all" && (
            <div className="px-1 py-[2px] bg-cornflower-blue-50 rounded-full flex flex-row items-center">
              {getStatusLabel(settings.status)}
              <div
                className="pl-1 cursor-pointer"
                onClick={() => {
                  setSettings({
                    ...settings,
                    status: "all" as InboxStatus,
                  });
                }}
              >
                <IconCloseCircle />
              </div>
            </div>
          )}
          {settings.sortBy !== "newest" && (
            <div className="px-1 py-[2px] bg-cornflower-blue-50 rounded-full flex flex-row items-center">
              {getSortByLabel(settings.sortBy)}
              <div
                className="pl-1 cursor-pointer"
                onClick={() => {
                  setSettings({
                    ...settings,
                    sortBy: "newest" as InboxSortBy,
                  });
                }}
              >
                <IconCloseCircle />
              </div>
            </div>
          )}
          {settings.groupBy !== "none" && (
            <div className="px-1 py-[2px] bg-cornflower-blue-50 rounded-full flex flex-row items-center">
              {getGroupByLabel(settings.groupBy)}
              <div
                className="pl-1 cursor-pointer"
                onClick={() => {
                  setSettings({
                    ...settings,
                    groupBy: "none" as InboxGroupBy,
                  });
                }}
              >
                <IconCloseCircle />
              </div>
            </div>
          )}
        </div>
      </div> */}
      <div>
        {showFilterModal && (
          <FilterModal onClose={() => setShowFilterModal(false)} />
        )}
      </div>
    </div>
  );
}
