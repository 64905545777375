import React from "react";
import { AutoResponseGroupType } from "@/reducers/messageGroupReducer";

interface Props {
  item: AutoResponseGroupType;
}

export default function AutoResponseGroup(props: Props) {
  return <div>AutoResponseGroup</div>;
}
