import React from "react";
import { AuthProvider } from "./providers/AuthProvider";
import ThemeProvider from "./providers/ThemeProvider";
import { AppRoutes } from "./routes/Routes";
import useWarmup from "./hooks/useWarmup";
import Warmup from "@velaro/velaro-shared/src/components/Warmup";
import i18n from "i18n";

function App() {
  const { warmedUp } = useWarmup();

  if (!warmedUp) {
    return <Warmup />;
  }

  return (
    <ThemeProvider>
      <AuthProvider>
        <AppRoutes />
      </AuthProvider>
    </ThemeProvider>
  );
}

export default App;
