import useInbox from "@/hooks/useInbox";
import { ConversationListItem } from "@/types";
import React from "react";
import ConversationItem from "./ConversationItem";

export default function GroupedConversations(props: {
  label: string;
  conversations: ConversationListItem[];
}) {
  const [expanded, setExpanded] = React.useState(true);

  let iconClassName =
    "transition fa-solid fa-arrow-left w-4 h-4 m-3 items-center justify-center rounded-full flex text-black border-[1px] border-black fa-rotate-270 dark:text-white dark:border-white";
  if (!expanded) {
    iconClassName =
      "transition fa-solid fa-arrow-left w-4 h-4 m-3 items-center justify-center rounded-full flex text-black border-[1px] border-black fa-rotate-180 dark:text-white dark:border-white";
  }

  return (
    <div>
      {props.conversations.length > 0 && (
        <div>
          <div
            className="flex bg-gray-100 dark:bg-gray-700"
            onClick={() => {
              setExpanded(!expanded);
            }}
          >
            <i className={iconClassName} style={{ fontSize: 12 }}></i>
            <div className="p-2 font-semibold flex">{props.label}</div>
          </div>

          {expanded && (
            <div>
              {props.conversations.map((conversationItem) => (
                <ConversationItem
                  key={conversationItem.id}
                  conversation={conversationItem}
                />
              ))}
            </div>
          )}
        </div>
      )}
    </div>
  );
}
