import * as React from "react";

interface Props {
  children: React.ReactNode;
  onImageLoad(): void;
}

/**
 * This component will find any child image elements and attach
 * a load event. This is necessary because upon receiving a message,
 * we may or may not scroll the message list to the bottom depending
 * on the previous scroll location. When an image is loaded
 * asynchronously, the message list will need to know about it.
 *
 * When we find an image element, we will initially set its max-height
 * to 0px. Once the image loads, we will unset the max-height
 * just before calling onImageLoad. We do this because we don't want the
 * image element to procedurally resize the image as it loads causing
 * the scrollbar to move up far enough that the message list believes
 * the user had previously been scrolled up.
 */

export default function ImageContainer(props: Props) {
  const ref = React.useRef<HTMLSpanElement>(null);

  React.useEffect(() => {
    if (!ref.current) {
      return;
    }

    function handleImageLoad(e: any) {
      e.target.style.maxHeight = "";
      props.onImageLoad();
    }

    const images = ref.current.getElementsByTagName("img");

    for (let i = 0; i < images.length; i++) {
      const image = images[i];

      if (!image.complete) {
        image.style.maxHeight = "0px";
        image.addEventListener("load", handleImageLoad);
      }
    }

    return () => {
      for (let i = 0; i < images.length; i++) {
        const image = images[i];
        image.removeEventListener("load", handleImageLoad);
      }
    };
  }, [props]);

  return <span ref={ref}>{props.children}</span>;
}
