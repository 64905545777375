import ToastAlert, {
  Severity
} from "@velaro/velaro-shared/src/components/ToastAlert";
import ToastAlertContext from "@/context/ToastAlertContext";
import { ToastAlertSettings } from "@/types";
import React, { useEffect, useState } from "react";
import { createRoot } from "react-dom/client";
import useApi from "@/hooks/useApi";

function closeAlert(id: string) {
  const uniqueToastContainer = document.getElementById(id);
  if (uniqueToastContainer === null) {
    return;
  }
  uniqueToastContainer.remove();
}

export default function ToastAlertProvider(props: {
  children: React.ReactNode;
}) {
  const [toastSettings, setToastSettings] = useState<ToastAlertSettings>({
    stickyAlerts: false,
    stickyDuration: 5000
  });

  const api = useApi();

  useEffect(() => {
    async function fetchSettings() {
      const resp = await api.messaging.get("NotificationSettings");
      setToastSettings(await resp.json());
    }

    fetchSettings();
  }, [api.messaging]);

  function updateToastSettings(updates: Partial<ToastAlertSettings>) {
    const newSettings = { ...toastSettings, ...updates };
    setToastSettings(newSettings);
    persistSettings(newSettings);
  }

  async function persistSettings(settings: ToastAlertSettings) {
    const resp = await api.messaging.post("NotificationSettings", settings);

    if (!resp.ok) {
      alert("an unexpected error occurred.");
    }
  }

  const sticky = toastSettings.stickyAlerts;
  const stickyDuration = toastSettings.stickyDuration;
  const alertsContainer = document.getElementById("notification-container");

  function displayToast(severity: Severity, message: string) {
    const r = (Math.random() + 1).toString(36).substring(7);
    const newElement = document.createElement("div");
    newElement.id = r;
    if (alertsContainer === null) {
      return;
    }
    alertsContainer.appendChild(newElement);
    const root = createRoot(newElement);
    root.render(
      <ToastAlert
        severity={severity}
        message={message}
        sticky={sticky}
        stickyDuration={stickyDuration}
        id={r}
        closeAlert={closeAlert}
      />
    );
  }

  return (
    <ToastAlertContext.Provider
      value={{ displayToast, toastSettings, updateToastSettings }}
    >
      {props.children}
    </ToastAlertContext.Provider>
  );
}
