import React from "react";
import { IconProps } from "./types";

function IconGroup(props: IconProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      {...props}
    >
      <g data-name="Group 1014" transform="translate(-816 -96)">
        <g fill="currentColor" transform="translate(816 96)">
          <path
            d="M22.764 7.412H20.93V5.578a.917.917 0 00-1.833 0v1.834h-1.836a.917.917 0 000 1.834H19.1v1.834a.917.917 0 101.833 0V9.247h1.835a.917.917 0 000-1.834"
            data-name="Path 498"
            transform="translate(.319 -4.662)"
          ></path>
          <path
            d="M12.489 12.675L10.818 11.3a.8.8 0 00-1.13.111.806.806 0 00.112 1.133l1.671 1.371A1.4 1.4 0 0111.984 15v5.324h1.606V15a3 3 0 00-1.1-2.326"
            data-name="Path 1805"
            transform="translate(-.941 1.275)"
          ></path>
          <path
            d="M3.9 11.413a.8.8 0 00-.542-.289.779.779 0 00-.587.179L1.1 12.675A3 3 0 000 15v5.324h1.607V15a1.4 1.4 0 01.513-1.086l1.671-1.373a.8.8 0 00.11-1.13"
            data-name="Path 1806"
            transform="translate(0 1.275)"
          ></path>
          <path
            d="M6.55 1.788a4.274 4.274 0 104.274 4.274A4.279 4.279 0 006.55 1.788m2.668 4.274A2.668 2.668 0 116.55 3.395a2.67 2.67 0 012.668 2.667"
            data-name="Path 1807"
            transform="translate(-.225 2.198)"
          ></path>
          <path
            d="M22.451 15.338l-1.671-1.373a.8.8 0 00-1.13.111.806.806 0 00.111 1.131l1.671 1.371a1.4 1.4 0 01.514 1.087v5.324h1.606v-5.324a3 3 0 00-1.1-2.326"
            data-name="Path 1808"
            transform="translate(-1.927 1.011)"
          ></path>
          <path
            d="M16.512 4.451a4.274 4.274 0 104.274 4.274 4.279 4.279 0 00-4.274-4.274m2.668 4.274a2.668 2.668 0 11-2.668-2.667 2.671 2.671 0 012.668 2.667"
            data-name="Path 1809"
            transform="translate(-1.211 1.934)"
          ></path>
        </g>
      </g>
    </svg>
  );
}

export default IconGroup;
