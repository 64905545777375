import React from "react";

/**
 * This hook handles determinig if a container is scrolled to
 * the bottom and provides a method to scroll to the bottom.
 */

export default function useBottomScroll(
  containerRef: React.RefObject<HTMLElement>
) {
  const [currentlyAtBottom, setCurrentlyAtBottom] = React.useState(true);
  // Handle scroll events, set wasAtBottom to true if they're close
  // enough to the bottom.

  React.useEffect(() => {
    const container = containerRef.current;

    function handleScroll() {
      if (!container) {
        return;
      }

      const containerHeight = container.scrollHeight - container.offsetHeight;
      const delta = containerHeight - container.scrollTop;

      // we'll leave a little buffer here so if they were near the bottom,
      // they'll still be scrolled down.

      setCurrentlyAtBottom(delta < 100);
    }

    container?.addEventListener("scroll", handleScroll);

    return () => {
      container?.removeEventListener("scroll", handleScroll);
    };
  }, [containerRef]);

  // Expose this method to allow a caller to scroll to the bottom of the container.

  const scrollToBottom = React.useCallback(() => {
    if (containerRef.current) {
      containerRef.current.scrollTop = containerRef.current.scrollHeight;
    }
  }, [containerRef]);

  // We want the container to stay scrolled to the bottom when the window is resized.

  React.useEffect(() => {
    function handleResize() {
      if (currentlyAtBottom) {
        scrollToBottom();
      }
    }

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [currentlyAtBottom, scrollToBottom]);

  return {
    currentlyAtBottom,
    scrollToBottom
  };
}
