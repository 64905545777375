import * as React from "react";
import { useCallback, useMemo } from "react";

interface Props {
  currentPage: number;
  setPage(page: number): void;
  totalRecords: number;
  pageSize: number;
}

export default function Pager({
  currentPage,
  setPage,
  totalRecords,
  pageSize
}: Props) {
  const pageCount = useMemo(() => Math.ceil(totalRecords / pageSize) || 1, [
    pageSize,
    totalRecords
  ]);

  const first = useCallback(() => {
    if (currentPage !== 1) {
      setPage(1);
    }
  }, [currentPage, setPage]);

  const previous = useCallback(() => {
    if (currentPage !== 1) {
      setPage(currentPage - 1);
    }
  }, [currentPage, setPage]);

  const next = useCallback(() => {
    if (currentPage !== pageCount) {
      setPage(currentPage + 1);
    }
  }, [currentPage, pageCount, setPage]);

  const last = useCallback(() => {
    if (currentPage !== pageCount) {
      setPage(pageCount);
    }
  }, [currentPage, pageCount, setPage]);

  if (totalRecords <= pageSize) {
    return null;
  }

  return (
    <div className="flex py-2">
      <button
        disabled={currentPage === 1}
        className="btn-link disabled:text-gray-300"
        onClick={first}
      >
        <i className="fa fa-angle-double-left" />
      </button>
      <button
        disabled={currentPage === 1}
        className="btn-link disabled:text-gray-300"
        onClick={previous}
      >
        <i className="fa fa-angle-left" />
      </button>
      <span className="btn-link">{`Page ${currentPage} of ${pageCount}`}</span>
      <button
        disabled={currentPage === pageCount}
        className="btn-link disabled:text-gray-300"
        onClick={next}
      >
        <i className="fa fa-angle-right" />
      </button>
      <button
        disabled={currentPage === pageCount}
        className="btn-link disabled:text-gray-300"
        onClick={last}
      >
        <i className="fa fa-angle-double-right" />
      </button>
    </div>
  );
}
