import React, { useMemo } from "react";
import format from "date-fns/format";

interface Props {
  text: string;
  user: string;
  date: string;
}

export default function NoteListItem(props: Props) {
  const formattedDate = useMemo(() => {
    return format(new Date(props.date), "MMM d yyyy, h:mm aaa");
  }, [props.date]);

  return (
    <div>
      <div
        className="dark:text-white [&_li]:list-disc [&_li]:ml-4"
        dangerouslySetInnerHTML={{ __html: props.text }}
      ></div>
      <div className="flex text-xs text-stone-400 dark:text-gray-400">
        <div className="font-semibold mr-3">{props.user}</div>
        <div>{formattedDate}</div>
      </div>
    </div>
  );
}
