import Modal from "@velaro/velaro-shared/src/components/Modal";
import { Contact } from "@/types";
import React from "react";
import FieldEditor from "./FieldEditor";
import useTranslations from "@/hooks/useTranslations";

interface Props {
  contact: Contact;
  onClose(): void;
  updateAttribute(attribute: string, value: string): void;
}

export default function AddressModal(props: Props) {
  const fieldClasses =
    "bg-cornflower-blue-50 w-full p-2 rounded-sm dark:bg-stone-600 dark:text-white";
  const translation = useTranslations();
  return (
    <Modal show={true}>
      <Modal.Header title="Edit Address" onClose={() => props.onClose()} />
      <Modal.Body>
        <div className="flex flex-col gap-4 m-2 text-stone-600 dark:text-white dark:border-stone-200">
          <FieldEditor
            className={fieldClasses}
            type="text"
            value={props.contact.address || ""}
            onUpdate={(value) => props.updateAttribute("address", value)}
            placeholder={translation.key("address")}
          />
          <FieldEditor
            className={fieldClasses}
            type="text"
            value={props.contact.city || ""}
            onUpdate={(value) => props.updateAttribute("city", value)}
            placeholder={translation.key("city")}
          />
          <FieldEditor
            className={fieldClasses}
            type="text"
            value={props.contact.state || ""}
            onUpdate={(value) => props.updateAttribute("state", value)}
            placeholder={translation.key("state")}
          />
          <FieldEditor
            className={fieldClasses}
            type="text"
            value={props.contact.country || ""}
            onUpdate={(value) => props.updateAttribute("country", value)}
            placeholder={translation.key("country")}
          />
        </div>
      </Modal.Body>
    </Modal>
  );
}
