import { Message } from "@/types";

type SetMessagesAction = {
  type: "SET_MESSAGES";
  messages: Message[];
};

type AddMessageAction = {
  type: "ADD_MESSAGE";
  message: Message;
};

type AppendMessagesAction = {
  type: "PREPEND_MESSAGES";
  messages: Message[];
};

type DeleteAttachmentAction = {
  type: "DELETE_ATTACHMENT";
  messageId: number;
  attachmentId: number;
};

export type MessageListAction =
  | SetMessagesAction
  | AppendMessagesAction
  | DeleteAttachmentAction
  | AddMessageAction;

export default function messageListReducer(
  state: Message[],
  action: MessageListAction
) {
  switch (action.type) {
    case "ADD_MESSAGE": {
      return [...state, action.message];
    }
    case "SET_MESSAGES": {
      return [...action.messages];
    }
    case "PREPEND_MESSAGES": {
      return [...action.messages, ...state];
    }
    case "DELETE_ATTACHMENT": {
      return state.map((message) => {
        if (message.id === action.messageId) {
          return {
            ...message,
            attachments: message.attachments.filter(
              (attachment) => attachment.id !== action.attachmentId
            )
          };
        }
        return message;
      });
    }
    default: {
      throw new Error(`Unsupported action type: ${(action as any).type}`);
    }
  }
}
