import React, { TextareaHTMLAttributes } from "react";

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface Props {}

export default function ResizableTextArea(
  props: Props & TextareaHTMLAttributes<HTMLTextAreaElement>
) {
  const { ...rest } = props;

  const ref = React.useRef<HTMLTextAreaElement>(null);

  React.useEffect(() => {
    if (!ref || !ref.current) {
      return;
    }

    ref.current.style.height = "1px";
    ref.current.style.height = `${ref.current.scrollHeight}px`;
  });

  return (
    <textarea
      ref={ref}
      rows={1}
      style={{
        resize: "none",
        width: "100%",
        maxHeight: 100,
        outline: "none"
      }}
      {...rest}
    />
  );
}
