import React from "react";
import { IconProps } from "./types";

function IconPersonRemove(props: IconProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="25"
      viewBox="0 0 25 25"
      {...props}
    >
      <g fill="currentColor" transform="translate(.5 .5)">
        <g data-name="Group 1012" transform="translate(1 1.13)">
          <path
            d="M13.885 11.3a.953.953 0 00-1.228.107.709.709 0 00.121 1.083l2.33 1.689a1.861 1.861 0 01.806 1.5v6.331h1.744v-6.328a3.333 3.333 0 00-1.443-2.694z"
            data-name="Path 499"
            transform="translate(-.519 -.456)"
          ></path>
          <path
            d="M1.744 15.681a1.862 1.862 0 01.806-1.5l2.33-1.689A.71.71 0 005 11.4a.953.953 0 00-1.228-.1l-2.33 1.689A3.332 3.332 0 000 15.681v6.332h1.744z"
            data-name="Path 500"
            transform="translate(0 -.456)"
          ></path>
          <path
            d="M14.8 18.593l-.012-1.448a.839.839 0 00-.879-.764.824.824 0 00-.865.775l.007 5.667H14.8z"
            data-name="Path 501"
            transform="translate(-.543 -1.267)"
          ></path>
          <path
            d="M4.753 18.605l.012-1.448a.824.824 0 00-.865-.775.846.846 0 00-.879.764l-.007 5.678h1.739z"
            data-name="Path 502"
            transform="translate(-.125 -1.267)"
          ></path>
          <path
            d="M14.4 5.892A5.711 5.711 0 108.694 11.6 5.717 5.717 0 0014.4 5.892M8.694 9.858a3.966 3.966 0 113.966-3.966 3.971 3.971 0 01-3.966 3.966"
            data-name="Path 503"
            transform="translate(-.124)"
          ></path>
        </g>
        <path
          d="M-3061.156-1263.819h-5.578a.9.9 0 01-.9-.9.9.9 0 01.9-.9h5.578a.9.9 0 01.9.9.9.9 0 01-.9.9z"
          data-name="Path 2018"
          transform="translate(3083.297 1274)"
        ></path>
      </g>
    </svg>
  );
}

export default IconPersonRemove;
