import useApi from "@/hooks/useApi";
import useLocalStorage from "@/hooks/useLocalStorage";
import useTimeZone from "@/hooks/useTimeZone";
import { formatDistance } from "date-fns";
import React, { useEffect, useState } from "react";
import AverageWaitTimeChart from "./AverageWaitTimeChart";
import ConversationsTable from "./ConversationsTable";
import KpiCard from "./KpiCard";
import useTranslations from "@/hooks/useTranslations";

interface DashboardSettings {
  dateRange: string;
}

interface DashboardKpi {
  resolvedConversationCount: number;
  unresolvedConversationCount: number;
  totalConversationCount: number;
  unassignedConversationCount: number;
  longestWaitTime?: number;
  averageWaitTime?: number;
}

export default function DashboardView() {
  const api = useApi();
  const timeZone = useTimeZone();
  const [data, setData] = useState<DashboardKpi>({} as DashboardKpi);
  const [settings, setSettings] = useLocalStorage<DashboardSettings>(
    "dashboard_settings",
    {
      dateRange: "Today"
    }
  );
  const translation = useTranslations();

  useEffect(() => {
    async function fetchData() {
      const resp = await api.messaging.post("Dashboard/Kpi", {
        dateRange: settings.dateRange,
        timeZone
      });
      setData(await resp.json());
    }

    fetchData();
  }, [api.messaging, settings.dateRange, timeZone]);

  return (
    <div className="h-full overflow-auto">
      <div className="p-4 border-b">
        <select
          className="input"
          value={settings.dateRange}
          onChange={(e) =>
            setSettings({
              ...settings,
              dateRange: e.currentTarget.value
            })
          }
        >
          <option value="Today">{translation.key("today")}</option>
          <option value="Yesterday">{translation.key("yesterday")}</option>
          <option value="ThisWeek">{translation.key("this_week")}</option>
          <option value="LastWeek">{translation.key("last_week")}</option>
          <option value="ThisMonth">{translation.key("this_month")}</option>
          <option value="LastMonth">{translation.key("last_month")}</option>
          <option value="Last30Days">
            {translation.key("last_thirty_days")}
          </option>
          <option value="Last60Days">
            {translation.key("last_sixty_days")}
          </option>
          <option value="Last90Days">
            {translation.key("last_ninety_days")}
          </option>
        </select>
      </div>
      <div className="flex flex-wrap p-2">
        <KpiCard onClick={() => {}}>
          <KpiCard.Value>{data.resolvedConversationCount}</KpiCard.Value>
          <KpiCard.Description>
            {translation.key("resolved_convo")}
          </KpiCard.Description>
        </KpiCard>
        <KpiCard onClick={() => {}}>
          <KpiCard.Value>{data.unresolvedConversationCount}</KpiCard.Value>
          <KpiCard.Description>
            {translation.key("unresolved_convo")}
          </KpiCard.Description>
        </KpiCard>
        <KpiCard onClick={() => {}}>
          <KpiCard.Value>{data.totalConversationCount}</KpiCard.Value>
          <KpiCard.Description>
            {translation.key("total_convos")}
          </KpiCard.Description>
        </KpiCard>
        <KpiCard onClick={() => {}}>
          <KpiCard.Value>{data.unassignedConversationCount}</KpiCard.Value>
          <KpiCard.Description>
            {translation.key("unassigned_convos")}
          </KpiCard.Description>
        </KpiCard>
        <KpiCard onClick={() => {}}>
          <KpiCard.Value>
            {data.longestWaitTime
              ? formatDistance(0, data.longestWaitTime)
              : "N/A"}
          </KpiCard.Value>
          <KpiCard.Description>
            {translation.key("max_wait_time")}
          </KpiCard.Description>
        </KpiCard>
        <KpiCard onClick={() => {}}>
          <KpiCard.Value>
            {data.averageWaitTime
              ? formatDistance(0, data.averageWaitTime)
              : "N/A"}
          </KpiCard.Value>
          <KpiCard.Description>
            {translation.key("ave_wait_time")}
          </KpiCard.Description>
        </KpiCard>
      </div>
      <div className="shadow border rounded mx-4 p-6">
        <AverageWaitTimeChart dateRange={settings.dateRange} />
      </div>
      <div>
        <ConversationsTable dateRange={settings.dateRange} />
      </div>
    </div>
  );
}
