import IconNotificationsBell from "@/icons/IconNotificationsBell";
import React from "react";
import { LeftNavButton } from "./MainLayout/LeftNav/LeftNavItems";
import useTranslations from "@/hooks/useTranslations";

export default function NotifyButton({ className }: { className?: string }) {
  const [show, setShow] = React.useState(false);
  const translation = useTranslations();

  React.useEffect(() => {
    if (Notification.permission === "default") {
      setShow(true);
    }
  }, []);

  if (!show) {
    return null;
  }

  function requestPermission() {
    Notification.requestPermission().then((permission) => {
      if (permission === "granted" || permission === "denied") {
        setShow(false);
      }
    });
  }

  return (
    <div className="relative">
      <button className={className} onClick={() => requestPermission()}>
        <LeftNavButton icon={<IconNotificationsBell />}>
          {translation.key("notifications")}
        </LeftNavButton>
      </button>
      <div className="absolute" style={{ top: 4, right: 14 }}>
        <div
          className="flex items-center justify-center h-4 w-4 rounded-full text-white text-xs relative top-1 -left-3"
          style={{ background: "#fc5" }}
        >
          !
        </div>
      </div>
    </div>
  );
}
