import { ConversationSource } from "@/types";
import useTranslations from "./useTranslations";

function getConversationSourceLabel(src: ConversationSource) {
  switch (src) {
    case "Facebook":
      return "Facebook";
    case "TwilioSms":
      return "SMS";
    case "Twitter":
      return "Twtitter";
    case "Web":
      return "Chat";
    default:
      return src;
  }
}

export default function useConversationSourceLabel() {
  return getConversationSourceLabel;
}
