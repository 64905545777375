import { Tag } from "@/types";
import Modal from "@velaro/velaro-shared/src/components/Modal";
import React, { useEffect, useMemo, useState } from "react";
import TagIcon from "@velaro/velaro-shared/src/svg/stroke-icons/tag.svg?react";
import Plus from "@velaro/velaro-shared/src/svg/stroke-icons/plus.svg?react";
import CheckCircleBroken from "@velaro/velaro-shared/src/svg/stroke-icons/check-circle-broken.svg?react";
import Check from "@velaro/velaro-shared/src/svg/stroke-icons/check.svg?react";
import AddTagBadge from "@velaro/velaro-shared/src/components/Badges/AddTagBadge";
import RemoveTagBadge from "@velaro/velaro-shared/src/components/Badges/RemoveTagBadge";
import { BadgeColor } from "@velaro/velaro-shared/src/components/types";
import {
  PrimaryBlueButton,
  SecondaryBlueButton
} from "@velaro/velaro-shared/src/components/Buttons/Buttons";

interface Props {
  allTags: Tag[];
  associatedTags: Tag[];
  onClose(): void;
  createTag(label: string): Promise<Tag>;
  updateTag(tag: Tag): Promise<Tag>;
  saveTags(tags: Tag[]): void;
  saving: boolean;
}

interface ColorOption {
  bg: string;
  border: string;
  color: BadgeColor;
}

export default function TagModal1(props: Props) {
  const [selectedTags, setSelectedTags] = useState<Tag[]>([]);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [filteredTags, setFilteredTags] = useState<Tag[]>(props.allTags);
  const [showColorPicker, setShowColorPicker] = useState<boolean>(false);
  const [selectedColor, setSelectedColor] = useState<BadgeColor>("gray");
  const [showTagMenu, setShowTagMenu] = useState<boolean>(false);

  const colorOptions: ColorOption[] = [
    {
      bg: "bg-slate-300",
      border: "border-slate-500",
      color: "gray"
    },
    {
      bg: "bg-cornflower-blue-300",
      border: "border-cornflower-blue-500",
      color: "blue"
    },
    {
      bg: "bg-crimson-red-300",
      border: "border-crimson-red-500",
      color: "red"
    },
    { bg: "bg-grape-300", border: "border-grape-500", color: "purple" },
    {
      bg: "bg-steel-blue-300",
      border: "border-steel-blue-500",
      color: "navy"
    },
    {
      bg: "bg-success-green-300",
      border: "border-success-green-500",
      color: "green"
    },
    {
      bg: "bg-sweet-orange-300",
      border: "border-sweet-orange-500",
      color: "yellow"
    }
  ];

  const tagOptions = useMemo(() => {
    return props.allTags.filter(
      (tag) => !props.associatedTags.some((t) => t.id === tag.id)
    );
  }, [props.allTags, props.associatedTags]);

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setSearchTerm(value);

    if (value) {
      const filtered = tagOptions.filter((tag) =>
        tag.name.toLowerCase().includes(value.toLowerCase())
      );
      setFilteredTags(filtered);
    } else {
      setFilteredTags(props.allTags);
    }
  };

  const handleSelectTag = (tag: Tag) => {
    if (!selectedTags.some((t) => t.id === tag.id)) {
      setSelectedTags([...selectedTags, tag]);
    }
    setSearchTerm("");
    setFilteredTags([]);
    setShowTagMenu(false);
  };

  const handleRemoveTag = (tag: Tag) => {
    setSelectedTags(selectedTags.filter((t) => t.id !== tag.id));
  };

  async function handleCreateTag(label: string) {
    const tag = await props.createTag(label);
    setSelectedTags([...selectedTags, tag]);
    setSearchTerm("");
    setShowTagMenu(false);
    setShowColorPicker(true);
  }

  const workingTag = useMemo(() => {
    return { ...selectedTags[selectedTags.length - 1] };
  }, [selectedTags]);

  function updateColor(color: BadgeColor) {
    const updatedTag = { ...workingTag, color: color };
    setSelectedTags([
      ...selectedTags.slice(0, selectedTags.length - 1),
      updatedTag
    ]);
  }

  function handleColorUpdate() {
    props.updateTag(workingTag);
    setShowColorPicker(false);
  }

  return (
    <Modal show={true} maxWidth="max-w-fit">
      <Modal.Header
        title={"Add Tag"}
        onClose={() => {
          props.onClose();
        }}
      />
      <Modal.Body>
        <div className="relative mx-4 text-stone-600 dark:text-white dark:border-stone-200 max-w-[500px] w-[400px] h-24">
          <div className="absolute left-0 right-0 bottom-0 top-0">
            <div className="flex flex-col">
              <div className="relative flex flex-wrap border rounded-lg items-center mb-2">
                <TagIcon className="text-slate-500 m-2" />
                {selectedTags.map((tag, index) => (
                  <div key={index} className="mr-2">
                    <RemoveTagBadge
                      label={tag.name}
                      onClick={() => {
                        handleRemoveTag(tag);
                      }}
                      color={tag.color}
                      size="sm"
                    />
                  </div>
                ))}
                <input
                  //ref={searchBar}
                  type="text"
                  id="search"
                  className="placeholder-slate-400 items-center h-full py-2 text-sm text-gray-900 focus:outline-none"
                  placeholder="Find or add a tag"
                  value={searchTerm}
                  onChange={handleSearch}
                  onClick={() => {
                    setShowTagMenu(true);
                    setShowColorPicker(false);
                    setFilteredTags(tagOptions);
                  }}
                ></input>
                <div className="absolute flex flex-wrap gap-4 mb-6 -bottom-16">
                  {tagOptions.slice(0, 3).map((tag, index) => (
                    <AddTagBadge
                      key={index}
                      label={tag.name}
                      onClick={() => {
                        handleSelectTag(tag);
                      }}
                      color={tag.color}
                      size="sm"
                    />
                  ))}
                </div>
              </div>
              {showTagMenu && filteredTags.length !== 0 && (
                <ul className="z-10 w-full bg-white border border-gray-300 rounded-md mt-1 max-h-40 overflow-auto shadow-lg">
                  {filteredTags.map((tag) => (
                    <li
                      key={tag.id}
                      className="p-2 cursor-pointer hover:bg-gray-200"
                      onClick={() => handleSelectTag(tag)}
                    >
                      <div className="flex items-center">
                        <AddTagBadge
                          label={tag.name}
                          color={tag.color}
                          size="sm"
                          onClick={() => {}}
                        />
                      </div>
                    </li>
                  ))}
                </ul>
              )}
              {filteredTags.length === 0 && searchTerm && !showColorPicker && (
                <ul className="z-10 w-full bg-white border border-gray-300 rounded-md mt-1 max-h-40 overflow-auto shadow-lg">
                  <li
                    className="p-2 cursor-pointer"
                    onClick={() => {
                      handleCreateTag(searchTerm);
                    }}
                  >
                    <div className="flex bg-cornflower-blue-100 text-cornflower-blue-500 justify-center items-center font-medium h-10 rounded">
                      <Plus className="text-cornflower-blue-500 mr-2" />
                      {searchTerm
                        ? `Create tag for "${searchTerm}"`
                        : "Create tag"}
                    </div>
                  </li>
                </ul>
              )}
              {showColorPicker && (
                <ul className="z-10 w-full bg-white border border-gray-300 rounded-md mt-1 max-h-40 overflow-auto shadow-lg">
                  <li className="p-2">
                    <div className="flex gap-2 items-center font-medium h-8 rounded">
                      {colorOptions.map((color, index) => (
                        <div
                          key={index}
                          className={`flex w-5 h-5 rounded-tl rounded-br ${color.bg} border ${color.border} items-center hover:cursor-pointer justify-center`}
                          onClick={() => {
                            setSelectedColor(color.color);
                            updateColor(color.color);
                          }}
                        >
                          {selectedColor === color.color && (
                            <Check className="text-white" />
                          )}
                        </div>
                      ))}
                      <div className="flex flex-grow justify-end">
                        <CheckCircleBroken
                          className="hover:text-cornflower-blue-500 w-4 h-4"
                          onClick={() => {
                            handleColorUpdate();
                          }}
                        />
                      </div>
                    </div>
                  </li>
                </ul>
              )}
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div className="flex items-center pt-2">
          <div className="ml-auto flex">
            <PrimaryBlueButton
              disabled={props.saving || showColorPicker || !selectedTags.length}
              label="Save"
              onClick={() => {
                props.saveTags(selectedTags);
                props.onClose();
              }}
            />
          </div>
        </div>
      </Modal.Footer>
    </Modal>
  );
}
