import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import TranslationContext from "@/context/TranslationContext";

export default function TranslationProvider(props: {
  children: React.ReactNode;
}) {
  const { t, i18n } = useTranslation();

  useEffect(() => {
    const lng = navigator.language;
    i18n.changeLanguage(lng);
  }, [i18n]);

  function key(header: string) {
    const translatedHeading = t(header);
    return translatedHeading;
  }

  return (
    <TranslationContext.Provider value={{ key }}>
      {props.children}
    </TranslationContext.Provider>
  );
}
