import { TFunction } from "i18next";
import React, { useEffect } from "react";
import { UnaryExpression } from "typescript";

export interface TranslationProps {
  key: (key: string) => string;
}

const TranslationContext = React.createContext<TranslationProps>({
  key: (key: string) => key
});

export default TranslationContext;
