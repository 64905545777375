import React from "react";
import { IconProps } from "./types";

function IconSentimentNeutral(props: IconProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      {...props}
    >
      <path
        fill="currentColor"
        d="M16 16H0V0h16v16zM4.706 9.882a.941.941 0 000 1.883h6.588a.941.941 0 100-1.883zm5.994-5.32a.881.881 0 10.009 0zm-5.121 0a.882.882 0 10.008 0z"
        data-name="Exclusion 2"
      ></path>
    </svg>
  );
}

export default IconSentimentNeutral;
