import React from "react";
import { IconProps } from "./types";

function IconFormstackLogo(props: IconProps) {
  return (
    <svg
      id="logosandtypes_com"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 150 150"
      fill="#20b574"
    >
      <path d="M0 0h150v150H0V0z" fill="none" />
      <path d="M115.2 30.1h7.8c1.9 0 3.4 1.5 3.4 3.4v83c0 1.9-1.5 3.4-3.4 3.4h-7.8V30.1zM132 41.3h7.9c1.9 0 3.4 1.5 3.4 3.4v60.6c0 1.9-1.5 3.4-3.4 3.4H132V41.3zM12 18.8h94.2c1.9 0 3.4 1.5 3.4 3.4v105.4c0 1.9-1.5 3.4-3.4 3.4H12c-1.9 0-3.4-1.5-3.4-3.4V22.2c0-1.9 1.5-3.4 3.4-3.4z" />
      <path
        d="M32.2 36.8h51.7c.6 0 1.1.5 1.1 1.1 0 .3-.2.7-.4.9L32.9 80.2c-.5.4-1.2.3-1.6-.2-.2-.2-.2-.4-.3-.7V37.9c.1-.6.6-1.1 1.2-1.1zm-1.1 67.3V86.8c0-.3.2-.7.4-.9L46.6 74c.2-.2.4-.2.7-.2h27.4c.6 0 1.1.5 1.1 1.1 0 .4-.2.7-.5.9L32.8 105c-.5.4-1.2.2-1.6-.3-.1-.1-.1-.4-.1-.6zm.4 6.5l15.7-10.9c.5-.4 1.2-.2 1.5.3.1.2.2.4.2.6V112c0 .6-.5 1.1-1.1 1.1H32.1c-.6 0-1.1-.5-1.1-1.1v-.5c.1-.3.2-.7.5-.9z"
        fill="#fff"
      />
    </svg>
  );
}

export default IconFormstackLogo;
