import useAuth from "@/hooks/useAuth";
import React from "react";
import { useLocation, Outlet } from "react-router-dom";
import AppLoading from "@/components/AppLoading";

export default function Home() {
  const auth = useAuth();
  const location = useLocation();

  if (
    location.pathname.includes("/login") ||
    location.pathname.includes("/logout")
  ) {
    return <Outlet />;
  }

  if (!auth.isAuthenticated()) {
    auth.loginRedirect();
    return <AppLoading />;
  }

  return <Outlet />;
}
