import React from "react";
import { IconProps } from "./types";

function IconMoon(props: IconProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      {...props}
    >
      <defs>
        <clipPath id="clip-path">
          <path
            fill="currentColor"
            d="M0 0H24V24H0z"
            data-name="Rectangle 610"
          ></path>
        </clipPath>
      </defs>
      <g clipPath="url(#clip-path)" data-name="Group 354">
        <g data-name="Group 353">
          <g clipPath="url(#clip-path)" data-name="Group 352">
            <path
              fill="currentColor"
              d="M12.3 22h-.1a10.307 10.307 0 01-7.34-3.15 10.46 10.46 0 01-.26-14 10.13 10.13 0 014-2.74.985.985 0 011.3 1.22 8.4 8.4 0 001.94 8.81 8.469 8.469 0 008.83 1.94 1 1 0 011.27 1.29A10.159 10.159 0 0119.6 19a10.281 10.281 0 01-7.3 3M7.46 4.92a7.944 7.944 0 00-1.37 1.22 8.44 8.44 0 00.2 11.32A8.287 8.287 0 0012.22 20h.08a8.341 8.341 0 006.78-3.49A10.368 10.368 0 017.46 4.92"
              data-name="Path 547"
            ></path>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default IconMoon;
