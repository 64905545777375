import * as React from "react";
import ThemeContext from "../context/ThemeContext";
import { Theme } from "../types";

export default function ThemeProvider(props: { children: React.ReactNode }) {
  const [theme, setTheme] = React.useState<Theme>("light");

  React.useEffect(() => {
    const theme = localStorage.getItem("theme") as Theme;

    if (theme) {
      setTheme(theme);
    }
  }, []);

  React.useEffect(() => {
    document.querySelector("html")!.className = theme;
  }, [theme]);

  React.useEffect(() => {
    localStorage.setItem("theme", theme);
  }, [theme]);

  return (
    <ThemeContext.Provider value={{ theme, setTheme }}>
      {props.children}
    </ThemeContext.Provider>
  );
}
