import { ToastAlertSettings } from "@/types";
import { Severity } from "@velaro/velaro-shared/src/components/ToastAlert";
import React from "react";

export interface ToastAlertProps {
  toastSettings: ToastAlertSettings;
  displayToast(severity: Severity, message: string): void;
  updateToastSettings(updates: Partial<ToastAlertSettings>): void;
}

const ToastAlertContext = React.createContext<ToastAlertProps>({
  toastSettings: {
    stickyAlerts: false,
    stickyDuration: 5000
  },
  displayToast() {},
  updateToastSettings() {}
});

export default ToastAlertContext;
