import React from "react";

export default function KpiCard({
  children,
  onClick
}: {
  children: React.ReactNode;
  onClick(): void;
}) {
  return (
    <a
      className="m-2 block border shadow text-center p-6 rounded-lg"
      href="#"
      onClick={(e) => {
        e.preventDefault();
        onClick();
      }}
    >
      {children}
    </a>
  );
}

function KpiCardValue({ children }: { children: React.ReactNode }) {
  return <div className="text-2xl">{children}</div>;
}

function KpiCardDescription({ children }: { children: React.ReactNode }) {
  return <div>{children}</div>;
}

KpiCard.Value = KpiCardValue;
KpiCard.Description = KpiCardDescription;
