import { Contact } from "@/types";
import React, { useMemo } from "react";
import IconEmail from "@velaro/velaro-shared/src/icons/IconEmail";
import IconPhone from "@velaro/velaro-shared/src/icons/IconPhone";
import IconPin from "@velaro/velaro-shared/src/icons/IconPin";
import IconFacebook from "@velaro/velaro-shared/src/icons/IconFacebook";

interface Props {
  contact: Contact;
}

export default function ContactInformation({ contact }: Props) {
  const location = useMemo(() => {
    if (
      !contact.address ||
      !contact.city ||
      !contact.state ||
      !contact.country
    ) {
      return null;
    }
    return `${contact.address} ${contact.city} ${contact.state} ${contact.country}`;
  }, [contact]);

  return (
    <div className="bg-white rounded-lg p-4 mb-4">
      <div className="text-sm font-semibold mb-4">Contact Information</div>
      <div className="flex flex-col">
        {!contact.email &&
          !contact.phone &&
          !contact.mobile &&
          !location &&
          !contact.facebookProfileUrl && (
            <div className="text-xs text-slate-400 mb-4">
              No contact information available.
            </div>
          )}
        {contact.email && (
          <div className="flex mb-4">
            <IconEmail className="w-4 h-4" />
            <div className="text-sm ml-3">{contact.email}</div>
          </div>
        )}
        {(contact.phone || contact.mobile) && (
          <div className="flex mb-4">
            <IconPhone className="w-4 h-4" />
            <div className="text-sm ml-3">
              {contact.phone || contact.mobile}
            </div>
          </div>
        )}
        {location && (
          <div className="flex mb-4">
            <IconPin className="w-4 h-4" />
            <div className="text-sm ml-3">{location}</div>
          </div>
        )}
        {contact.facebookProfileUrl && (
          <div className="flex mb-4">
            <IconFacebook className="w-4 h-4" />
            <div className="text-sm ml-3">{contact.facebookProfileUrl}</div>
          </div>
        )}
      </div>
      {/* <div className="bg-slate-50 p-4 rounded-lg">
        <div className="flex flex-col">
          <div className="flex justify-between mb-4">
            <div className="text-sm font-semibold">Status</div>
            <div className="text-sm">Active</div>
          </div>
          <div className="flex justify-between mb-4">
            <div className="text-sm font-semibold">Last Conversation</div>
            <div className="text-sm">8 mins ago</div>
          </div>
          <div className="flex justify-between mb-4">
            <div className="text-sm font-semibold">Contact Added</div>
            <div className="text-sm">12/31/2023</div>
          </div>
          <div className="flex justify-between">
            <div className="text-sm font-semibold">Assignee</div>
            <div className="text-sm">Mary Aude</div>
          </div>
        </div>
      </div> */}
    </div>
  );
}
