import { Contact } from "@/types";
import Modal from "@velaro/velaro-shared/src/components/Modal";
import React, { useEffect, useState } from "react";
import useApi from "@/hooks/useApi";
import ContactNote from "./ContactNote";
import Tags from "../Conversations/Tags";
import useContactTags from "@/hooks/useContactTags";
import { SecondaryBlueButton } from "@velaro/velaro-shared/src/components/Buttons/Buttons";
import { PrimaryBlueButton } from "@velaro/velaro-shared/src/components/Buttons/Buttons";
import useToastAlert from "@/hooks/useToastAlert";
import { Severity } from "@velaro/velaro-shared/src/components/ToastAlert";
import { set } from "lodash";
import ContactForm from "./ContactForm";

interface Props {
  onClose(): void;
}

const defaultContact: Contact = {
  id: 0,
  picture: "",
  name: "",
  email: "",
  displayName: "",
  lastActivity: ""
};

interface CountryModel {
  countryId: string;
  countryName: string;
}

const inputClass = "input w-full";

export default function AddContactModal(props: Props) {
  const [contact, setContact] = useState<Contact>(defaultContact);
  const [errors, setErrors] = React.useState<Record<string, string>>({});
  const [saving, setSaving] = useState(false);
  const api = useApi();
  const toastAlert = useToastAlert();
  //const contactTags = useContactTags(contact.id);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setContact({ ...contact, [name]: value });
  };

  const handleSubmit = async () => {
    setSaving(true);
    const response = await api.messaging.post("Contacts", contact);
    if (response.ok) {
      props.onClose();
      toastAlert.displayToast(Severity.Success, "Contact added successfully");
    } else if (response.status === 400) {
      const errorResponse = await response.json();
      if (errorResponse.errors == null) {
        toastAlert.displayToast(Severity.Error, errorResponse);
        setSaving(false);
        return;
      }
      setErrors(errorResponse.errors);
      setSaving(false);
    } else {
      toastAlert.displayToast(Severity.Error, "An unexpected error occurred");
      setSaving(false);
    }
  };

  return (
    <Modal show={true} maxWidth="max-w-[1000px]">
      <Modal.Header title="Add New Contact" onClose={() => props.onClose()} />
      <Modal.Body>
        <div className="py-6">
          <ContactForm
            contact={contact}
            setContact={setContact}
            errors={errors}
            handleChange={handleChange}
          />
          <label className="text-sm font-medium">Notes</label>
          <ContactNote setContactNote={() => {}} />
          {/* <Tags
              label="Tags"
              onSave={() => {}}
              onRemove={() => {}}
              allTags={contactTags.all}
              selectedTags={contactTags.selected}
            /> */}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div className="flex items-center py-3">
          <div className="ml-auto flex">
            <div className="mr-2">
              <SecondaryBlueButton
                label="Cancel"
                onClick={() => props.onClose()}
              />
            </div>
            <PrimaryBlueButton
              disabled={saving}
              label="Save changes"
              onClick={handleSubmit}
            />
          </div>
        </div>
      </Modal.Footer>
    </Modal>
  );
}
