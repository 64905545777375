import React from "react";
import { IconProps } from "./types";

function IconChevronLeft(props: IconProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      {...props}
    >
      <g transform="rotate(90 8 8)">
        <path
          fill="currentColor"
          d="M12 15.5a1 1 0 01-.71-.29l-4-4a1 1 0 011.419-1.42L12 13.1l3.3-3.178a1 1 0 111.379 1.439l-4 3.857A1 1 0 0112 15.5z"
          data-name="Path 548"
          transform="translate(-3.996 -4.496)"
        ></path>
      </g>
    </svg>
  );
}

export default IconChevronLeft;
