import React from "react";
import { IconProps } from "./types";

function IconArrowDown2(props: IconProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      {...props}
    >
      <path
        fill="currentColor"
        d="M5.23 10.64a1 1 0 001.41.13L11 7.14V19a1 1 0 002 0V7.14l4.36 3.63a1 1 0 001.28-1.54l-6-5-.15-.09-.13-.07a1 1 0 00-.72 0l-.13.07-.15.09-6 5a1 1 0 00-.13 1.41z"
        data-name="Path 1770"
        transform="rotate(180 12 12)"
      ></path>
    </svg>
  );
}

export default IconArrowDown2;
