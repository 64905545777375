import React from "react";
import { IconProps } from "./types";

function IconMoreVerticalDots(props: IconProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      {...props}
    >
      <g>
        <g fill="currentColor" data-name="more-vertical">
          <circle
            cx="2"
            cy="2"
            r="2"
            data-name="Ellipse 12"
            transform="translate(10 10)"
          ></circle>
          <circle
            cx="2"
            cy="2"
            r="2"
            data-name="Ellipse 13"
            transform="translate(10 3)"
          ></circle>
          <circle
            cx="2"
            cy="2"
            r="2"
            data-name="Ellipse 14"
            transform="translate(10 17)"
          ></circle>
        </g>
      </g>
    </svg>
  );
}

export default IconMoreVerticalDots;
