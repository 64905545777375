import React from "react";
import { IconProps } from "./types";

function IconContactsCrm(props: IconProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="28"
      height="28"
      viewBox="0 0 28 28"
      {...props}
    >
      <defs>
        <clipPath id="clip-path">
          <path
            fill="currentColor"
            d="M0 0H28V28H0z"
            data-name="Rectangle 1617"
          ></path>
        </clipPath>
      </defs>
      <g clipPath="url(#clip-path)" data-name="Group 691">
        <g fill="currentColor" transform="translate(2 .879)">
          <path
            d="M9.611 19.4H4.529a.646.646 0 01-.615-.67v-.223a.647.647 0 01.615-.67h5.082a.647.647 0 01.616.67v.223a.647.647 0 01-.616.67"
            data-name="Path 1130"
            transform="translate(-.559 -1.141)"
          ></path>
          <path
            d="M13.674 22.529H4.529a.647.647 0 01-.616-.67v-.223a.647.647 0 01.616-.67h9.145a.647.647 0 01.616.67v.223a.647.647 0 01-.616.67"
            data-name="Path 1131"
            transform="translate(-.559 -1.354)"
          ></path>
          <path
            d="M21.455 14.288a.843.843 0 00-.8.875v7.894a.578.578 0 01-.553.6H2.163a.579.579 0 01-.553-.6v-11.2h7.357a.878.878 0 000-1.75H1.61V7.386a.32.32 0 01.3-.332h7.9a.843.843 0 00.8-.875.843.843 0 00-.8-.875h-7.9A2.005 2.005 0 000 7.386v15.671a2.265 2.265 0 002.163 2.35H20.1a2.265 2.265 0 002.163-2.35v-7.894a.843.843 0 00-.8-.875"
            data-name="Path 1132"
            transform="translate(0 -.285)"
          ></path>
          <path
            d="M17.613 1.121c-4.3 0-7.791 3.8-7.791 8.469s3.495 8.469 7.791 8.469S25.4 14.26 25.4 9.59s-3.495-8.469-7.791-8.469m6.252 8.469a6.545 6.545 0 01-6.249 6.792 6.545 6.545 0 01-6.248-6.792 6.545 6.545 0 016.249-6.79 6.545 6.545 0 016.248 6.79"
            data-name="Path 1133"
            transform="translate(-1.403)"
          ></path>
          <path
            d="M18.527 10.873a3.377 3.377 0 003.225-3.5 3.377 3.377 0 00-3.225-3.5 3.376 3.376 0 00-3.223 3.5 3.376 3.376 0 003.223 3.5m-1.894-3.5a1.9 1.9 0 113.789 0 1.9 1.9 0 11-3.789 0"
            data-name="Path 1134"
            transform="translate(-2.186 -.187)"
          ></path>
          <path
            d="M20.339 12.941a20.048 20.048 0 012.15 1.432 1.029 1.029 0 01.213.265 1.35 1.35 0 01.164.673v.742h1.522v-.742a3.1 3.1 0 00-.4-1.55 2.652 2.652 0 00-.549-.676 22.071 22.071 0 00-2.378-1.6.7.7 0 00-.579-.066.774.774 0 00-.453.4.866.866 0 00.306 1.122"
            data-name="Path 1135"
            transform="translate(-2.849 -.701)"
          ></path>
          <path
            d="M14.216 16.052v-.742a1.35 1.35 0 01.164-.673 1.029 1.029 0 01.213-.265 20.049 20.049 0 012.15-1.432.866.866 0 00.306-1.122.731.731 0 00-1.031-.333 21.927 21.927 0 00-2.378 1.6 2.664 2.664 0 00-.549.676 3.109 3.109 0 00-.4 1.551v.742z"
            data-name="Path 1136"
            transform="translate(-1.813 -.701)"
          ></path>
        </g>
      </g>
    </svg>
  );
}

export default IconContactsCrm;
