import React from "react";
import { IconProps } from "./types";

function IconCameraCircle(props: IconProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      {...props}
    >
      <g
        fill="none"
        stroke="currentColor"
        strokeWidth="1.8"
        data-name="Ellipse 42"
      >
        <circle cx="12" cy="12" r="12" stroke="none"></circle>
        <circle cx="12" cy="12" r="11.1"></circle>
      </g>
      <g fill="currentColor" data-name="Group 206" transform="translate(5 6)">
        <path
          d="M13.657 5.743H11.6V4.714A1.714 1.714 0 009.886 3H7.829a1.714 1.714 0 00-1.715 1.714v1.029H4.057A2.057 2.057 0 002 7.8v5.486a2.057 2.057 0 002.057 2.057h9.6a2.057 2.057 0 002.057-2.057V7.8a2.057 2.057 0 00-2.057-2.057zM7.486 4.714a.343.343 0 01.343-.343h2.057a.343.343 0 01.343.343v1.029H7.486zm6.857 8.571a.686.686 0 01-.686.686h-9.6a.686.686 0 01-.686-.686V7.8a.686.686 0 01.686-.686h9.6a.686.686 0 01.686.686z"
          data-name="Path 323"
          transform="translate(-2 -3)"
        ></path>
        <path
          d="M10.9 10.5a2.4 2.4 0 102.4 2.4 2.4 2.4 0 00-2.4-2.4zm0 3.429a1.029 1.029 0 111.029-1.029 1.029 1.029 0 01-1.029 1.029z"
          data-name="Path 324"
          transform="translate(-4.043 -5.357)"
        ></path>
      </g>
    </svg>
  );
}

export default IconCameraCircle;
