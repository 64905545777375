import React, {
  useCallback,
  useEffect,
  useMemo,
  useReducer,
  useRef
} from "react";

import useApi from "@/hooks/useApi";
import useSubscribe from "./useSubscribe";
import useConnection from "./useConnection";
import { HubConnectionState } from "@microsoft/signalr";
import messageListReducer from "@/reducers/messageListReducer";
import messageGroupReducer from "@/reducers/messageGroupReducer";

export default function useMessages(conversationBaseId: number) {
  const cancel = useRef(false);
  const [messages, dispatch] = useReducer(messageListReducer, []);

  const [loadingMessages, setLoadingMessages] = React.useState(true);
  const [conversationFullyLoaded, setConversationFullyLoaded] =
    React.useState(false);
  const api = useApi();
  const subscribe = useSubscribe();
  const { connectionState } = useConnection();

  // Fetch messages on load

  const fetch = useCallback(() => {
    cancel.current = false;

    async function fetchMessages() {
      setConversationFullyLoaded(false);
      const response = await api.messaging.get(
        `Conversations/${conversationBaseId}/Messages`
      );

      if (cancel.current === true) {
        return;
      }

      dispatch({ type: "SET_MESSAGES", messages: await response.json() });
      setLoadingMessages(false);
    }

    if (connectionState === HubConnectionState.Connected) {
      fetchMessages();
    }

    return () => {
      cancel.current = true;
    };
  }, [api, connectionState, conversationBaseId]);

  useEffect(() => {
    fetch();
  }, [fetch]);

  // Exposed method to load more messages as the user scrolls up.

  const loadMoreMessages = useCallback(async () => {
    if (conversationFullyLoaded) {
      return;
    }

    const lastMessage = messages[0];

    if (!lastMessage) {
      return;
    }

    const response = await api.messaging.get(
      `Conversations/${conversationBaseId}/Messages?lastMessageId=${lastMessage.id}`
    );

    const loadedMessages = await response.json();

    if (cancel.current === true) {
      return;
    }

    if (loadedMessages.length > 0) {
      dispatch({ type: "PREPEND_MESSAGES", messages: loadedMessages });
    } else {
      setConversationFullyLoaded(true);
    }
  }, [api, messages, conversationBaseId, conversationFullyLoaded]);

  const groupedMessages = useMemo(
    () => messageGroupReducer(messages),
    [messages]
  );

  useEffect(
    () =>
      subscribe("receive-message", (data) => {
        if (data.message.conversationBaseId !== conversationBaseId) {
          return;
        }

        dispatch({ type: "ADD_MESSAGE", message: data.message });
      }),
    [conversationBaseId, subscribe]
  );

  React.useEffect(
    () =>
      subscribe("conversation-assigned", (message) => {
        if (conversationBaseId === message.conversation.id) {
          fetch();
        }
      }),
    [subscribe, conversationBaseId, fetch]
  );

  useEffect(
    () =>
      subscribe("delete-attachment", (data) => {
        if (data.conversationBaseId !== conversationBaseId) {
          return;
        }

        dispatch({
          type: "DELETE_ATTACHMENT",
          messageId: data.messageId,
          attachmentId: data.attachmentId
        });
      }),
    [conversationBaseId, subscribe]
  );

  return {
    messages,
    groupedMessages,
    loadingMessages,
    loadMoreMessages
  };
}
