import React from "react";
import { IconProps } from "./types";

function IconArrowRefresh(props: IconProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      {...props}
    >
      <defs>
        <clipPath id="clip-path">
          <path
            fill="currentColor"
            d="M0 0H24V24H0z"
            data-name="Rectangle 2049"
          ></path>
        </clipPath>
      </defs>
      <g clipPath="url(#clip-path)" data-name="Group 875">
        <path
          fill="currentColor"
          d="M19.149 9.7a8.9 8.9 0 00-7.111-6.808 9 9 0 00-3.015-.027 9.227 9.227 0 00-1.469.363L7.649 3c.14-.332.282-.664.417-1a1.038 1.038 0 00-.023-.887.995.995 0 00-1.818.177q-.708 1.66-1.409 3.323a1 1 0 00.356 1.321L8.1 8.145a.99.99 0 001.59-.84 1.033 1.033 0 00-.49-.812q-.682-.524-1.369-1.042l-.169-.128a6.656 6.656 0 012.8-.575h.373c.125.006.252.018.376.032a6.631 6.631 0 013.96 1.813 6.744 6.744 0 012.162 5.9 6.622 6.622 0 01-2.443 4.5 6.705 6.705 0 01-5.659 1.5 6.8 6.8 0 01-4.613-3.049 6.256 6.256 0 01-.365-.659l-.155-.3a.956.956 0 00-1.333-.421.941.941 0 00-.446 1.29 8.921 8.921 0 007.629 5.225q.283.02.567.02a8.734 8.734 0 002.67-.427 8.627 8.627 0 004.195-2.885A8.735 8.735 0 0019.149 9.7"
          data-name="Path 1779"
          transform="translate(.78 1.4)"
        ></path>
      </g>
    </svg>
  );
}

export default IconArrowRefresh;
