import Logo from "@velaro/velaro-shared/src/images/logo_color.png";
import React, { useState } from "react";
import useProfile from "@/hooks/useProfile";
import MobileNavMenu from "./MobileNavMenu";
import DesktopProfileMenu from "./DesktopProfileMenu";
import MobileProfileMenu from "./MobileProfileMenu";

interface Props {
  children: React.ReactNode;
  headerActions?: React.ReactNode;
}

export default function MobileLayout(props: Props) {
  const [showNavMenu, setShowNavMenu] = useState(false);
  const [showProfileMenu, setShowProfileMenu] = useState(false);
  const { profile } = useProfile();

  return (
    <div className="flex flex-col h-screen">
      <div className="mobile-nav flex items-center shadow-md z-20 h-14">
        <button className="ml-4" onClick={() => setShowNavMenu(true)}>
          <img src={Logo} alt="" className="h-7" />
        </button>
        <div className="font-semibold text-slate-gray-600 p-3 font-rubik">
          Workspace
        </div>
        <div className="flex items-center ml-auto">
          <button
            className="relative mr-3"
            onClick={() => setShowProfileMenu(true)}
          >
            <div
              className="absolute bottom-0 h-4 w-4 rounded-full bg-success-green-500 border-2 border-white"
              style={{ left: -6 }}
            ></div>
            <img
              className="h-9 w-9 rounded-full border"
              src={profile.picture}
              alt=""
            />
          </button>
          <button
            className="h-14 w-14 text-center text-xl"
            onClick={() => setShowNavMenu(!showNavMenu)}
          >
            <i className="fa-solid fa-bars"></i>
          </button>
          {/* {props.headerActions} */}
        </div>
      </div>

      {showProfileMenu && (
        //MobileProfileMenu exists, but do we need it?
        <MobileProfileMenu onClose={() => setShowProfileMenu(false)} />
      )}

      {showNavMenu && <MobileNavMenu onClose={() => setShowNavMenu(false)} />}

      <main className="flex-1 bg-slate-gray-100 text-slate-gray-600 overflow-hidden">
        {props.children}
      </main>
    </div>
  );
}
