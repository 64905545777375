import React from "react";
import { IconProps } from "./types";

function IconSecurityLock(props: IconProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="currentColor"
      {...props}
    >
      <g transform="translate(-1.471 1)">
        <g transform="translate(6 2)">
          <path
            d="M20.111,8.641h-1.66V6.565A4.565,4.565,0,0,0,13.886,2h-.83A4.565,4.565,0,0,0,8.49,6.565V8.641H6.83a.83.83,0,0,0-.83.83v9.961a.83.83,0,0,0,.83.83H20.111a.83.83,0,0,0,.83-.83V9.471A.83.83,0,0,0,20.111,8.641ZM10.15,6.565A2.905,2.905,0,0,1,13.056,3.66h.83a2.905,2.905,0,0,1,2.905,2.905V8.641H10.15ZM19.281,18.6H7.66V10.3H19.281Z"
            transform="translate(-6 -2)"
          />
          <path
            d="M23.22,27.581a1.66,1.66,0,1,0-2.49,1.453v1.038a.83.83,0,0,0,1.66,0V29.034A1.66,1.66,0,0,0,23.22,27.581Z"
            transform="translate(-14.131 -16.001)"
          />
        </g>
      </g>
      <rect width="24" height="24" fill="none" />
    </svg>
  );
}

export default IconSecurityLock;
