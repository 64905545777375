import { AppEvent } from "./../types";
import React from "react";
import { HubConnection, HubConnectionState } from "@microsoft/signalr";

interface Props {
  connection: HubConnection;
  connectionState: HubConnectionState;
  on: (event: AppEvent, callback: () => void) => void;
  off: (event: AppEvent, callback: () => void) => void;
}

const ConnectionContext = React.createContext<Props>({
  connection: {} as any,
  connectionState: HubConnectionState.Disconnected,
  on: () => {},
  off: () => {}
});

export default ConnectionContext;
