import React from "react";
import { IconProps } from "./types";

function IconCheckMarkSquareCancel(props: IconProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      {...props}
    >
      <defs>
        <clipPath id="clip-path">
          <path
            fill="currentColor"
            d="M0 0H24V24H0z"
            data-name="Rectangle 599"
          ></path>
        </clipPath>
      </defs>
      <g fill="currentColor" clipPath="url(#clip-path)" data-name="Group 341">
        <path
          d="M22.951 24H1.049A1.05 1.05 0 010 22.951V1.049A1.05 1.05 0 011.049 0h21.9A1.05 1.05 0 0124 1.049v21.9A1.05 1.05 0 0122.951 24M2.1 21.9h19.8V2.1H2.1z"
          data-name="Path 529"
        ></path>
        <path
          d="M17.788 18.837a1.044 1.044 0 01-.742-.308L6.564 8.048a1.049 1.049 0 011.484-1.483L18.53 17.046a1.049 1.049 0 01-.742 1.791"
          data-name="Path 530"
          transform="translate(-.547 -.547)"
        ></path>
        <path
          d="M7.306 18.837a1.049 1.049 0 01-.742-1.791L17.046 6.565a1.049 1.049 0 011.484 1.483L8.048 18.53a1.044 1.044 0 01-.742.308"
          data-name="Path 531"
          transform="translate(-.547 -.547)"
        ></path>
      </g>
    </svg>
  );
}

export default IconCheckMarkSquareCancel;
