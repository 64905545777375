import React from "react";
import { SystemMessageGroupType } from "@/reducers/messageGroupReducer";

interface Props {
  item: SystemMessageGroupType;
}

export default function SystemMessageGroup(props: Props) {
  return (
    <div className="py-4 flex flex-col items-center justify-center">
      {props.item.messages.map((message) => (
        <div
          key={message.id}
          className="px-4 text-xs font-semibold border border-dashed rounded-md border-slate-gray-300 p-2 gap-2 my-2"
          style={{ color: message.color }}
        >
          {message.text}
        </div>
      ))}
    </div>
  );
}
