import React, { useEffect } from "react";
import useTranslations from "@/hooks/useTranslations";

export default function NoConversationSelected() {
  const translation = useTranslations();
  return (
    <div className="h-full flex flex-col items-center justify-center pb-16">
      <div className="text-blue mb-5">
        <i className="fa-4x far fa-comments"></i>
      </div>
      <div>{translation.key("select_a_conversation")}</div>
    </div>
  );
}
