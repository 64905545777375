import React from "react";
import { IconProps } from "./types";

function IconCheckMarkCircleCancel(props: IconProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24.001"
      viewBox="0 0 24 24.001"
      {...props}
    >
      <g fill="currentColor" data-name="Group 1004" transform="translate(2 2)">
        <path
          d="M3440.719 1305.124l-3.01-3.011-3.011 3.011a.994.994 0 11-1.406-1.4l3.011-3.011-3.011-3.012a.994.994 0 111.406-1.4l3.011 3.011 3.011-3.011a.993.993 0 111.4 1.4l-3.011 3.012 3.011 3.011a.994.994 0 11-1.406 1.4z"
          data-name="Union 6"
          transform="translate(-3427.708 -1290.708)"
        ></path>
        <path
          d="M18 10a7.966 7.966 0 11-5.8-7.7L13.75.751A9.306 9.306 0 0010 0a10 10 0 1010 10z"
          data-name="Path 536"
        ></path>
      </g>
    </svg>
  );
}

export default IconCheckMarkCircleCancel;
