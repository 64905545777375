import { Message, MessageBot, MessageContact, MessageUser } from "@/types";

export type DaySeparatorType = {
  type: "DAY_SEPARATOR";
  timestamp: string;
};

export type UserMessageGroupType = {
  type: "USER_MESSAGE_GROUP";
  user: MessageUser;
  messages: Message[];
};

export type ContactMessageGroupType = {
  type: "CONTACT_MESSAGE_GROUP";
  contact: MessageContact;
  messages: Message[];
};

export type BotMessageGroupType = {
  type: "BOT_MESSAGE_GROUP";
  user: MessageBot;
  messages: Message[];
};

export type AutoResponseGroupType = {
  type: "AUTO_RESPONSE_MESSAGE_GROUP";
  messages: Message[];
};

export type SystemMessageGroupType = {
  type: "SYSTEM_MESSAGE_GROUP";
  messages: Message[];
};

export type LinkMessageGroupType = {
  type: "LINK_MESSAGE_GROUP";
  messages: Message[];
};

export type MessageGroupItemType =
  | DaySeparatorType
  | UserMessageGroupType
  | BotMessageGroupType
  | ContactMessageGroupType
  | AutoResponseGroupType
  | SystemMessageGroupType
  | LinkMessageGroupType;

/**
 * input is a list of messages, output is a nested list of objects
 * that will look like this:
 *
 * [
 *  { type: "DAY_SEPARATOR", date: "2020-01-01" },
 *  { type: "USER_MESSAGE_GROUP", user: {}, messages: [] },
 *  { type: "CONTACT_MESSAGE_GROUP", user: {}, messages: [] },
 *  { type: "AUTO_RESPONSE_MESSAGE_GROUP", user: {}, messages: [] },
 * ]
 */

export default function messageGroupReducer(messages: Message[]) {
  const groupItems: MessageGroupItemType[] = [];

  for (let i = 0; i < messages.length; i++) {
    const message = messages[i];

    if (i === 0) {
      groupItems.push({
        type: "DAY_SEPARATOR",
        timestamp: message.timestamp
      });
    }

    if (i > 0 && diffDays(message.timestamp, messages[i - 1].timestamp)) {
      groupItems.push({
        type: "DAY_SEPARATOR",
        timestamp: message.timestamp
      });
    }

    if (message.type === "link") {
      groupItems.push({
        type: "LINK_MESSAGE_GROUP",
        messages: [message]
      });
      continue;
    }

    const prevGroupItem = groupItems[groupItems.length - 1];

    if (message.isAutoResponse) {
      if (prevGroupItem.type === "AUTO_RESPONSE_MESSAGE_GROUP") {
        prevGroupItem.messages.push(message);
      } else {
        groupItems.push({
          type: "AUTO_RESPONSE_MESSAGE_GROUP",
          messages: [message]
        });
      }
    } else if (message.type === "system") {
      if (prevGroupItem.type === "SYSTEM_MESSAGE_GROUP") {
        prevGroupItem.messages.push(message);
      } else {
        groupItems.push({
          type: "SYSTEM_MESSAGE_GROUP",
          messages: [message]
        });
      }
    } else if (message.type === "user") {
      if (prevGroupItem.type === "USER_MESSAGE_GROUP") {
        prevGroupItem.messages.push(message);
      } else {
        groupItems.push({
          type: "USER_MESSAGE_GROUP",
          user: message.user!,
          messages: [message]
        });
      }
    } else if (message.type === "bot") {
      if (prevGroupItem.type === "BOT_MESSAGE_GROUP") {
        prevGroupItem.messages.push(message);
      } else {
        groupItems.push({
          type: "BOT_MESSAGE_GROUP",
          user: message.bot!,
          messages: [message]
        });
      }
    } else {
      if (prevGroupItem.type === "CONTACT_MESSAGE_GROUP") {
        prevGroupItem.messages.push(message);
      } else {
        groupItems.push({
          type: "CONTACT_MESSAGE_GROUP",
          contact: message.contact!,
          messages: [message]
        });
      }
    }
  }

  return groupItems;
}

function diffDays(ts1: string, ts2: string) {
  return new Date(ts1).toDateString() !== new Date(ts2).toDateString();
}
