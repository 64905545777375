import Logo from "@velaro/velaro-shared/src/images/logo_color.png";
import React from "react";
import { NavLink } from "react-router-dom";

interface Props {
  onClose(): void;
}

export default function MobileNavMenu(props: Props) {
  const logo = <img src={Logo} alt="" className="h-7" />;

  return (
    <div className="fixed top-0 w-full h-screen bg-white z-20">
      <div className="flex items-center h-14 shadow-md mb-4">
        <div className="ml-4">{logo}</div>
        <button
          className="ml-auto h-14 w-14 text-center text-xl"
          onClick={() => props.onClose()}
        >
          <i className="fa fa-times"></i>
        </button>
      </div>
      <div className="text-slate-gray-600" onClick={() => props.onClose()}>
        <NavLink to="/conversations" className="flex items-center px-4 h-12">
          <i className="w-8 fa fa-comment-alt"></i>
          <div className="flex items-center w-full">
            <div>Messaging</div>
            {/* Need to add actual unread message count here */}
            {/* <div className="ml-auto w-6 h-6 bg-crimson-red-600 text-white rounded-full text-sm flex items-center justify-center">
              99
            </div> */}
          </div>
        </NavLink>
        {/* <NavLink to="/dashboard" className="flex items-center px-4 h-12">
          <i className="w-8 fa fa-chart-line"></i>
          <div>Insights</div>
        </NavLink> */}
        {/* <NavLink to="/contacts" className="flex items-center px-4 h-12">
          <i className="w-8 fa fa-address-book"></i>
          <div>Contacts</div>
        </NavLink> */}
      </div>
    </div>
  );
}
