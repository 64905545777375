import React, { useEffect } from "react";

interface Props {
  value: string;
  onUpdate: (value: string) => void;
  placeholder?: string;
  type?: "text" | "email" | "number" | "phone";
  className?: string;
}

export default function FieldEditor(props: Props) {
  const [value, setValue] = React.useState(props.value);

  useEffect(() => {
    setValue(props.value);
  }, [props.value]);

  return (
    <input
      type={props.type || "text"}
      className={
        props.className ||
        "block w-full focus:bg-cornflower-blue-50 outline-none dark:bg-stone-300 dark:text-white"
      }
      placeholder={props.placeholder}
      value={value || ""}
      onChange={(e) => setValue(e.currentTarget.value)}
      onBlur={() => {
        if (value !== props.value) {
          props.onUpdate(value);
        }
      }}
      onKeyDown={(e) => {
        if (e.key === "Enter") {
          e.currentTarget.blur();
        }
      }}
    />
  );
}
