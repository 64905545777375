import React from "react";
import { IconProps } from "./types";

function IconCloseCircle(props: IconProps) {
  return (
    <svg
      id="Icon-close-circle"
      xmlns="http://www.w3.org/2000/svg"
      width="4"
      height="4"
      viewBox="0 0 4 4"
    >
      <path
        id="Icon_ionic-ios-close-circle"
        data-name="Icon ionic-ios-close-circle"
        d="M11.375,3.375a8,8,0,1,0,8,8A8,8,0,0,0,11.375,3.375Zm2.027,10.9-2.027-2.027L9.348,14.271a.615.615,0,1,1-.869-.869l2.027-2.027L8.479,9.348a.615.615,0,0,1,.869-.869l2.027,2.027L13.4,8.479a.615.615,0,0,1,.869.869l-2.027,2.027L14.271,13.4a.618.618,0,0,1,0,.869A.611.611,0,0,1,13.4,14.271Z"
        transform="translate(-3.375 -3.375)"
        fill="#90bcf5"
      />
    </svg>
  );
}

export default IconCloseCircle;
