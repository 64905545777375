import React, { useState } from "react";

interface Props {
  label: string;
  logo?: any;
  selected: boolean;
  onSelect: () => void;
}

export default function AsideViewTab({
  label,
  logo,
  selected,
  onSelect
}: Props) {
  return (
    <button
      className={`h-16 flex flex-col flex-1 items-center justify-center ${
        !selected && "bg-gray-100"
      }`}
      onClick={onSelect}
    >
      <div className="text-sm">{label}</div>
    </button>
  );
}
