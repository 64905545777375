import React from "react";
import { IconProps } from "./types";

function IconSnooze2(props: IconProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      {...props}
    >
      <g fill="currentColor" data-name="Group 189" transform="translate(1 2)">
        <path
          d="M1.105-1.06a.357.357 0 01-.263-.1.357.357 0 01-.1-.263v-.559a.562.562 0 01.068-.267A2.18 2.18 0 01.961-2.5l4.406-5.6H1.249a.357.357 0 01-.263-.1.357.357 0 01-.1-.263v-.527a.357.357 0 01.1-.263.357.357 0 01.263-.1h5.682a.357.357 0 01.263.1.357.357 0 01.1.263v.59a.517.517 0 01-.072.255 2.629 2.629 0 01-.152.239L2.717-2.321H7.25a.367.367 0 01.271.1.367.367 0 01.1.271v.527a.357.357 0 01-.1.263.357.357 0 01-.263.1z"
          data-name="Path 310"
          transform="translate(6.747 15.941)"
        ></path>
        <path
          d="M16.435 20.707L14.6 18.875a9.331 9.331 0 01-10.185.246l-1.58 1.586a.933.933 0 01-1.319-1.319l1.413-1.413a9.326 9.326 0 014.53-15.924V2a2 2 0 012-2h.661a2 2 0 012 2v.292a9.327 9.327 0 013.91 15.374l1.722 1.722a.933.933 0 01-1.319 1.319zM1.865 11.19a7.46 7.46 0 107.459-7.46 7.46 7.46 0 00-7.459 7.46z"
          data-name="Union 1"
          transform="translate(1.632)"
        ></path>
        <path
          d="M3343.534-1437.522a.932.932 0 01-.684-.3l-3.351-3.613a.933.933 0 01.05-1.318.933.933 0 011.318.049l3.351 3.613a.933.933 0 01-.05 1.318.929.929 0 01-.634.251z"
          data-name="Path 321"
          transform="translate(-3322.466 1443.933)"
        ></path>
        <path
          d="M3340.182-1437.522a.927.927 0 01-.634-.249.933.933 0 01-.05-1.318l3.351-3.613a.933.933 0 011.318-.049.933.933 0 01.05 1.318l-3.351 3.613a.931.931 0 01-.684.298z"
          data-name="Path 323"
          transform="translate(-3339.25 1443.933)"
        ></path>
      </g>
    </svg>
  );
}

export default IconSnooze2;
