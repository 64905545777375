import { MessageAttachment } from "@/types";
import React from "react";
import ImageContainer from "./ImageContainer";
import MessageFileItem from "./MessageFileItem";
import MessageItem from "./MessageItem";

interface Props {
  attachments: MessageAttachment[];
  onImageLoad(): void;
}

export default function MessageAttachments({
  attachments,
  onImageLoad
}: Props) {
  if (!attachments) {
    return null;
  }

  return (
    <>
      {attachments.map((item, index) => (
        <React.Fragment key={index}>
          {item.type === "image" && (
            <MessageItem>
              <ImageContainer onImageLoad={onImageLoad}>
                <img src={item.url} alt="" className="max-h-48 rounded-lg" />
              </ImageContainer>
            </MessageItem>
          )}
          {item.type === "file" && (
            <MessageItem>
              <MessageFileItem file={item} />
            </MessageItem>
          )}
        </React.Fragment>
      ))}
    </>
  );
}
