import Pager from "@/components/Pager";
import useApi from "@/hooks/useApi";
import useConversationSourceLabel from "@/hooks/useConversationSourceLabel";
import useTimeZone from "@/hooks/useTimeZone";
import { Conversation, ConversationSource } from "@/types";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import TData from "./Table/TData";
import THeader from "./Table/THeader";
import useTranslations from "@/hooks/useTranslations";

interface Props {
  dateRange: string;
}

export default function ConversationsTable(props: Props) {
  const navigate = useNavigate();
  const api = useApi();
  const timeZone = useTimeZone();
  const [conversations, setConversations] = useState<Conversation[]>([]);
  const [totalRecords, setTotalRecords] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(5);

  const getSourceLabel = useConversationSourceLabel();
  const translation = useTranslations();

  useEffect(() => {
    async function fetchData() {
      const resp = await api.messaging.post("Dashboard/Conversations", {
        dateRange: props.dateRange,
        timeZone,
        currentPage,
        pageSize
      });

      const data = await resp.json();
      setTotalRecords(data.totalRecords);
      setConversations(data.conversations);
    }

    fetchData();
  }, [api.messaging, currentPage, pageSize, props.dateRange, timeZone]);

  ///possible loop through convos and covert source from "TwilioSms" to "SMS"

  return (
    <div className="p-4">
      <table className="w-full table-auto">
        <thead>
          <tr>
            <THeader colSpan={6}>{translation.key("conversation")}</THeader>
            <THeader colSpan={5}>{translation.key("contact")}</THeader>
          </tr>
          <tr>
            <THeader>{translation.key("id")}</THeader>
            <THeader>{translation.key("source")}</THeader>
            <THeader>{translation.key("agent")}</THeader>
            <THeader>{translation.key("start")}</THeader>
            <THeader>{translation.key("first_response")}</THeader>
            <THeader>{translation.key("end")}</THeader>
            <THeader>{translation.key("name")}</THeader>
            <THeader>{translation.key("display_name")}</THeader>
            <THeader>{translation.key("picture")}</THeader>
          </tr>
        </thead>
        <tbody>
          {conversations.map((c) => (
            <tr
              onClick={() => navigate(`/dashboard/${c.contact.id}/${c.id}`)}
              className="hover:bg-steel-blue-100 cursor-pointer"
              key={c.id}
            >
              <TData>{c.id}</TData>
              <TData>{getSourceLabel(c.source as ConversationSource)}</TData>
              <TData>{c.assignedUser?.name || ""}</TData>
              <TData>{formatTime(c.startTimestamp)}</TData>
              <TData>{formatTime(c.firstResponseTimestamp)}</TData>
              <TData>{formatTime(c.endTimestamp)}</TData>
              <TData>{c.contact.name}</TData>
              <TData>{c.contact.displayName}</TData>
              <TData>{c.contact.picture}</TData>
            </tr>
          ))}
        </tbody>
      </table>
      <div>
        <Pager
          currentPage={currentPage}
          setPage={setCurrentPage}
          pageSize={pageSize}
          totalRecords={totalRecords}
        />
      </div>
    </div>
  );
}

function formatTime(timestamp?: number | string) {
  if (!timestamp) {
    return "N/A";
  }

  return new Date(timestamp).toLocaleString();
}
