import React from "react";
import { IconProps } from "./types";

function IconHelp(props: IconProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      {...props}
    >
      <g fill="currentColor" data-name="Group 1011" transform="translate(1 1)">
        <path
          d="M11.9 19a1.116 1.116 0 111.116-1.116A1.117 1.117 0 0111.9 19"
          data-name="Path 544"
          transform="translate(-.899 -1.397)"
        ></path>
        <path
          d="M11.639 14.85a.87.87 0 01-.917-.815v-1.1A3.274 3.274 0 0112.652 10a1.853 1.853 0 001.074-2.093 2.008 2.008 0 00-1.617-1.438 2.339 2.339 0 00-1.817.38A1.825 1.825 0 009.5 8.327a.87.87 0 01-.917.816.871.871 0 01-.917-.816 3.387 3.387 0 011.47-2.741 4.381 4.381 0 013.348-.712 3.776 3.776 0 013.038 2.7 3.41 3.41 0 01-2 3.859 1.662 1.662 0 00-.966 1.5v1.1a.87.87 0 01-.917.815"
          data-name="Path 545"
          transform="translate(-.639 -.4)"
        ></path>
        <path
          d="M11 0a11 11 0 1011 11A11 11 0 0011 0m0 19.8a8.8 8.8 0 118.8-8.8 8.8 8.8 0 01-8.8 8.8"
          data-name="Path 546"
        ></path>
      </g>
    </svg>
  );
}

export default IconHelp;
