import React from "react";
import { IconProps } from "./types";

function IconEmoji(props: IconProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      {...props}
    >
      <path fill="none" d="M0 0H24V24H0z" data-name="Rectangle 1537"></path>
      <path
        fill="currentColor"
        d="M0 10a10 10 0 1110 10A10.011 10.011 0 010 10zm1.863 0A8.137 8.137 0 1010 1.863 8.146 8.146 0 001.863 10zm3.764 2.372a.932.932 0 011.487-1.122A4 4 0 0010 12.7a3.992 3.992 0 002.882-1.44.931.931 0 111.491 1.118A5.832 5.832 0 0110 14.559a5.834 5.834 0 01-4.373-2.187zm6.166-5.092a.928.928 0 01.927-.932h.009a.931.931 0 11-.936.932zm-5.44 0a.928.928 0 01.926-.932h.009a.931.931 0 11-.936.932z"
        data-name="Union 1"
        transform="translate(2 2)"
      ></path>
    </svg>
  );
}

export default IconEmoji;
