import emitter from "@/hub/emitter";
import eventMap from "@/hub/eventMap";

function subscribe<E extends keyof typeof eventMap>(
  event: E,
  listener: typeof eventMap[E]
) {
  emitter.on(event, listener);

  return () => {
    emitter.off(event, listener);
  };
}

export default function useSubscribe() {
  return subscribe;
}
