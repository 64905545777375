import {
  InboxContent,
  InboxFilters,
  InboxGroupBy,
  InboxSortBy,
  InboxTab
} from "./types";

export const defaultInboxTabState: InboxTab = {
  count: 0,
  conversations: []
};

export const defaultInboxState: InboxContent = {
  tabs: {
    mine: defaultInboxTabState,
    //incoming: defaultInboxTabState,
    all: defaultInboxTabState
  }
};

export const defaultInboxFilters: InboxFilters = {
  assignment: ["Incoming"],
  status: ["Open"],
  channel: [],
  agent: [],
  team: []
};

export const inboxFilterOptions = {
  assignment: {
    label: "Assignment",
    values: { Assigned: "Agent", BotChat: "Bot", Incoming: "Unassigned" }
  },
  status: {
    label: "Status",
    values: { Open: "Open", Resolved: "Resolved" }
  },
  channel: {
    label: "Channel",
    values: {
      Twitter: "Twitter",
      Facebook: "Facebook",
      Web: "Web",
      TwilioSms: "SMS",
      Email: "Email",
      WhatsApp: "WhatsApp"
    }
  },
  agent: {
    label: "Agent",
    values: []
  },
  team: {
    label: "Team",
    values: []
  }
};

// export const inboxFilterOptions: {
//   label: string;
//   value: string | string[];
//   group: "assignment" | "status" | "team" | "agent";
// }[] = [
//   { label: "Assigned", value: "assigned", group: "assignment" },
//   { label: "Unassigned", value: "incoming", group: "assignment" },
//   { label: "Open", value: "open", group: "status" },
//   { label: "Resolved", value: "closed", group: "status" },
//   { label: "Team", value: [], group: "team" },
//   { label: "Agent", value: [], group: "agent" },
//   //{ label: "Channel", value: "channel" },
//   // { label: "Mine", value: "mine" }
// ];

export const inboxSortOptions: { label: string; value: InboxSortBy }[] = [
  { label: "Newest", value: "newest" },
  { label: "Oldest", value: "oldest" },
  { label: "Longest", value: "longest" },
  { label: "Priority", value: "priority" }
  //{ label: "Agent", value: "agent" }
];

export const inboxGroupOptions: { label: string; value: InboxGroupBy }[] = [
  { label: "None", value: "none" },
  { label: "Channel", value: "channel" },
  { label: "Status", value: "status" },
  { label: "Agent", value: "agent" }
  //{ label: "Agent", value: "agent" }
];
