import useApi from "@/hooks/useApi";
import useProfile from "@/hooks/useProfile";
import IconTrash1 from "@/icons/IconTrash1";
import IconUpload2 from "@/icons/IconUpload2";
import {
  SecondaryBlueButton,
  PrimaryBlueButton
} from "@velaro/velaro-shared/src/components/Buttons/Buttons";
import Modal from "@velaro/velaro-shared/src/components/Modal";
import React, { useRef, useState } from "react";

interface Props {
  setShowProfilePicutreModal(show: boolean): void;
}

export default function ProfilePictureModal(props: Props) {
  const { profile, setProfile } = useProfile();
  const [profilePicture, setProfilePicture] = useState<string>(profile.picture);
  const [pictureFile, setPictureFile] = useState<File | null>(null);
  const [reset, setReset] = useState(false);
  const api = useApi();
  const profilePictureInput = useRef<HTMLInputElement>(null);

  const defaultProfilePictureUrl =
    "https://velaromessagingstaging.blob.core.windows.net/messaging-public/default.png";

  function handleUpload(pictureFile: File) {
    setPictureFile(pictureFile);
    let imageSrc: string = "";
    if (isFileImage(pictureFile)) {
      imageSrc = URL.createObjectURL(pictureFile);
    }
    setProfilePicture(imageSrc);
    setReset(false);
  }

  async function handleSubmit() {
    if (reset) {
      const resp = await api.messaging.post("Profile/ResetProfilePicture");
      const picture: string = await resp.json();
      setProfile({ ...profile, picture });
      return;
    }

    const formData = new FormData();

    if (pictureFile) {
      formData.append("picture", pictureFile);
    }

    const resp = await api.messaging.postFormData(
      "Profile/UpdateProfilePicture",
      formData
    );

    const picture: string = await resp.json();

    setProfile({ ...profile, picture });
  }

  function handleRemove() {
    setProfilePicture(profile.picture);
    setReset(false);
    setPictureFile(null);
  }

  function handleReset() {
    setReset(true);
    setProfilePicture(defaultProfilePictureUrl);
  }

  return (
    <Modal show={true}>
      <Modal.Header
        title="Change your profile picture"
        onClose={() => props.setShowProfilePicutreModal(false)}
      />
      <Modal.Body>
        <div className="flex justify-center items-center space-x-8">
          <div className="w-1/2 flex justify-center">
            <div className="bg-white p-4 my-4 rounded-full overflow-hidden">
              <img
                src={profilePicture}
                alt="Profile Image"
                className="h-28 w-28 rounded-full border"
              />
            </div>
          </div>
          <div className="w-1/2">
            <div className="flex flex-col space-y-4">
              <div
                className="w-28 flex hover:bg-slate-gray-50 p-2 rounded hover:text-cornflower-blue-500 hover:cursor-pointer"
                onClick={(e) => {
                  e.stopPropagation();
                  profilePictureInput.current?.click();
                }}
              >
                <input
                  className="hidden"
                  ref={profilePictureInput}
                  type="file"
                  accept="image/*"
                  onChange={(e) => {
                    if (!e.currentTarget.files?.length) {
                      return;
                    }
                    handleUpload(e.currentTarget.files[0]);
                  }}
                />
                <IconUpload2 className="h-6 w-6" />
                <div className="ml-2">Upload</div>
              </div>
              {(pictureFile || reset) && (
                <div
                  className="w-28 flex hover:bg-slate-gray-50 p-2 rounded hover:text-cornflower-blue-500 hover:cursor-pointer"
                  onClick={(e) => {
                    e.stopPropagation();
                    handleRemove();
                  }}
                >
                  <IconTrash1 className="h-6 w-6" />
                  <div className="ml-2">Remove</div>
                </div>
              )}
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div className="flex items-center py-3">
          <div className="ml-auto flex">
            <div className="mr-2">
              <SecondaryBlueButton
                label="Reset"
                onClick={() => handleReset()}
                size="sm"
              />
            </div>
            <PrimaryBlueButton
              label="Save changes"
              onClick={() => {
                handleSubmit();
                setPictureFile(null);
                setReset(false);
                props.setShowProfilePicutreModal(false);
              }}
              size="sm"
            />
          </div>
        </div>
      </Modal.Footer>
    </Modal>
  );
}

function isFileImage(file: File) {
  return file && file["type"].split("/")[0] === "image";
}
