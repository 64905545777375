import React from "react";
import { IconProps } from "./types";

function IconAlphabetical(props: IconProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="25"
      viewBox="0 0 25 25"
      {...props}
    >
      <g transform="translate(.5 .5)">
        <g
          fill="currentColor"
          data-name="Group 914"
          transform="translate(2.5 4.5)"
        >
          <path
            d="M.178 5.092a.767.767 0 001.082.1L4.6 2.407v9.1a.767.767 0 101.534 0v-9.1l3.35 2.785a.768.768 0 10.982-1.182L5.863.174 5.748.105l-.1-.054a.767.767 0 00-.552 0L5 .105l-.119.069L.278 4.01a.767.767 0 00-.1 1.082z"
            data-name="Path 1770"
            transform="rotate(180 9.64 7.204)"
          ></path>
          <g data-name="Group 913">
            <path
              d="M4.7-3.637l-.459-1.516H1.918l-.462 1.516H0l2.249-6.4H3.9l2.258 6.4zm-.785-2.65Q3.278-8.348 3.2-8.619t-.116-.427q-.144.558-.824 2.759z"
              data-name="Path 1813"
              transform="translate(0 10.035)"
            ></path>
            <path
              d="M5.077-3.622H.335V-4.5L3.39-8.878H.418v-1.116h4.576v.872L1.943-4.738h3.134z"
              data-name="Path 1814"
              transform="translate(.541 19.488)"
            ></path>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default IconAlphabetical;
