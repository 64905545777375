import React from "react";
import { IconProps } from "./types";

function IconExpandOutline(props: IconProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      {...props}
    >
      <g data-name="expand-outline">
        <g fill="currentColor">
          <path
            d="M20 5a1 1 0 00-1-1h-5a1 1 0 000 2h2.57l-3.28 3.29a1 1 0 101.42 1.42L18 7.42V10a1 1 0 002 0z"
            data-name="Path 2008"
          ></path>
          <path
            d="M10.71 13.29a1 1 0 00-1.42 0L6 16.57V14a1 1 0 00-2 0v5a1 1 0 001 1h5a1 1 0 000-2H7.42l3.29-3.29a1 1 0 000-1.42z"
            data-name="Path 2009"
          ></path>
        </g>
      </g>
    </svg>
  );
}

export default IconExpandOutline;
