export interface ContactListItem extends ContactListDetails {
  id: string;
  fallbackName: string;
}

export interface ContactListDetails {
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  country: string;
  city: string;
  state: string;
  zipCode: string;
  company: string;
  companyWebsite: string;
  jobTitle: string;
  facebookProfileUrl: string;
  whatsAppProfileUrl: string;
  linkedInProfileUrl: string;
  mobile: string;
  dateCreated: string;
}

export interface ContactKPIItem {
  id: string;
  label: string;
  value: number;
  percentChange: number;
}

export interface SelectedColumn {
  value: keyof ContactListDetails;
  enabled: boolean;
}

export interface Position {
  x: number;
  y: number;
}

export const columnsDict: Record<keyof ContactListDetails, string> = {
  firstName: "First Name",
  lastName: "Last Name",
  email: "Email",
  phone: "Phone",
  mobile: "Mobile",
  country: "Country",
  city: "City",
  state: "State",
  zipCode: "Zip Code",
  company: "Company",
  companyWebsite: "Company Website",
  jobTitle: "Job Title",
  facebookProfileUrl: "Facebook Profile",
  whatsAppProfileUrl: "WhatsApp Profile",
  linkedInProfileUrl: "LinkedIn Profile",
  dateCreated: "Date Created"
};

export const contactProps = Object.keys(
  columnsDict
) as (keyof ContactListDetails)[];

export interface TimelineEvent {
  id: number;
  contactId: number;
  userId: number;
  eventType: string;
  dateCreated: string;
  customDataBlob: string;
}
