import React, {
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState
} from "react";
import AppLoading from "@/components/AppLoading";
import useApi from "@/hooks/useApi";
import Avatar from "@/components/Avatar";
import { PrimaryBlueButton } from "@velaro/velaro-shared/src/components/Buttons/Buttons";
import SearchBar from "@velaro/velaro-shared/src/components/SearchBar";
import IconPlusCircle from "@velaro/velaro-shared/src/icons/IconPlusCircle";
import Pager from "@velaro/velaro-shared/src/components/Pager";
import ContactKPI from "./ContactKPI";
import { useNavigate } from "react-router-dom";
import AddContactModal from "./AddContactModal";
import AddKPIModal from "./AddKPIModal";
import useLocalStorage from "@/hooks/useLocalStorage";
import ColumnDropdown from "./ColumnDropdown";
import format from "date-fns/format";
import { get, set } from "lodash";
import {
  ContactKPIItem,
  ContactListDetails,
  ContactListItem,
  Position,
  SelectedColumn,
  columnsDict,
  contactProps
} from "./types";

const thClassName = "border-b p-4 text-left text-sm font-medium text-slate-800";
const tdClassName = "border-b px-4 py-2 text-sm font-normal text-slate-800";

const defaultSelectedColumns: string[] = [
  "firstName",
  "lastName",
  "email",
  "phone",
  "company",
  "jobTitle"
];

function getDefaultColumns(): SelectedColumn[] {
  return contactProps.map((prop) => ({
    value: prop,
    enabled: defaultSelectedColumns.includes(prop)
  }));
}

export default function Contacts() {
  const [loading, setLoading] = useState(true);
  const [contacts, setContacts] = useState<ContactListItem[]>([]);
  const [searchString, setSearchString] = useState("");
  const [currentPage, setCurrentPage] = React.useState(1);
  const [pageSize, setPageSize] = React.useState(10);
  const [openConversationCount, setOpenConversationCount] = useState(0);
  const [pendingConversationCount, setPendingConversationCount] = useState(0);
  const [resolvedConversationCount, setResolvedConversationCount] = useState(0);
  const [showAddContactModal, setShowAddContactModal] = useState(false);
  const [showAddKpiModal, setShowAddKpiModal] = useState(false);
  const [showColumnDropdown, setShowColumnDropdown] = useState(false);
  const [columnButtonPosition, setColumnButtonPosition] = useState<Position>({
    x: 0,
    y: 0
  });
  const [selectedKPIs, setSelectedKPIs] = useLocalStorage<string[]>(
    "selected_KPIs",
    ["totalContacts", "open", "pending"]
  );
  const [selectedColumns, setSelectedColumns] = useLocalStorage<
    SelectedColumn[]
  >("selected_columns", getDefaultColumns());
  const api = useApi();
  const navigate = useNavigate();
  const ref = useRef<HTMLTableHeaderCellElement>(null);

  useLayoutEffect(() => {
    const handleClick = () => {
      setShowColumnDropdown(false);
    };

    const handleResize = () => {
      const columnButtonRect = ref.current?.getBoundingClientRect();

      setColumnButtonPosition({
        x: columnButtonRect?.x || 0,
        y: columnButtonRect?.y || 0
      });
    };

    document.body.addEventListener("click", handleClick);
    window.addEventListener("resize", handleResize);

    if (!loading) {
      handleResize();
    }
    return () => {
      document.body.removeEventListener("click", handleClick);
      window.removeEventListener("resize", handleResize);
    };
  }, [loading]);

  useEffect(() => {
    async function load() {
      async function fetchContacts() {
        const response = await api.messaging.get("Contacts/List");
        const data = await response.json();
        const contacts: ContactListItem[] = data.contacts;
        const updatedContacts = contacts.map((contact) => {
          return {
            ...contact,
            firstName: !contact.firstName
              ? contact.fallbackName
              : contact.firstName
          };
        });
        setContacts(updatedContacts);
      }

      async function fetchOpenConversationCount() {
        const response = await api.messaging.get("ContactKpi/OpenCount");
        const data = await response.json();
        setOpenConversationCount(data);
      }

      async function fetchPendingConversationCount() {
        const response = await api.messaging.get("ContactKpi/PendingCount");
        const data = await response.json();
        setPendingConversationCount(data);
      }

      async function fetchResolvedConversationCount() {
        const response = await api.messaging.get("ContactKpi/ResolvedCount");
        const data = await response.json();
        setResolvedConversationCount(data);
      }

      await Promise.all([
        fetchContacts(),
        fetchOpenConversationCount(),
        fetchPendingConversationCount(),
        fetchResolvedConversationCount()
      ]);
      setLoading(false);
    }

    load();
  }, [api.messaging]);

  const contactKPIs: ContactKPIItem[] = useMemo(() => {
    return [
      {
        id: "totalContacts",
        label: "Total Contacts",
        value: contacts.length,
        percentChange: 10
      },
      {
        id: "open",
        label: "Open Conversations",
        value: openConversationCount,
        percentChange: 5
      },
      {
        id: "pending",
        label: "Pending Conversations",
        value: pendingConversationCount,
        percentChange: -3
      },
      {
        id: "resolved",
        label: "Resolved Conversations",
        value: resolvedConversationCount,
        percentChange: 0
      }
    ];
  }, [
    contacts.length,
    openConversationCount,
    pendingConversationCount,
    resolvedConversationCount
  ]);

  const filteredKPIs = useMemo(() => {
    return contactKPIs.filter((kpi) => selectedKPIs.includes(kpi.id));
  }, [contactKPIs, selectedKPIs]);

  const filteredContacts = useMemo(
    () =>
      contacts.filter((val) => {
        if (searchString === "") {
          return val;
        } else if (
          val.firstName?.toLowerCase().includes(searchString.toLowerCase()) ||
          val.lastName?.toLowerCase().includes(searchString.toLowerCase()) ||
          val.email?.toLowerCase().includes(searchString.toLowerCase())
        ) {
          return val;
        }
      }),
    [contacts, searchString]
  );

  const startIndex = (currentPage - 1) * pageSize;
  const endIndex = startIndex + pageSize;
  const paginatedContacts = filteredContacts.slice(startIndex, endIndex);

  function formatName(contact: ContactListItem) {
    if (!contact) return "Guest";
    if (contact.firstName && contact.lastName) {
      return contact.firstName + " " + contact.lastName;
    }
    return contact.firstName || contact.lastName || "Guest";
  }

  const enabledColumns = selectedColumns.filter((col) => col.enabled);

  function getColumnValue(column: SelectedColumn, contact: ContactListItem) {
    if (column.value === "dateCreated") {
      return format(new Date(contact.dateCreated), "M/d/yyyy");
    } else {
      return contact[column.value];
    }
  }

  return (
    <>
      {loading && <AppLoading />}
      {!loading && (
        <div className="flex flex-col shrink p-6 text-slate-800 bg-white h-full">
          <div className="flex justify-between mb-12">
            <div className="text-lg font-medium">Contacts</div>
            <PrimaryBlueButton
              label="New Contact"
              onClick={() => setShowAddContactModal(true)}
            />
          </div>
          <div className="flex justify-between space-x-4">
            {filteredKPIs.map((kpi) => (
              <ContactKPI
                key={kpi.label}
                label={kpi.label}
                value={kpi.value}
                percentChange={kpi.percentChange}
              />
            ))}
          </div>
          <div
            className="flex justify-end text-blue-500 text-sm font-medium mt-3 hover: cursor-pointer"
            onClick={() => setShowAddKpiModal(true)}
          >
            Add KPI
          </div>
          <div className="my-6">
            <SearchBar onSearch={setSearchString} placeholderText="Search" />
          </div>
          <div className="overflow-auto flex-shrink border rounded-lg">
            <table className="w-full">
              <thead className="z-50 sticky top-0 bg-white">
                <tr>
                  {/* <th className={thClassName} style={{ width: 1 }}></th> */}
                  <th className="border-b px-1 py-4" style={{ width: 1 }}></th>
                  {enabledColumns.map((column) => (
                    <th key={column.value} className={thClassName}>
                      {columnsDict[column.value]}
                    </th>
                  ))}
                  <th
                    className={`${thClassName} sticky right-0 z-10 bg-white`}
                    style={{ width: 1 }}
                    ref={ref}
                  >
                    <IconPlusCircle
                      className="text-slate-500 hover:text-cornflower-blue-500 hover: cursor-pointer"
                      onClick={(e) => {
                        setShowColumnDropdown(!showColumnDropdown);
                        e.stopPropagation();
                      }}
                    />
                  </th>
                </tr>
              </thead>
              <tbody>
                {paginatedContacts.map((contact) => (
                  <tr
                    className="hover:bg-slate-200 hover:cursor-pointer"
                    onClick={() => navigate(`/contacts/${contact.id}`)}
                    key={contact.id}
                  >
                    {/* <td className={tdClassName}>
                      <input type="checkbox"></input>
                    </td> */}
                    <td className="border-b px-1 py-2">
                      <Avatar
                        url=""
                        name={formatName(contact)}
                        size={"1.5rem"}
                        fontSize={12}
                      />
                    </td>
                    {enabledColumns.map((column) => (
                      <td key={column.value} className={tdClassName}>
                        {getColumnValue(column, contact)}
                      </td>
                    ))}
                    <td className={`${tdClassName} sticky right-0 z-10`}></td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <Pager
            currentPage={currentPage}
            setPage={setCurrentPage}
            setPageSize={setPageSize}
            pageSize={pageSize}
            totalRecords={filteredContacts.length}
          />
        </div>
      )}
      {showAddContactModal && (
        <AddContactModal onClose={() => setShowAddContactModal(false)} />
      )}
      {showAddKpiModal && (
        <AddKPIModal
          onClose={() => setShowAddKpiModal(false)}
          selectedKPIs={selectedKPIs}
          setSelectedKPIs={setSelectedKPIs}
          contactKPIs={contactKPIs}
        />
      )}
      {showColumnDropdown && (
        <div
          className="absolute z-50"
          style={{
            left: columnButtonPosition.x - 160,
            top: columnButtonPosition.y + 50
          }}
          onClick={(e) => e.stopPropagation()}
        >
          <ColumnDropdown
            selectedColumns={selectedColumns}
            setSelectedColumns={setSelectedColumns}
          />
        </div>
      )}
    </>
  );
}
