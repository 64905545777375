import * as React from "react";
import DesktopLayout from "./DesktopLayout";
import MobileLayout from "./MobileLayout";
import useIsMobile from "@/hooks/useIsMobile";

interface Props {
  children: React.ReactNode;
  headerActions?: React.ReactNode;
}

export default function MainLayout(props: Props) {
  const isMobile = useIsMobile();

  const initialValue = [
    {
      type: "paragraph",
      children: [{ text: "Example Text" }]
    }
  ];

  if (isMobile) {
    return <MobileLayout {...props} />;
  }

  return <DesktopLayout {...props} />;
}
