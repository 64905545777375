import useProfile from "@/hooks/useProfile";
import useTranslations from "@/hooks/useTranslations";
import IconGeneralSettings from "@/icons/IconGeneralSettings";
import IconHelp from "@/icons/IconHelp";
import IconNotificationSettings from "@/icons/IconNotificationsSettings";
import IconPerson from "@/icons/IconPerson";
import IconSecurityLock from "@/icons/IconSecurityLock";
import IconTinyCamera from "@/icons/IconTinyCamera";
import React, { useRef } from "react";

interface Props {
  selectedPage: string;
  setSelectedPage(
    selectedPage: "basic" | "notification" | "security" | "help" | "preferences"
  ): void;
  setShowAvatarModal(show: boolean): void;
}

export default function ProfileMenu(props: Props) {
  const { profile } = useProfile();
  const profilePictureInput = useRef<HTMLInputElement>(null);
  const translation = useTranslations();

  function getClassName(page: string) {
    if (page == props.selectedPage) {
      return "flex items-center hover:text-cornflower-blue-500 hover:bg-slate-gray-200 rounded hover:cursor-pointer text-cornflower-blue-500 h-12";
    } else {
      return "flex items-center hover:text-cornflower-blue-500 hover:bg-slate-gray-200 rounded hover:cursor-pointer h-12";
    }
  }

  function getBorderColor(page: string) {
    if (page == props.selectedPage) {
      return "bg-cornflower-blue-500 border-cornflower-blue-500 w-1 rounded-r border-r-2 h-full";
    } else {
      return "bg-transparent border-transparent w-1 rounded-r border-r-2 h-full";
    }
  }

  return (
    <div className="bg-white w-1/5 rounded h-[300px]">
      <div className="relative flex justify-center pt-8">
        <div className="relative">
          <img
            className="h-16 w-16 rounded-full border"
            src={profile.picture}
            alt=""
          />
          <div
            onClick={(e) => {
              e.stopPropagation();
              props.setShowAvatarModal(true);
            }}
            id="test"
            className="absolute bottom-0 right-0 z-10 cursor-pointer"
          >
            <IconTinyCamera />
          </div>
        </div>
      </div>
      <div className="flex justify-center pt-2">
        <div className="font-semibold truncate w-[calc(100%-40px)] text-center">
          {profile.displayName}
        </div>
      </div>
      <div className="flex justify-center">
        <hr className="my-2 border-gray-200 w-[calc(100%-50px)]" />
      </div>
      <div
        className={getClassName("basic")}
        onClick={() => props.setSelectedPage("basic")}
      >
        <div className={getBorderColor("basic")}></div>
        <div className="mr-2 p-2">
          <IconPerson />
        </div>
        <div className="ml-4">{translation.key("basic_info")}</div>
      </div>
      <div>
        <div
          className={getClassName("notification")}
          onClick={() => props.setSelectedPage("notification")}
        >
          <div className={getBorderColor("notification")}></div>
          <div className="mr-2 p-2">
            <IconNotificationSettings />
          </div>
          <div className="ml-4">{translation.key("notification_settings")}</div>
        </div>
      </div>
      <div>
        <div
          className={getClassName("preferences")}
          onClick={() => props.setSelectedPage("preferences")}
        >
          <div className={getBorderColor("preferences")}></div>
          <div className="mr-2 p-2">
            <IconGeneralSettings />
          </div>
          <div className="ml-4">{translation.key("preferences")}</div>
        </div>
      </div>
      {/* <div>
        <div
          className={getClassName("security")}
          onClick={() => props.setSelectedPage("security")}
        >
          <div className={getBorderColor("security")}></div>
          <div className="mr-2 p-2">
            <IconSecurityLock />
          </div>
          <div className="ml-4">Security</div>
        </div>
      </div> */}
      {/* <div>
        <div
          className={getClassName("help")}
          onClick={() => props.setSelectedPage("help")}
        >
          <div className={getBorderColor("help")}></div>
          <div className="mr-2 p-2">
            <IconHelp />
          </div>
          <div className="ml-4">Help and support</div>
        </div>
      </div> */}
    </div>
  );
}
