import React from "react";
import {
  ContactMessageGroupType,
  UserMessageGroupType
} from "@/reducers/messageGroupReducer";
import Avatar from "@/components/Avatar";
import SafeMarkdown from "@/components/SafeMarkdown";
import FormattedTime from "./FormattedTime";
import { ConversationSource } from "@/types";
import MessageItem from "./MessageItem";
import MessageAttachments from "./MessageAttachments";
import { format } from "date-fns";
import FormattedDate from "./FormattedDate";

interface Props {
  item: ContactMessageGroupType | UserMessageGroupType;
  conversationSource: ConversationSource;
  onImageLoad(): void;
}

export default function StandardMessageGroup(props: Props) {
  return (
    <div className="flex flex-col justify-start items-start w-full overflow-hidden ">
      {props.item.messages.map((message) => (
        <div
          key={message.id}
          className="flex flex-row justify-start items-start w-full overflow-hidden py-4 "
        >
          {message.contact && (
            <div className="mr-4 h-10 flex flex-col-reverse">
              <Avatar
                name={message.contact.displayName}
                url={message.contact.picture}
                size={40}
              />
            </div>
          )}
          {message.user && (
            <div className="mr-4 h-10 flex flex-col-reverse">
              <Avatar
                name={message.user.name}
                url={message.user.picture}
                size={40}
              />
            </div>
          )}
          <div className="flex-1 overflow-hidden">
            <div
              key={message.id}
              className="flex flex-col items-start max-w-full"
            >
              {message.text && (
                <MessageItem className="pr-24">
                  <div className=" dark:bg-stone-200 dark:text-slate-gray-100 rounded-md rounded-bl-2xl ">
                    <div className="flex text-slate-gray-500 text-sm font-normal font-['Rubik']">
                      {message.contact && (
                        <div className="mr-2 text-sm">
                          {message.contact.displayName}
                        </div>
                      )}
                      {message.user != null && (
                        <div className="mr-2">{message.user.name}</div>
                      )}
                      <div className="mr-2">
                        <FormattedDate isoDate={message.timestamp} />
                      </div>
                      <div>
                        <FormattedTime isoDate={message.timestamp} />
                      </div>
                    </div>
                    <div className="whitespace-pre-wrap break-words text-sm font-normal text-slate-gray-800">
                      <SafeMarkdown text={message.text} />
                    </div>
                  </div>
                </MessageItem>
              )}
              <MessageAttachments
                attachments={message.attachments}
                onImageLoad={props.onImageLoad}
              />
            </div>
          </div>
        </div>
      ))}
    </div>
  );
}
