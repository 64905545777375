import {
  ConversationListItem,
  InboxSortBy,
  InboxContent,
  InboxTab,
  InboxTabs,
  InboxSettings,
  Contact
} from "@/types";

import cloneDeep from "lodash/cloneDeep";

export type SetTabsAction = {
  type: "SET_TABS";
  tabs: InboxTabs;
};

export type ConversationAssignedAction = {
  type: "CONVERSATION_ASSIGNED";
  wasMine: boolean;
  isMine: boolean;
  wasUnassigned: boolean;
  conversation: ConversationListItem;
  settings: InboxSettings;
};

export type ConversationUnassignedAction = {
  type: "CONVERSATION_UNASSIGNED";
  wasMine: boolean;
  conversation: ConversationListItem;
  settings: InboxSettings;
};

export type ConversationCreatedAction = {
  type: "CONVERSATION_CREATED";
  isMine: boolean;
  conversation: ConversationListItem;
  settings: InboxSettings;
};

export type ConversationResolvedAction = {
  type: "CONVERSATION_RESOLVED";
  conversation: ConversationListItem;
  isMine: boolean;
  isUnassigned: boolean;
  settings: InboxSettings;
};

export type ConversationReopenedAction = {
  type: "CONVERSATION_REOPENED";
  conversation: ConversationListItem;
  isMine: boolean;
  isUnassigned: boolean;
  settings: InboxSettings;
};

export type ReceiveMessageAction = {
  type: "RECEIVE_MESSAGE";
  id: number;
  lastMessageText: string;
  lastMessageTimestamp: string;
};

export type ContactUpdateAction = {
  type: "CONTACT_UPDATE";
  contact: Contact;
};

export type UnassignWarningAction = {
  type: "UNASSIGN_WARNING";
  conversation: ConversationListItem;
};

export type AutomationDoneAction = {
  type: "AUTOMATION_DONE";
  conversation: ConversationListItem;
};

export type InboxAction =
  | SetTabsAction
  | ConversationAssignedAction
  | ConversationUnassignedAction
  | ConversationCreatedAction
  | ConversationResolvedAction
  | ConversationReopenedAction
  | ReceiveMessageAction
  | ContactUpdateAction
  | UnassignWarningAction
  | AutomationDoneAction;

export default function inboxReducer(
  state: InboxContent,
  action: InboxAction
): InboxContent {
  switch (action.type) {
    case "SET_TABS": {
      return { ...state, tabs: action.tabs };
    }
    case "CONVERSATION_UNASSIGNED": {
      return handleConversationUnassigned(state, action);
    }
    case "CONVERSATION_ASSIGNED": {
      return handleConversationAssigned(state, action);
    }
    case "CONVERSATION_CREATED": {
      return handleConversationCreated(state, action);
    }
    case "CONVERSATION_RESOLVED": {
      return handleConversationResolved(state, action);
    }
    case "CONVERSATION_REOPENED": {
      return handleConversationReopened(state, action);
    }
    case "RECEIVE_MESSAGE": {
      return handleReceiveMessage(state, action);
    }
    case "CONTACT_UPDATE": {
      return handleContactUpdate(state, action);
    }
    case "UNASSIGN_WARNING": {
      return handleUnassignWarning(state, action);
    }
    case "AUTOMATION_DONE": {
      return handleAutomationDone(state, action);
    }
    default: {
      throw new Error(`Unsupported action type: ${(action as any).type}`);
    }
  }
}

function handleReceiveMessage(
  state: InboxContent,
  action: ReceiveMessageAction
): InboxContent {
  state = cloneDeep(state);

  const fn = (conversation: ConversationListItem) => {
    if (conversation.id === action.id) {
      conversation.lastMessageText = action.lastMessageText;
      conversation.lastMessageTimestamp = action.lastMessageTimestamp;
    }
  };

  state.tabs.all.conversations.forEach(fn);
  //state.tabs.incoming.conversations.forEach(fn);
  state.tabs.mine.conversations.forEach(fn);

  return state;
}

function handleConversationCreated(
  state: InboxContent,
  action: ConversationCreatedAction
): InboxContent {
  const statusFilter = action.settings.filters.status;
  if (statusFilter.length > 0 && statusFilter.indexOf("Open") === -1) {
    //don't add to inbox if status filter is active and doesn't include "Open"
    return state;
  }

  state = cloneDeep(state);

  if (action.isMine) {
    insertConversation(
      state.tabs.mine,
      action.conversation,
      action.settings.sortBy
    );
  } else {
    // insertConversation(
    //   state.tabs.incoming,
    //   action.conversation,
    //   action.settings.sortBy
    // );
  }
  insertConversation(
    state.tabs.all,
    action.conversation,
    action.settings.sortBy
  );

  return state;
}

function insertConversation(
  tab: InboxTab,
  conversation: ConversationListItem,
  sortBy: InboxSortBy
) {
  tab.count++;
  let ascending = true;
  let dateParam = true;
  let sortParam: "startTimestamp" | "lastMessageTimestamp" | "assignedUserId" =
    "startTimestamp";
  switch (sortBy) {
    case "newest":
      sortParam = "startTimestamp";
      break;
    case "oldest":
      ascending = false;
      sortParam = "startTimestamp";
      break;
    case "longest":
      sortParam = "lastMessageTimestamp";
      break;
    case "agent":
      dateParam = false;
      sortParam = "assignedUserId";
      break;
    default:
      sortParam = "startTimestamp";
      break;
  }

  if (tab.conversations.findIndex((con) => con.id === conversation.id) !== -1) {
    console.warn("Conversation already exists in tab.");
    return;
  }

  let index = 0;
  if (dateParam) {
    index = findIndexbyDate(
      tab.conversations,
      conversation,
      sortParam,
      ascending
    );
  } else {
    index = tab.conversations.findIndex((con) => {
      const conversationParam = conversation[sortParam] || "";
      const conParam = con[sortParam] || "";
      if (ascending) {
        return conversationParam > conParam;
      } else {
        return conversationParam < conParam;
      }
    });
  }

  if (index === -1) {
    tab.conversations.push(conversation);
  } else {
    tab.conversations.splice(index, 0, conversation);
  }
}

function findIndexbyDate(
  conversations: ConversationListItem[],
  conversation: ConversationListItem,
  sortParam: "startTimestamp" | "lastMessageTimestamp" | "assignedUserId",
  ascending: boolean
) {
  const sortProp = sortParam;
  const index = conversations.findIndex((con) => {
    const conversationParam = conversation[sortProp] || "";
    const conParam = con[sortProp] || "";
    if (ascending) {
      return new Date(conversationParam) > new Date(conParam);
    } else {
      return new Date(conversationParam) < new Date(conParam);
    }
  });
  return index;
}

function removeConversation(tab: InboxTab, conversationBaseId: number) {
  tab.count--;
  tab.conversations = tab.conversations.filter(
    (conversation) => conversation.id !== conversationBaseId
  );
}

function handleConversationAssigned(
  state: InboxContent,
  action: ConversationAssignedAction
): InboxContent {
  const assignmentFilter = action.settings.filters.assignment;
  if (
    assignmentFilter.length > 0 &&
    assignmentFilter.indexOf("Assigned") === -1
  ) {
    //don't add to inbox if assignment filter is active and doesn't include "Assigned"
    return state;
  }

  state = cloneDeep(state);

  if (action.wasMine) {
    removeConversation(state.tabs.mine, action.conversation.id);
  }

  if (action.isMine) {
    insertConversation(
      state.tabs.mine,
      action.conversation,
      action.settings.sortBy
    );
  }

  const convo = state.tabs.all.conversations.find(
    (x) => x.id == action.conversation.id
  );
  if (convo) {
    convo.assignedUserId = action.conversation.assignedUserId;
  }

  if (action.wasUnassigned) {
    //removeConversation(state.tabs.incoming, action.conversation.id);
  }

  return state;
}

function handleConversationUnassigned(
  state: InboxContent,
  action: ConversationUnassignedAction
): InboxContent {
  state = cloneDeep(state);

  if (action.wasMine) {
    removeConversation(state.tabs.mine, action.conversation.id);
  }

  const convo = state.tabs.all.conversations.find(
    (x) => x.id == action.conversation.id
  );
  if (convo) {
    convo.unassignWarningTimestamp =
      action.conversation.unassignWarningTimestamp;
    convo.assignedUserId = action.conversation.assignedUserId;
  }

  // insertConversation(
  //   //state.tabs.incoming,
  //   state.tabs.all,
  //   action.conversation,
  //   action.settings.sortBy
  // );

  return state;
}

function handleConversationResolved(
  state: InboxContent,
  action: ConversationResolvedAction
): InboxContent {
  const statusFilter = action.settings.filters.status;
  if (statusFilter.length > 0 && statusFilter.indexOf("Open") !== -1) {
    //don't add to inbox if status filter is active and doesn't include "Open"
    return handleConversationResolvedOpen(state, action);
  }

  if (statusFilter.length > 0 && statusFilter.indexOf("Resolved") !== -1) {
    return handleConversationResolvedClosed(state, action);
  }

  return state;
}

/**
 * This will handle state when the inbox filter is set to "open".
 */

function handleConversationResolvedOpen(
  state: InboxContent,
  action: ConversationResolvedAction
): InboxContent {
  state = cloneDeep(state);

  if (action.isMine) {
    state.tabs.mine.count--;
    state.tabs.mine.conversations = state.tabs.mine.conversations.filter(
      (conversation) => conversation.id !== action.conversation.id
    );
  }

  // if (action.isUnassigned) {
  //   state.tabs.incoming.count--;
  //   state.tabs.incoming.conversations = state.tabs.incoming.conversations.filter(
  //     (conversation) => conversation.id !== action.conversation.id
  //   );
  // }

  state.tabs.all.count--;
  state.tabs.all.conversations = state.tabs.all.conversations.filter(
    (conversation) => conversation.id !== action.conversation.id
  );

  return state;
}

/**
 * This will handle state when the inbox filter is set to "closed".
 */

function handleConversationResolvedClosed(
  state: InboxContent,
  action: ConversationResolvedAction
): InboxContent {
  state = cloneDeep(state);

  if (action.isMine) {
    state.tabs.mine.count++;
    state.tabs.mine.conversations.push(action.conversation);
  }

  // if (action.isUnassigned) {
  //   state.tabs.incoming.count++;
  //   state.tabs.incoming.conversations.push(action.conversation);
  // }

  state.tabs.all.count++;
  state.tabs.all.conversations.push(action.conversation);

  return state;
}

function handleConversationReopened(
  state: InboxContent,
  action: ConversationReopenedAction
): InboxContent {
  const statusFilter = action.settings.filters.status;
  if (statusFilter.length > 0 && statusFilter.indexOf("Open") !== -1) {
    //don't add to inbox if status filter is active and doesn't include "Open"
    return handleConversationReopenedOpen(state, action);
  }

  if (statusFilter.length > 0 && statusFilter.indexOf("Resolved") !== -1) {
    return handleConversationReopenedClosed(state, action);
  }

  return state;
}

/**
 * This will handle state when the inbox filter is set to "open".
 */

function handleConversationReopenedOpen(
  state: InboxContent,
  action: ConversationReopenedAction
): InboxContent {
  state = cloneDeep(state);

  if (action.isMine) {
    state.tabs.mine.count++;
    state.tabs.mine.conversations.push(action.conversation);
  }

  // if (action.isUnassigned) {
  //   state.tabs.incoming.count++;
  //   state.tabs.incoming.conversations.push(action.conversation);
  // }

  state.tabs.all.count++;
  state.tabs.all.conversations.push(action.conversation);

  return state;
}

/**
 * This will handle state when the inbox filter is set to "closed".
 */

function handleConversationReopenedClosed(
  state: InboxContent,
  action: ConversationReopenedAction
): InboxContent {
  state = cloneDeep(state);

  if (action.isMine) {
    state.tabs.mine.count--;
    state.tabs.mine.conversations = state.tabs.mine.conversations.filter(
      (conversation) => conversation.id !== action.conversation.id
    );
  }

  // if (action.isUnassigned) {
  //   state.tabs.incoming.count--;
  //   state.tabs.incoming.conversations = state.tabs.incoming.conversations.filter(
  //     (conversation) => conversation.id !== action.conversation.id
  //   );
  // }

  state.tabs.all.count--;
  state.tabs.all.conversations = state.tabs.all.conversations.filter(
    (conversation) => conversation.id !== action.conversation.id
  );

  return state;
}

function handleContactUpdate(
  state: InboxContent,
  action: ContactUpdateAction
): InboxContent {
  state = cloneDeep(state);

  function updateTab(tab: InboxTab) {
    const contact = tab.conversations.find(
      (x) => x.contactId == action.contact.id
    );

    if (contact) {
      contact.name = action.contact.name;
    }
  }

  updateTab(state.tabs.mine);
  // updateTab(state.tabs.incoming);
  updateTab(state.tabs.all);

  return state;
}

function handleUnassignWarning(
  state: InboxContent,
  action: UnassignWarningAction
): InboxContent {
  state = cloneDeep(state);

  function updateTab(tab: InboxTab) {
    const convo = tab.conversations.find((x) => x.id == action.conversation.id);
    if (convo) {
      convo.unassignWarningTimestamp =
        action.conversation.unassignWarningTimestamp;
    }
  }

  updateTab(state.tabs.mine);
  // updateTab(state.tabs.incoming);
  updateTab(state.tabs.all);

  return state;
}

function handleAutomationDone(
  state: InboxContent,
  action: AutomationDoneAction
): InboxContent {
  state = cloneDeep(state);

  function updateTab(tab: InboxTab) {
    const convo = tab.conversations.find((x) => x.id == action.conversation.id);
    if (convo) {
      convo.automationJobStatus = action.conversation.automationJobStatus;
    }
  }

  updateTab(state.tabs.mine);
  // updateTab(state.tabs.incoming);
  updateTab(state.tabs.all);

  return state;
}
