import React from "react";
import { IconProps } from "./types";

function IconLogOut(props: IconProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      {...props}
    >
      <path fill="none" d="M0 0H24V24H0z" data-name="Rectangle 420"></path>
      <g fill="currentColor" data-name="Group 96" transform="translate(2 3)">
        <path
          d="M7.529 6.353a1.176 1.176 0 100-2.353H5.176A1.176 1.176 0 004 5.176v16.468a1.176 1.176 0 001.176 1.176h2.353a1.176 1.176 0 100-2.353H6.353V6.353z"
          data-name="Path 225"
          transform="translate(-4 -4)"
        ></path>
        <path
          d="M22.9 12.2l-3.313-4.706a1.177 1.177 0 10-1.917 1.364l2.023 2.847h-9.517a1.176 1.176 0 000 2.353h9.41l-2.117 2.823a1.176 1.176 0 101.882 1.412l3.529-4.705a1.176 1.176 0 00.02-1.388z"
          data-name="Path 226"
          transform="translate(-3.119 -3.471)"
        ></path>
      </g>
    </svg>
  );
}

export default IconLogOut;
