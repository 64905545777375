import * as React from "react";
import LoadingSpinner from "@velaro/velaro-shared/src/components/LoadingSpinner";
import { Conversation, CrmConfigDetail } from "@/types";
import ContactPanel from "./ContactPanel";
import ConversationDetailsPanel from "./ConversationDetailsPanel";
import NetSuitePanel from "./NetSuitePanel/NetSuitePanel";
import { useCallback, useEffect, useState } from "react";
import Logo from "@velaro/velaro-shared/src/images/logo_color.png";
import NetSuiteEmblem from "@velaro/velaro-shared/src/images/NetSuiteEmblem.png";
import AsideViewTab from "./AsideViewTab";
import useApi from "@/hooks/useApi";
import SurveySubmissions from "./SurveySubmissions";
import useToastAlert from "@/hooks/useToastAlert";
import { useResizeFrame } from "@/hooks/useResizeFrame";
import { Severity } from "@velaro/velaro-shared/src/components/ToastAlert";
import useTranslations from "@/hooks/useTranslations";

interface Props {
  conversation: Conversation;
  updateConversation(conversation: Conversation): void;
}

type tab = "Contact" | "NetSuite";

export default function RightAside(props: Props) {
  const [selectedTab, setSelectedTab] = useState<tab>("Contact");
  const [crmConfigs, setCrmConfigs] = useState<CrmConfigDetail[]>([]);
  const api = useApi();
  const toastAlert = useToastAlert();
  const translation = useTranslations();

  const { width, onMouseDown } = useResizeFrame("left", 400);

  useEffect(() => {
    async function fetchCrmConfigs() {
      const resp = await api.messaging.get("Integration/Crms");

      if (!resp.ok) {
        toastAlert.displayToast(
          Severity.Error,
          "an unexpected error occurred while fetching crm configs"
        );
        return;
      }

      setCrmConfigs((await resp.json()) as CrmConfigDetail[]);
    }

    fetchCrmConfigs();
  }, [api.messaging, toastAlert]);

  const renderTabContent = useCallback(() => {
    //there is a the foundation started for a CRM tab that would have a dropdown to select the CRM
    //look at CrmPanel.tsx
    let panels = [];
    switch (selectedTab) {
      case "Contact":
        panels = [
          <ContactPanel
            key={0}
            contactUpdated={(contact) =>
              props.updateConversation({
                ...props.conversation,
                contact: contact
              })
            }
            contact={props.conversation.contact}
          />,
          <ConversationDetailsPanel
            key={1}
            conversationBaseId={props.conversation.id}
          />,
          <SurveySubmissions
            key={2}
            conversationBaseId={props.conversation.id}
          />
        ];
        break;
      case "NetSuite":
        panels = [<NetSuitePanel key={0} conversation={props.conversation} />];
        break;
    }

    return panels.map((panel, i) => (
      <div key={i} className="border-b-2 h-full border-gray-200">
        {panel}
      </div>
    ));
  }, [props, selectedTab]);

  if (!props.conversation.contact || !props.conversation.contact.id) {
    return (
      <div className="flex mt-16 items-center justify-center">
        <LoadingSpinner />
      </div>
    );
  }

  //using crmConfigs to know if tabs are needed. will need to add additional logic if non-crm tabs are added to app
  return (
    <>
      <div
        onMouseDown={onMouseDown}
        className="h-full w-[2px] border-l-2 cursor-col-resize flex-grow"
      ></div>
      <div
        style={{ width: width + "px" }}
        className="right-aside-container w-96 flex-none overflow-auto bg-white"
      >
        <div className="flex flex-col w-full bg-white dark:border-stone-100">
          <div className="flex">
            <AsideViewTab
              label={translation.key("contact")}
              logo={Logo}
              selected={selectedTab === "Contact"}
              onSelect={() => setSelectedTab("Contact")}
            />
            <AsideViewTab
              label="NetSuite"
              logo={NetSuiteEmblem}
              selected={selectedTab === "NetSuite"}
              onSelect={() => setSelectedTab("NetSuite")}
            />
          </div>
          {renderTabContent()}
        </div>
      </div>
    </>
  );
}
