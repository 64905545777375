import { SurveySubmission } from "@/types";
import { useEffect, useState } from "react";
import useApi from "./useApi";
import useToastAlert from "@/hooks/useToastAlert";
import { Severity } from "@velaro/velaro-shared/src/components/ToastAlert";

export default function useSurveySubmissions(conversationBaseId: number) {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState<SurveySubmission[]>([]);
  const api = useApi();
  const toastAlert = useToastAlert();

  useEffect(() => {
    async function fetch() {
      const resp = await api.messaging.get(
        `SurveySubmissions/list?conversationBaseId=${conversationBaseId}`
      );

      if (resp.ok) {
        setData(await resp.json());
      } else {
        console.error("error loading conversation notes");
        toastAlert.displayToast(
          Severity.Error,
          "An unexpected error occurred while loading conversation notes"
        );
      }
      setLoading(false);
    }

    fetch();
  }, [api.messaging, conversationBaseId, toastAlert]);

  return {
    data,
    loading
  };
}
