import { MessageAttachment } from "@/types";
import React from "react";

interface Props {
  file: MessageAttachment;
}

export default function MessageFileItem({ file }: Props) {
  return (
    <div className="border rounded text-center">
      {/* todo: parse the file name out of the url */}
      {/* sample: https://cdn.fbsbx.com/v/t59.2708-21/11109455_1656521941247217_608746469_n.txt/test.txt?_nc_cat=104&ccb=3&_nc_sid=0cab14&_nc_ohc=dZMRNKlTu-oAX-KuuFN&_nc_ht=cdn.fbsbx.com&oh=fd9c1df26bb09c523b152b9ea32480a1&oe=60411666 */}
      <div className="p-3">
        <i className="fa fa-file mr-1" /> {file.fileName ?? "unknown file name"}
      </div>
      <a
        href={file.url}
        className="block border-t p-3 text-center text-cornflower-blue-500 hover:bg-slate-gray-50"
      >
        <span className="ml-2">Download</span>
      </a>
    </div>
  );
}
