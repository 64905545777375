import React from "react";
import AppLoading from "@/components/AppLoading";
import useAuth from "@/hooks/useAuth";
import { Outlet } from "react-router-dom";
import ConnectionProvider from "@/providers/ConnectionProvider";
import InboxProvider from "@/providers/InboxProvider";
import NotificationProvider from "@/providers/NotificationProvider";
import ProfileProvider from "@/providers/ProfileProvider";
import { RoleListItemsProvider } from "@/providers/RoleListItemsProvider";
import StatusProvider from "@/providers/StatusProvider";
import { TeamListItemsProvider } from "@/providers/TeamListItemsProvider";
import ToastAlertProvider from "@/providers/ToastAlertProvider";
import UnreadMessagesProvider from "@/providers/UnreadMessagesProvider";
import TranslationProvider from "@/providers/TranslationProvider";
import MainLayout from "@/pages/Shared/MainLayout";
import { SkillListItemsProvider } from "@/providers/SkillsProvider";
import { SubscriptionsProvider } from "@/providers/SubscriptionsProvider";

export default function PrivateRoute(props: any) {
  const { component, ...rest } = props;
  const auth = useAuth();

  if (auth.isAuthenticated()) {
    return (
      <ProfileProvider>
        <StatusProvider>
          <ConnectionProvider>
            <NotificationProvider>
              <InboxProvider>
                <UnreadMessagesProvider>
                  <RoleListItemsProvider>
                    <ToastAlertProvider>
                      <TranslationProvider>
                        <TeamListItemsProvider>
                          <SkillListItemsProvider>
                            <SubscriptionsProvider>
                              <MainLayout>
                                <Outlet />
                              </MainLayout>
                            </SubscriptionsProvider>
                          </SkillListItemsProvider>
                        </TeamListItemsProvider>
                      </TranslationProvider>
                    </ToastAlertProvider>
                  </RoleListItemsProvider>
                </UnreadMessagesProvider>
              </InboxProvider>
            </NotificationProvider>
          </ConnectionProvider>
        </StatusProvider>
      </ProfileProvider>
    );
  } else {
    auth.loginRedirect();
    return <AppLoading />;
  }
}
