import { EventEmitter } from "events";

// The need for this emitter is complicated. SignalR appeared to
// have major issues emitting events while callback collections
// were being modified by subscribe/unusubscribe events. I believe
// I found a bug where the reordering of the internal array within
// the hub was causing events to not fire which was obviously
// problematic. Either way, this emitter gives us the opportunity
// to register all events and types in `eventMap.ts` which is a
// good quality of life improvement.

const emitter = new EventEmitter();

export default emitter;
