import { Contact } from "@/types";
import FormValidator from "@velaro/velaro-shared/src/components/FormValidator";
import React, { useEffect } from "react";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import flags from "react-phone-number-input/flags";
import useApi from "@/hooks/useApi";

const inputClass =
  "input w-full placeholder-slate-300 text-sm font-normal rounded-lg";

interface Props {
  contact: Contact;
  errors: Record<string, string>;
  handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  setContact: (contact: Contact) => void;
}

interface CountryModel {
  countryId: string;
  countryName: string;
}

export default function ContactForm({
  contact,
  errors,
  handleChange,
  setContact
}: Props) {
  const [countries, setCountries] = React.useState<CountryModel[]>([]);
  const api = useApi();

  useEffect(() => {
    async function fetchCountries() {
      const response = await api.messaging.get("Countries");
      setCountries(await response.json());
    }
    fetchCountries();
  }, [api.messaging]);

  return (
    <div className="grid grid-cols-3 gap-4">
      <div>
        <label className="text-sm font-medium">Unique ID</label>
        <div className="mb-6">
          <input
            id="uniqueId"
            name="uniqueId"
            placeholder="Enter unique identification"
            type="text"
            className={inputClass}
            autoFocus
            value={contact.uniqueId || ""}
            onChange={handleChange}
          />
        </div>
        <label className="text-sm font-medium">Email Address</label>
        <div className="mb-6">
          <FormValidator error={errors["Email"]}>
            <input
              id="email"
              name="email"
              placeholder="Enter email address"
              type="text"
              className={inputClass}
              value={contact.email || ""}
              onChange={handleChange}
            />
          </FormValidator>
        </div>
        <label className="text-sm font-medium">Address</label>
        <div className="mb-6">
          <input
            id="address"
            name="address"
            placeholder="Enter address details"
            type="text"
            className={inputClass}
            value={contact.address || ""}
            onChange={handleChange}
          />
        </div>
        <label className="text-sm font-medium">Company</label>
        <div className="mb-6">
          <input
            id="company"
            name="company"
            placeholder="Enter company"
            type="text"
            className={inputClass}
            value={contact.company || ""}
            onChange={handleChange}
          />
        </div>
        <label className="text-sm font-medium">Facebook Profile URL</label>
        <div className="mb-6">
          <input
            id="facebookProfileUrl"
            name="facebookProfileUrl"
            placeholder="Enter Facebook profile URL"
            type="text"
            className={inputClass}
            value={contact.facebookProfileUrl || ""}
            onChange={handleChange}
          />
        </div>
      </div>
      <div>
        <label className="text-sm font-medium">First Name</label>
        <div className="mb-6">
          <input
            id="firstName"
            name="firstName"
            placeholder="Enter first name"
            type="text"
            className={inputClass}
            value={contact.firstName || ""}
            onChange={handleChange}
          />
        </div>
        <label className="text-sm font-medium">Telephone</label>
        <div className="mb-6">
          <PhoneInput
            placeholder="(555) 000-0000"
            flags={flags}
            country={"us"}
            className="input mb-6 block w-full phone-input focus-visible:outline-none focus-visible:border-cornflower-blue-600 hover:outline-none"
            value={contact.phone || ""}
            onChange={() => {}}
            defaultCountry={"US"}
          />
        </div>
        <label className="text-sm font-medium">ZIP Code</label>
        <div className="mb-6">
          <FormValidator error={errors["ZipCode"]}>
            <input
              id="zipCode"
              name="zipCode"
              placeholder="Enter ZIP code"
              type="text"
              className={inputClass}
              value={contact.zipCode || ""}
              onChange={handleChange}
            />
          </FormValidator>
        </div>
        <label className="text-sm font-medium">Company Website</label>
        <div className="mb-6">
          <input
            id="companyWebsite"
            name="companyWebsite"
            placeholder="Enter company website"
            type="text"
            className={inputClass}
            value={contact.companyUrl || ""}
            onChange={handleChange}
          />
        </div>
        <label className="text-sm font-medium">WhatsApp Profile URL</label>
        <div className="mb-6">
          <input
            id="whatsAppProfileUrl"
            name="whatsAppProfileUrl"
            placeholder="Enter WhatsApp profile URL"
            type="text"
            className={inputClass}
            value={contact.whatsAppProfileUrl || ""}
            onChange={handleChange}
          />
        </div>
      </div>
      <div>
        <label className="text-sm font-medium">Last Name</label>
        <div className="mb-6">
          <input
            id="lastName"
            name="lastName"
            placeholder="Enter last name"
            type="text"
            className={inputClass}
            value={contact.lastName || ""}
            onChange={handleChange}
          />
        </div>
        <label className="text-sm font-medium">Mobile Number</label>
        <div className="mb-6">
          <PhoneInput
            placeholder="+1 (555) 000-0000"
            flags={flags}
            country={"us"}
            className="input mb-6 block w-full phone-input focus-visible:outline-none focus-visible:border-cornflower-blue-600 hover:outline-none"
            value={contact.phone || ""}
            onChange={() => {}}
            defaultCountry={"US"}
          />
        </div>
        <label className="text-sm font-medium">Country</label>
        <div className="mb-6">
          <select
            className={inputClass}
            onChange={(e) =>
              setContact({ ...contact, country: e.currentTarget.value })
            }
            value={contact.country || ""}
          >
            <option value="" disabled>
              Select country
            </option>
            {countries?.map((country, i) => (
              <option key={i} value={country.countryId}>
                {country.countryName}
              </option>
            ))}
          </select>
        </div>
        <label className="text-sm font-medium">Job Title</label>
        <div className="mb-6">
          <input
            id="jobTitle"
            name="jobTitle"
            placeholder="Enter job title"
            type="text"
            className={inputClass}
            value={contact.jobTitle || ""}
            onChange={handleChange}
          />
        </div>
        <label className="text-sm font-medium">LinkedIn Profile URL</label>
        <div className="mb-6">
          <input
            id="linkedInProfileUrl"
            name="linkedInProfileUrl"
            placeholder="Enter LinkedIn profile URL"
            type="text"
            className={inputClass}
            value={contact.linkedInProfileUrl || ""}
            onChange={handleChange}
          />
        </div>
      </div>
    </div>
  );
}
