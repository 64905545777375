import React from "react";
import { IconProps } from "./types";

function IconPerson(props: IconProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="currentColor"
      {...props}
    >
      <rect width="24" height="24" fill="none" />
      <path
        d="M13.885,11.3a.953.953,0,0,0-1.228.107.709.709,0,0,0,.121,1.083l2.33,1.689a1.861,1.861,0,0,1,.806,1.5v6.331h1.744V15.682a3.333,3.333,0,0,0-1.443-2.694Z"
        transform="translate(2.481 1.987)"
      />
      <path
        d="M1.744,15.681a1.862,1.862,0,0,1,.806-1.5l2.33-1.689A.71.71,0,0,0,5,11.4.953.953,0,0,0,3.772,11.3l-2.33,1.689A3.332,3.332,0,0,0,0,15.681v6.332H1.744Z"
        transform="translate(3 1.987)"
      />
      <path
        d="M14.8,18.593l-.012-1.448a.839.839,0,0,0-.879-.764.824.824,0,0,0-.865.775l.007,5.667H14.8Z"
        transform="translate(2.457 1.176)"
      />
      <path
        d="M4.753,18.605l.012-1.448a.824.824,0,0,0-.865-.775.846.846,0,0,0-.879.764l-.007,5.678H4.753Z"
        transform="translate(2.875 1.176)"
      />
      <path
        d="M14.4,5.892A5.711,5.711,0,1,0,8.694,11.6,5.717,5.717,0,0,0,14.4,5.892M8.694,9.858A3.966,3.966,0,1,1,12.66,5.892,3.971,3.971,0,0,1,8.694,9.858"
        transform="translate(2.876 2.443)"
      />
    </svg>
  );
}

export default IconPerson;
