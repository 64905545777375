import React, { useMemo } from "react";

export default function Icons() {
  const icons = useMemo(() => {
    return import.meta.globEager("../../icons/*.tsx");
  }, []);

  return (
    <div>
      {Object.keys(icons).map((key) => {
        return (
          <div key={key} className="flex items-center mb-1">
            <div className="w-10 flex justify-center">
              {React.createElement((icons[key] as any).default)}
            </div>
            <div>{formatKey(key)}</div>
          </div>
        );
      })}
    </div>
  );
}

function formatKey(key: string) {
  return key.replace("../../icons/", "").replace(".tsx", "");
}
