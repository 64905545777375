import React from "react";
import useVersion from "@/hooks/useVersion";
import LogoWhite from "@velaro/velaro-shared/src/images/Logo_White.svg";
import IconClose from "@/icons/IconClose";
import { SecondaryBlueButton } from "@velaro/velaro-shared/src/components/Buttons/Buttons";

export default function NewVersionModal() {
  const { showReloadModal, resetModal } = useVersion();

  return (
    <>
      {showReloadModal && ( // Show a modal if a new version is available}
        <div className="fixed flex -translate-x-1/2 left-1/2 bottom-4 items-center justify-center opacity-90">
          <div className="flex flex-col items-center bg-cornflower-blue-500 text-white p-4 rounded-lg shadow-lg">
            <button
              onClick={() => resetModal()}
              className="absolute right-2 top-2 cursor-pointer"
            >
              <IconClose />
            </button>
            <img className=" h-16 w-16" src={LogoWhite} alt="Velaro Logo" />
            <p className="pb-4 border-b mb-4">An update to Velaro is ready!</p>
            <SecondaryBlueButton
              label="Click here to refresh"
              onClick={() => {
                resetModal();
                window.location.reload();
              }}
            />
          </div>
        </div>
      )}
    </>
  );
}
