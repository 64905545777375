import React from "react";
import { IconProps } from "./types";

function IconCollapseOutline(props: IconProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      {...props}
    >
      <g data-name="collapse-outline">
        <g fill="currentColor">
          <path
            d="M19 9h-2.58l3.29-3.29a1 1 0 10-1.42-1.42L15 7.57V5a1 1 0 00-2 0v5a1 1 0 001 1h5a1 1 0 000-2z"
            data-name="Path 2012"
          ></path>
          <path
            d="M10 13H5a1 1 0 000 2h2.57l-3.28 3.29a1 1 0 101.42 1.42L9 16.42V19a1 1 0 002 0v-5a1 1 0 00-1-1z"
            data-name="Path 2013"
          ></path>
        </g>
      </g>
    </svg>
  );
}

export default IconCollapseOutline;
