import React from "react";
import { IconProps } from "./types";

function IconUpload2(props: IconProps) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g id="upload-02">
        <path
          id="Icon"
          d="M21 3H3M18 13L12 7M12 7L6 13M12 7V21"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  );
}

export default IconUpload2;
