import { useEffect, useState } from "react";
import useApi from "./useApi";

interface NetSuiteConfig {
  id: number;
  displayName: string;
}

export default function useNetSuiteConfigs(teamId: number) {
  const [items, setItems] = useState<NetSuiteConfig[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const api = useApi();

  useEffect(() => {
    async function fetchConfigs() {
      setLoading(true);
      const resp = await api.messaging.get(`NetSuite/Integrations/${teamId}`);
      setItems(await resp.json());
      setLoading(false);
    }

    fetchConfigs();
  }, [api.messaging, teamId]);

  return { items, loading };
}
