import React from "react";
import { TeamListItem } from "@/types";
export const TeamListItemsContext = React.createContext<{
  teams: TeamListItem[];
  getTeamNameById: (TeamId: number) => string;
  loading: boolean;
  setTeams: (teams: TeamListItem[]) => void;
}>({
  teams: [],
  getTeamNameById: () => "",
  loading: true,
  setTeams: () => {}
});
