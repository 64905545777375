import useApi from "@/hooks/useApi";
import useProfile from "@/hooks/useProfile";
import React, { useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import ProfileMenu from "./ProfileMenu";
import BasicInformationMenu from "./BasicInformationMenu";
import LoadingSpinner from "@velaro/velaro-shared/src/components/LoadingSpinner";
import AppLoading from "@/components/AppLoading";
import NotificationSettingsPanel from "./NotificationSettingsPanel";
import useToastAlert from "@/hooks/useToastAlert";
import { Severity } from "@velaro/velaro-shared/src/components/ToastAlert";
import PreferencesPanel from "./PreferencesPanel";
import ProfilePictureModal from "./ProfilePictureModal";
import useTranslations from "@/hooks/useTranslations";
import useIsMobile from "@/hooks/useIsMobile";
import AsideViewTab from "../Conversations/RightAside/AsideViewTab";
import {
  SecondaryBlueButton,
  PrimaryBlueButton
} from "@velaro/velaro-shared/src/components/Buttons/Buttons";

export default function Profile() {
  const api = useApi();
  const isMobile = useIsMobile();
  const navigate = useNavigate();
  const { profile, setProfile, loading } = useProfile();
  const [profilePicture, setProfilePicture] = useState<Blob>();
  const [pictureUrl, setPictureUrl] = useState("");
  const profilePictureInput = useRef<HTMLInputElement>(null);
  const [errors, setErrors] = React.useState<Record<string, string>>({});
  const [showProfilePictureModal, setShowProfilePictureModal] = useState(false);
  const [saving, setSaving] = useState(false);
  const [selectedPage, setSelectedPage] = useState<
    "basic" | "notification" | "security" | "help" | "preferences"
  >("basic");
  const toastAlert = useToastAlert();
  const translation = useTranslations();

  async function handleSubmit() {
    setSaving(true);
    setErrors({});

    const newErrors: Record<string, string> = {};

    if (profile.displayName === "" || /^\s*$/.test(profile.displayName)) {
      newErrors["DisplayName"] = "Display name is required";
      toastAlert.displayToast(Severity.Error, "Profile update failed");
    }

    setErrors(newErrors);

    if (Object.keys(newErrors).length !== 0) {
      setSaving(false);
      return;
    }

    const formData = new FormData();

    formData.append("displayName", profile.displayName);
    formData.append("firstName", profile.firstName);
    formData.append("lastName", profile.lastName);
    formData.append("phone", profile.phone);
    formData.append("language", profile.language);
    formData.append("country", profile.country);
    formData.append("timezone", profile.timezone);
    formData.append("soundEnabled", profile.soundEnabled.toString());
    formData.append("newConversationSound", profile.newConversationSound);
    formData.append("newMessageSound", profile.newMessageSound);
    formData.append(
      "chatTranslationsEnabled",
      profile.chatTranslationsEnabled.toString()
    );

    if (profilePicture) {
      formData.append("picture", profilePicture);
    }

    const resp = await api.messaging.postFormData("Profile/Update", formData);
    const data = await resp.json();

    if (resp.status === 400) {
      setErrors(data.errors);
      setSaving(false);
      toastAlert.displayToast(Severity.Error, "Profile update failed");
      return;
    }

    setProfile(data);

    if (profilePictureInput.current) {
      profilePictureInput.current.value = "";
    }

    toastAlert.displayToast(Severity.Success, "Profile updated successfully");
    setSaving(false);
  }

  if (isMobile) {
    return (
      <div className="h-full bg-white flex flex-col">
        {loading && <AppLoading />}
        <div className="flex justify-between items-center my-4 mx-4">
          <div className="text-lg font-semibold">
            {translation.key("profile_settings")}
          </div>
          <div className="flex items-center h-8 gap-2">
            {saving && (
              <div>
                <LoadingSpinner />
              </div>
            )}
            <SecondaryBlueButton
              label={translation.key("close")}
              onClick={() => navigate("/conversations")}
              size="sm"
            />
            <PrimaryBlueButton
              label={translation.key("save_profile")}
              onClick={handleSubmit}
              disabled={saving}
              size="sm"
            />
          </div>
        </div>
        <div className="flex flex-col w-full bg-white dark:border-stone-100">
          <div className="flex">
            <AsideViewTab
              label="Basic"
              selected={selectedPage == "basic"}
              onSelect={() => {
                setSelectedPage("basic");
              }}
            />
            <AsideViewTab
              label="Notifications"
              selected={selectedPage == "notification"}
              onSelect={() => {
                setSelectedPage("notification");
              }}
            />
            <AsideViewTab
              label="Preferences"
              selected={selectedPage == "preferences"}
              onSelect={() => {
                setSelectedPage("preferences");
              }}
            />
          </div>
        </div>
        <div className="flex w-full overflow-y-auto">
          {selectedPage == "basic" && <BasicInformationMenu errors={errors} />}
          {selectedPage == "notification" && <NotificationSettingsPanel />}
          {selectedPage == "preferences" && <PreferencesPanel />}
        </div>
      </div>
    );
  }

  return (
    <>
      {loading && <AppLoading />}
      <div className="flex justify-between items-center my-6 mx-4">
        <div className="text-lg font-semibold">
          {translation.key("profile_settings")}
        </div>
        <div className="flex items-center h-8 gap-2">
          {saving && (
            <div>
              <LoadingSpinner />
            </div>
          )}
          <SecondaryBlueButton
            label={translation.key("close")}
            onClick={() => navigate("/conversations")}
          />
          <PrimaryBlueButton
            label={translation.key("save_profile")}
            onClick={handleSubmit}
            disabled={saving}
          />
        </div>
      </div>
      <div className="flex w-full justify-between mx-4 gap-4">
        <ProfileMenu
          selectedPage={selectedPage}
          setSelectedPage={setSelectedPage}
          setShowAvatarModal={setShowProfilePictureModal}
        />
        {selectedPage == "basic" && <BasicInformationMenu errors={errors} />}
        {selectedPage == "notification" && <NotificationSettingsPanel />}
        {selectedPage == "preferences" && <PreferencesPanel />}
      </div>
      {showProfilePictureModal && (
        <ProfilePictureModal
          setShowProfilePicutreModal={setShowProfilePictureModal}
        />
      )}
    </>
  );
}
