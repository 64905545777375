import useProfile from "@/hooks/useProfile";
import React, { useEffect, useState } from "react";
import PhoneInput, { Value } from "react-phone-number-input";
import "react-phone-number-input/style.css";
import flags from "react-phone-number-input/flags";
import { Profile } from "@/types";
import useRoleListItems from "@/hooks/useRolesListItems";
import useTeamListItems from "@/hooks/useTeamListItems";
import useApi from "@/hooks/useApi";
import LoadingSpinner from "@velaro/velaro-shared/src/components/LoadingSpinner";
import useToastAlert from "@/hooks/useToastAlert";
import FormValidator from "@velaro/velaro-shared/src/components/FormValidator";
import useTranslations from "@/hooks/useTranslations";
import useIsMobile from "@/hooks/useIsMobile";

interface LanguageModel {
  label: string;
  value: string;
}

interface TimeZoneModel {
  id: string;
  displayName: string;
}

interface CountryModel {
  countryId: string;
  countryName: string;
}

interface Props {
  errors: Record<string, string>;
}

export default function BasicInformationMenu(props: Props) {
  const { profile, setProfile } = useProfile();
  const [languages, setLanguages] = useState<LanguageModel[]>();
  const [timeZones, setTimeZones] = useState<TimeZoneModel[]>();
  const [countries, setCountries] = useState<CountryModel[]>();
  const [loading, setLoading] = useState(true);
  const { getRoleNameById } = useRoleListItems();
  const { getTeamNameById } = useTeamListItems();
  const api = useApi();
  const toastAlert = useToastAlert();
  const translation = useTranslations();
  const isMobile = useIsMobile();

  React.useEffect(() => {
    async function load() {
      async function fetchLanguages() {
        const response = await api.messaging.get("Language");
        setLanguages(await response.json());
      }

      async function fetchTimeZones() {
        const response = await api.messaging.get("Timezones");
        setTimeZones(await response.json());
      }

      async function fetchCountries() {
        const response = await api.messaging.get("Countries");
        setCountries(await response.json());
      }

      await Promise.all([fetchLanguages(), fetchTimeZones(), fetchCountries()]);
      setLoading(false);
    }

    load();
  }, [api]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setProfile({ ...profile, [name]: value });
  };

  function onChangePhone(value: Value) {
    setProfile({ ...profile, phone: value });
  }

  function getRoles(profile: Profile | null) {
    if (profile?.roles?.length === 0 || profile?.roles === null) {
      return "(None)";
    }

    const roleNames = profile?.roles.map((x) => {
      const parsedNumber = parseInt(x);
      if (!isNaN(parsedNumber)) {
        return getRoleNameById(parsedNumber);
      } else {
        return x;
      }
    });
    return roleNames?.join(", ");
  }

  function getTeams(profile: Profile | null) {
    if (!profile) return "None";

    if (profile.teamIds?.length === 0) {
      return "None";
    }

    const teams = profile.teamIds.map((x) => getTeamNameById(x));

    return teams.join(", ");
  }

  if (loading) {
    return (
      <div className="flex justify-center items-center w-4/5">
        <LoadingSpinner />
      </div>
    );
  }

  if (isMobile) {
    return (
      <div className="bg-white p-4">
        <div className="font-semibold text-lg mb-2">Details</div>
        <div>
          <label>{translation.key("first_name")}</label>
          <input
            id="firstName"
            name="firstName"
            type="text"
            className="input w-full mt-2 mb-6"
            autoFocus
            value={profile.firstName || ""}
            onChange={handleChange}
          />
          <label>{translation.key("last_name")}</label>
          <input
            id="lastName"
            name="lastName"
            type="text"
            className="input w-full mt-2 mb-6"
            value={profile.lastName || ""}
            onChange={handleChange}
          />
          <label>{translation.key("phone_number")}</label>
          <PhoneInput
            placeholder="Mobile Phone Number"
            flags={flags}
            country={"us"}
            className="input mt-2 mb-6 block w-full phone-input focus-visible:outline-none focus-visible:border-cornflower-blue-600 hover:outline-none"
            value={profile.phone || ""}
            onChange={onChangePhone}
            defaultCountry={"US"}
          />
          <label>{translation.key("country")}</label>
          <select
            className="input w-full mt-2 mb-6"
            onChange={(e) =>
              setProfile({ ...profile, country: e.currentTarget.value })
            }
            value={profile.country || ""}
          >
            {countries?.map((country, i) => (
              <option key={i} value={country.countryId}>
                {country.countryName}
              </option>
            ))}
          </select>
          <label>{translation.key("language")}</label>
          <select
            className="input w-full mt-2 mb-6"
            onChange={(e) =>
              setProfile({ ...profile, language: e.currentTarget.value })
            }
            value={profile.language || ""}
          >
            {languages?.map((language, i) => (
              <option key={i} value={language.value}>
                {language.label}
              </option>
            ))}
          </select>
          <label>{translation.key("time_zone")}</label>
          <select
            className="input w-full mt-2"
            onChange={(e) =>
              setProfile({ ...profile, timezone: e.currentTarget.value })
            }
            value={profile.timezone || ""}
          >
            {timeZones?.map((timezone, i) => (
              <option key={i} value={timezone.id}>
                {timezone.displayName}
              </option>
            ))}
          </select>
        </div>
        <div className="font-semibold text-lg mb-2 mt-6">
          {translation.key("public_profile")}
        </div>
        <div>
          <label>{translation.key("display_name")}</label>
          <FormValidator
            error={props.errors["DisplayName"]}
            className="mt-2 mb-6"
          >
            <input
              id="displayName"
              name="displayName"
              type="text"
              className="input w-full "
              value={profile.displayName || ""}
              onChange={handleChange}
            />
          </FormValidator>
        </div>
        <div className="font-semibold text-lg mb-2 mt-6">
          {translation.key("teams_and_roles")}
        </div>
        <div className="grid grid-cols-2 gap-12">
          <div>
            <label>{translation.key("my_assigned_roles")}</label>
            <div className="w-full mt-2 mb-6">{getRoles(profile)}</div>
          </div>
          <div>
            <label>{translation.key("my_teams")}</label>
            <div className="w-full mt-2 mb-6">{getTeams(profile)}</div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="bg-white rounded mr-8 w-4/5 p-8">
      <div className="font-semibold text-lg mb-2">Details</div>
      <div className="grid grid-cols-2 gap-12">
        <div>
          <label>{translation.key("first_name")}</label>
          <input
            id="firstName"
            name="firstName"
            type="text"
            className="input w-full mt-2 mb-6"
            autoFocus
            value={profile.firstName || ""}
            onChange={handleChange}
          />
          <label>{translation.key("phone_number")}</label>
          <PhoneInput
            placeholder="Mobile Phone Number"
            flags={flags}
            country={"us"}
            className="input mt-2 mb-6 block w-full phone-input focus-visible:outline-none focus-visible:border-cornflower-blue-600 hover:outline-none"
            value={profile.phone || ""}
            onChange={onChangePhone}
            defaultCountry={"US"}
          />
          <label>{translation.key("country")}</label>
          <select
            className="input w-full mt-2"
            onChange={(e) =>
              setProfile({ ...profile, country: e.currentTarget.value })
            }
            value={profile.country || ""}
          >
            {countries?.map((country, i) => (
              <option key={i} value={country.countryId}>
                {country.countryName}
              </option>
            ))}
          </select>
        </div>
        <div>
          <label>{translation.key("last_name")}</label>
          <input
            id="lastName"
            name="lastName"
            type="text"
            className="input w-full mt-2 mb-6"
            autoFocus
            value={profile.lastName || ""}
            onChange={handleChange}
          />
          <label>{translation.key("language")}</label>
          <select
            className="input w-full mt-2 mb-6"
            onChange={(e) =>
              setProfile({ ...profile, language: e.currentTarget.value })
            }
            value={profile.language || ""}
          >
            {languages?.map((language, i) => (
              <option key={i} value={language.value}>
                {language.label}
              </option>
            ))}
          </select>
          <label>{translation.key("time_zone")}</label>
          <select
            className="input w-full mt-2"
            onChange={(e) =>
              setProfile({ ...profile, timezone: e.currentTarget.value })
            }
            value={profile.timezone || ""}
          >
            {timeZones?.map((timezone, i) => (
              <option key={i} value={timezone.id}>
                {timezone.displayName}
              </option>
            ))}
          </select>
        </div>
      </div>
      <div className="font-semibold text-lg mb-2 mt-6">
        {translation.key("public_profile")}
      </div>
      <div className="grid grid-cols-2 gap-12">
        <div>
          <label>{translation.key("display_name")}</label>
          <FormValidator
            error={props.errors["DisplayName"]}
            className="mt-2 mb-6"
          >
            <input
              id="displayName"
              name="displayName"
              type="text"
              className="input w-full "
              autoFocus
              value={profile.displayName || ""}
              onChange={handleChange}
            />
          </FormValidator>
        </div>
      </div>
      <div className="font-semibold text-lg mb-2 mt-6">
        {translation.key("teams_and_roles")}
      </div>
      <div className="grid grid-cols-2 gap-12">
        <div>
          <label>{translation.key("my_assigned_roles")}</label>
          <div className="w-full mt-2 mb-6">{getRoles(profile)}</div>
        </div>
        <div>
          <label>{translation.key("my_teams")}</label>
          <div className="w-full mt-2 mb-6">{getTeams(profile)}</div>
        </div>
      </div>
    </div>
  );
}
