import React from "react";

interface Props {
  attachments: File[];
  onUpdate(attachments: File[]): void;
}

export default function MessageFormAttachments(props: Props) {
  if (!props.attachments.length) {
    return null;
  }

  function renderAttachment(file: File, index: number) {
    let imageSrc: string | null = null;
    if (isFileImage(file)) {
      imageSrc = URL.createObjectURL(file);
    }

    return (
      <div
        title={file.name}
        className="border relative p-2 rounded w-36 h-40 flex flex-col gap-2 items-center justify-center"
        key={index}
      >
        {imageSrc && (
          <img
            className="h-full min-h-0 object-scale-down flex-grow"
            src={imageSrc}
            onLoad={() => {
              URL.revokeObjectURL(imageSrc!);
            }}
          />
        )}
        {!imageSrc && (
          <i className="fa fa-file fa-8x flex-grow h-full min-h-0" />
        )}
        <div className="text-sm text-center w-full overflow-hidden whitespace-nowrap h-6">
          {file.name}
        </div>
        <div
          style={{ top: -10, right: -10 }}
          className="bg-white absolute border shadow rounded z-10"
        >
          <button
            title={`Remove ${file.name}`}
            className="text-crimson-red-600 w-8 h-8"
            onClick={() => {
              const updated = [...props.attachments];
              updated.splice(index, 1);
              props.onUpdate(updated);
            }}
          >
            <i className="fa fa-times"></i>
          </button>
        </div>
      </div>
    );
  }

  return (
    <div className="flex overflow-x-auto cursor-default pb-4 mb-4 gap-5 pt-3">
      {props.attachments.map(renderAttachment)}
    </div>
  );
}

function isFileImage(file: File) {
  return file && file["type"].split("/")[0] === "image";
}
