import React from "react";
import { SelectedColumn, columnsDict } from "./types";
import SelectAllCheckbox from "@velaro/velaro-shared/src/components/SelectAllCheckbox";

interface Props {
  selectedColumns: SelectedColumn[];
  setSelectedColumns: (columns: SelectedColumn[]) => void;
}

export default function ColumnDropdown(props: Props) {
  function selectAll() {
    props.setSelectedColumns(
      props.selectedColumns.map((col) => ({ ...col, enabled: true }))
    );
  }

  function deselectAll() {
    props.setSelectedColumns(
      props.selectedColumns.map((col) => ({ ...col, enabled: false }))
    );
  }

  return (
    <div className="bg-white w-56 rounded shadow border">
      <div className="flex p-1 hover:bg-indigo-50 items-center border-b border-slate-200 text-sm font-normal">
        <SelectAllCheckbox
          allSelected={props.selectedColumns.every((col) => col.enabled)}
          someSelected={props.selectedColumns.some((col) => col.enabled)}
          onChange={(checked) => {
            if (checked) {
              selectAll();
            } else {
              deselectAll();
            }
          }}
        />
        <div className="pl-2 text-sm text-slate-800">Select All</div>
      </div>
      {props.selectedColumns.map((column, i) => (
        <div
          key={i}
          className="flex p-1 hover:bg-indigo-50 items-center border-b border-slate-200 text-sm font-normal"
        >
          <input
            type="checkbox"
            checked={column.enabled}
            onChange={(e) => {
              const clone = [...props.selectedColumns];
              clone[i] = { ...column, enabled: e.target.checked };
              props.setSelectedColumns(clone);
            }}
          ></input>
          <div className="pl-2 text-sm text-slate-800">
            {columnsDict[column.value]}
          </div>
        </div>
      ))}
    </div>
  );
}
