import React from "react";
import { IconProps } from "./types";

function IconCheckMarkSquare(props: IconProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24.001"
      height="24"
      viewBox="0 0 24.001 24"
      {...props}
    >
      <defs>
        <clipPath id="clip-path">
          <path
            fill="currentColor"
            d="M0 0H24.001V24H0z"
            data-name="Rectangle 600"
          ></path>
        </clipPath>
      </defs>
      <g fill="currentColor" clipPath="url(#clip-path)" data-name="Group 342">
        <path
          d="M22.951 24H1.049A1.05 1.05 0 010 22.951V1.049A1.049 1.049 0 011.049 0h21.9A1.049 1.049 0 0124 1.049v21.9A1.05 1.05 0 0122.951 24M2.1 21.9h19.8V2.1H2.1z"
          data-name="Path 532"
        ></path>
        <path
          d="M11.273 17.083a1.045 1.045 0 01-.742-.308l-4.068-4.068a1.049 1.049 0 011.484-1.484l3.326 3.326 5.859-5.859a1.049 1.049 0 111.484 1.484l-6.6 6.6a1.045 1.045 0 01-.742.308"
          data-name="Path 533"
          transform="translate(-.538 -.733)"
        ></path>
      </g>
    </svg>
  );
}

export default IconCheckMarkSquare;
