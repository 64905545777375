/* eslint-disable @typescript-eslint/no-unused-vars */

import { Contact, HubMessages } from "@/types";

const eventMap = {
  "conversation-assigned": (message: HubMessages.ConversationAssigned) => {},
  "conversation-unassigned": (
    message: HubMessages.ConversationUnassigned
  ) => {},
  "assign-group": (message: HubMessages.AssignGroup) => {},
  "contact-update": (message: Contact) => {},
  "contact-note-added": (message: HubMessages.ContactNoteAdded) => {},
  "contact-tags-updated": (message: HubMessages.ContactTagsUpdated) => {},
  "conversation-created": (message: HubMessages.ConversationCreated) => {},
  "conversation-resolved": (message: HubMessages.ConversationResolved) => {},
  "conversation-reopened": (message: HubMessages.ConversationReopened) => {},
  "conversation-note-added": (message: HubMessages.ConversationNoteAdded) => {},
  "conversation-tags-updated": (
    message: HubMessages.ConversationTagsUpdated
  ) => {},
  "receive-message": (message: HubMessages.ReceiveMessage) => {},
  "receive-invitation": (message: HubMessages.ReceiveInvitation) => {},
  "delete-attachment": (message: HubMessages.DeleteAttachment) => {},
  "unassign-warning": (message: HubMessages.UnassignWarning) => {},
  "automation-done": (message: HubMessages.AutomationDone) => {},
  "is-typing": (message: HubMessages.IsTyping) => {},
  "skill-assigned": (message: HubMessages.SkillAssigned) => {},
  "team-assigned": (message: HubMessages.TeamAssigned) => {}
};

export default eventMap;
