import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import HttpBackend from "i18next-http-backend";
import en from "@velaro/velaro-shared/src/locales/en.json";
import es from "@velaro/velaro-shared/src/locales/es.json";

i18n
  //.use(HttpBackend)
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    fallbackLng: "en",
    interpolation: {
      escapeValue: false // not needed for react as it escapes by default
    },
    saveMissing: false,
    // backend: {
    //   loadPath: "node_modules/@velaro/velaro-shared/src/locales/{{lng}}.json"
    // },
    resources: {
      en: { translation: en },
      es: { translation: es }
    }
  });

export default i18n;
