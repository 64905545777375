import React from "react";
import useApi from "./useApi";
import conversationReducer from "@/reducers/conversationReducer";
import useSubscribe from "./useSubscribe";
import useConnection from "./useConnection";
import { HubConnectionState } from "@microsoft/signalr";
import { Contact } from "@/types";
import useToastAlert from "./useToastAlert";
import { Severity } from "@velaro/velaro-shared/src/components/ToastAlert";

const defaultConversation: any = {
  users: [],
  contact: {}
};

export default function useConversation(id: number) {
  const [conversation, dispatch] = React.useReducer(
    conversationReducer,
    defaultConversation
  );
  const [conversationLoading, setConversationLoading] = React.useState(true);
  const api = useApi();
  const subscribe = useSubscribe();
  const { connectionState } = useConnection();
  const toast = useToastAlert();
  React.useEffect(() => {
    async function fetchConversation() {
      if (isNaN(id)) {
        setConversationLoading(false);
        return;
      }

      const response = await api.messaging.get(`Conversations/${id}`);

      if (!response.ok) {
        toast.displayToast(
          Severity.Error,
          "There was a problem fetrching the conversation"
        );
        setConversationLoading(false);
        return;
      }

      if (response.status !== 204) {
        dispatch({
          type: "SET_CONVERSATION",
          conversation: await response.json()
        });
      }
      setConversationLoading(false);
    }

    if (connectionState === HubConnectionState.Connected) {
      fetchConversation();
    }
  }, [api.messaging, connectionState, id, toast]);

  React.useEffect(
    () =>
      subscribe("contact-update", (contact: Contact) => {
        if (contact.id === conversation.contact.id) {
          dispatch({
            type: "UPDATE_CONTACT",
            contact: contact
          });
        }
      }),
    [conversation.contact.id, subscribe]
  );

  React.useEffect(
    () =>
      subscribe("conversation-assigned", (message) => {
        if (id === message.conversation.id) {
          dispatch({
            type: "ASSIGN_USER",
            user: message.user
          });
          dispatch({
            type: "UPDATE_TOPIC",
            topic: message.conversation.topic
          });
        }
      }),
    [subscribe, id]
  );

  React.useEffect(
    () =>
      subscribe("conversation-unassigned", (message) => {
        if (id === message.conversation.id) {
          dispatch({
            type: "UNASSIGN_USER"
          });
          dispatch({
            type: "UPDATE_TOPIC",
            topic: message.conversation.topic
          });
        }
      }),
    [subscribe, id]
  );

  React.useEffect(
    () =>
      subscribe("assign-group", (message) => {
        if (id === message.conversationBaseId) {
          dispatch({ type: "ASSIGN_GROUP", groupId: message.groupId });
        }
      }),
    [subscribe, id]
  );

  React.useEffect(
    () =>
      subscribe("conversation-resolved", (message) => {
        dispatch({
          type: "RESOLVE_CONVERSATION",
          conversation: conversation
        });
      }),
    [conversation, subscribe]
  );

  return {
    conversation,
    conversationLoading,
    dispatch
  };
}
