import useInbox from "@/hooks/useInbox";
import useProfile from "@/hooks/useProfile";
import React, { useCallback, useEffect, useMemo } from "react";
import GroupedConversations from "./GroupedConversations";
import ConversationItem from "./ConversationItem";
import useTranslations from "@/hooks/useTranslations";

interface AgentData {
  agentName: string;
  expanded: boolean;
}

export default function InboxTabContent() {
  const { state, settings, groupedConversations } = useInbox();
  const { profile } = useProfile();
  const tab = state.tabs[settings.selectedTab];
  const translation = useTranslations();

  if (!tab) {
    return (
      <div className="p-8 text-xs text-center">
        {translation.key("no_active_conversations")}
      </div>
    );
  }
  if (tab.conversations.length === 0) {
    return (
      <div className="p-8 text-xs text-center">
        {translation.key("no_active_conversations")}
      </div>
    );
  }

  return (
    <>
      {/* Handle grouped items */}
      {settings.groupBy !== "none" && (
        <>
          {Object.keys(groupedConversations).map((groupedBy, key) => (
            <GroupedConversations
              key={key}
              label={groupedBy}
              conversations={Object.values(groupedConversations[groupedBy])}
            />
          ))}
        </>
      )}
      {/* Display everything if there is no grouping */}
      {settings.groupBy === "none" && (
        <>
          {Object.values(groupedConversations)[0].map((conversation, key) => (
            <ConversationItem key={key} conversation={conversation} />
          ))}
        </>
      )}
      {/* {settings.selectedTab === "mine" && (
        <>
          {tab.conversations.map((conversation) => (
            <ConversationItem
              key={conversation.id}
              conversation={conversation}
            />
          ))}
        </>
      )} */}
      {/* todo: add load more button back in. */}
      {/* {props.tab.allLoaded === false && (
        <button
          className="w-full h-20 text-center text-cornflower-blue-600 hover:text-cornflower-blue-900"
          onClick={() => props.tab.loadMore()}
          disabled={props.tab.loadingMore}
        >
          {props.tab.loadingMore
            ? "Loading more conversations..."
            : "Load more conversations"}
        </button>
      )}
      {props.tab.allLoaded === true && (
        <div className="flex w-full h-20 items-center justify-center">
          All conversations loaded.
        </div>
      )} */}
    </>
  );
}
