import React, { useEffect } from "react";

interface Props {
  name?: string;
  url: string | undefined;
  size: number | string;
  fontSize?: number | string;
}

export default function Avatar(props: Props) {
  const initials = React.useMemo(() => {
    try {
      const tokens = (props.name || "")
        .split(/[-\s]/)
        .filter((str) => str !== "")
        .map((str) => str[0].toUpperCase());

      if (tokens.length === 0) {
        return "G";
      }

      if (tokens.length === 1) {
        return tokens[0];
      }

      return `${tokens[0]}${tokens[1]}`;
    } catch (err) {
      console.error(err);
      return "G";
    }
  }, [props.name]);

  // The list of colors for populating the background of an avatar
  const colors = [
    "#EE3763", // Crimson Red -> crimson-red-500
    "#697B87", // Slate Gray -> slate-gray-500
    "#4B75AB", // Steel Blue -> steel-blue-500
    "#6E51A3", // Grape -> grape-500
    "#FFBD22", // Sweet Orange -> sweet-orange-500
    "#2FBF71" // Success Green -> success-green-500
  ];

  // This is a bunch of code to randomly create an avatar, but make sure its consistent between page loads

  function cyrb128(str: any) {
    let h1 = 1779033703,
      h2 = 3144134277,
      h3 = 1013904242,
      h4 = 2773480762;
    for (let i = 0, k; i < str.length; i++) {
      k = str.charCodeAt(i);
      h1 = h2 ^ Math.imul(h1 ^ k, 597399067);
      h2 = h3 ^ Math.imul(h2 ^ k, 2869860233);
      h3 = h4 ^ Math.imul(h3 ^ k, 951274213);
      h4 = h1 ^ Math.imul(h4 ^ k, 2716044179);
    }
    h1 = Math.imul(h3 ^ (h1 >>> 18), 597399067);
    h2 = Math.imul(h4 ^ (h2 >>> 22), 2869860233);
    h3 = Math.imul(h1 ^ (h3 >>> 17), 951274213);
    h4 = Math.imul(h2 ^ (h4 >>> 19), 2716044179);
    return [
      (h1 ^ h2 ^ h3 ^ h4) >>> 0,
      (h2 ^ h1) >>> 0,
      (h3 ^ h1) >>> 0,
      (h4 ^ h1) >>> 0
    ];
  }

  function mulberry32(a: any) {
    return function () {
      let t = (a += 0x6d2b79f5);
      t = Math.imul(t ^ (t >>> 15), t | 1);
      t ^= t + Math.imul(t ^ (t >>> 7), t | 61);
      return ((t ^ (t >>> 14)) >>> 0) / 4294967296;
    };
  }

  let seed: number[];
  if (props.name?.length) {
    seed = cyrb128(props.name);
  } else {
    seed = cyrb128("velarodefaultseed");
  }
  const rand = mulberry32(seed[0]);
  const background = colors[Math.floor(rand() * colors.length)];

  const fontSize = props.fontSize || "inherit";

  if (props.url) {
    return (
      <img
        src={props.url}
        className={`object-cover rounded-full`}
        style={{ height: props.size, width: props.size }}
      />
    );
  }

  return (
    <div
      className={`flex justify-center items-center  text-white rounded-full`}
      style={{
        height: props.size,
        width: props.size,
        fontSize,
        backgroundColor: background
      }}
    >
      {initials}
    </div>
  );
}
