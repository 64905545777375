import React from "react";
import { IconProps } from "./types";

function IconPriorityOutline(props: IconProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      {...props}
    >
      <path
        fill="currentColor"
        d="M8.506 13.952l-3.268-3.186 4.516-.656 2.019-4.093 2.02 4.093 4.516.656-3.268 3.186.771 4.5-4.04-2.122-4.037 2.119zm-.132-5.74l-7.6 1.1 5.5 5.362-1.3 7.569 6.8-3.574 6.8 3.574-1.3-7.569 5.5-5.362-7.6-1.1-3.4-6.887z"
        data-name="Path 1101"
        transform="translate(.226 .676)"
      ></path>
    </svg>
  );
}

export default IconPriorityOutline;
