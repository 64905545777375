import React from "react";

interface Props {
  label: string;
  value: number;
  percentChange: number;
}

export default function ContactKPI({ label, value, percentChange }: Props) {
  return (
    <div className="flex flex-col flex-1 border rounded h-32 p-4 justify-evenly">
      <div className="text-sm font-medium">{label}</div>
      <div className="text-2xl font-medium">{value}</div>
      {/* <div
        className={`text-sm ${
          percentChange > 0 ? "text-green-500" : "text-red-500"
        }`}
      >
        {percentChange > 0 ? "+" : ""}
        {percentChange}%
      </div> */}
    </div>
  );
}
