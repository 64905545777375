import React from "react";
import { IconProps } from "./types";

function IconAppsLarge(props: IconProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="28"
      height="28"
      viewBox="0 0 28 28"
      {...props}
    >
      <g
        fill="currentColor"
        data-name="Group 101"
        transform="translate(2.242 2.242)"
      >
        <path
          d="M10.839 3H5.613A2.613 2.613 0 003 5.613v5.226a2.613 2.613 0 002.613 2.613h5.226a2.613 2.613 0 002.613-2.613V5.613A2.613 2.613 0 0010.839 3zm-5.226 7.839V5.613h5.226v5.226z"
          data-name="Path 231"
          transform="translate(-3 -3)"
        ></path>
        <path
          d="M20.839 3h-5.226A2.613 2.613 0 0013 5.613v5.226a2.613 2.613 0 002.613 2.613h5.226a2.613 2.613 0 002.613-2.613V5.613A2.613 2.613 0 0020.839 3zm-5.226 7.839V5.613h5.226v5.226z"
          data-name="Path 232"
          transform="translate(.064 -3)"
        ></path>
        <path
          d="M10.839 13H5.613A2.613 2.613 0 003 15.613v5.226a2.613 2.613 0 002.613 2.613h5.226a2.613 2.613 0 002.613-2.613v-5.226A2.613 2.613 0 0010.839 13zm-5.226 7.839v-5.226h5.226v5.226z"
          data-name="Path 233"
          transform="translate(-3 .064)"
        ></path>
        <path
          d="M20.839 13h-5.226A2.613 2.613 0 0013 15.613v5.226a2.613 2.613 0 002.613 2.613h5.226a2.613 2.613 0 002.613-2.613v-5.226A2.613 2.613 0 0020.839 13zm-5.226 7.839v-5.226h5.226v5.226z"
          data-name="Path 234"
          transform="translate(.064 .064)"
        ></path>
      </g>
    </svg>
  );
}

export default IconAppsLarge;
