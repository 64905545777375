import React from "react";
import { IconProps } from "./types";

function IconSortByDown(props: IconProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      {...props}
    >
      <defs>
        <clipPath id="clip-path"></clipPath>
      </defs>
      <g
        fill="currentColor"
        clipPath="url(#clip-path)"
        data-name="Mask Group 2"
      >
        <path
          d="M5.2 3.412L.5 6.729A1.178 1.178 0 101.861 8.65l2.846-2.026v15.093a1.176 1.176 0 102.352 0V6.729l2.823 2.117a1.176 1.176 0 101.412-1.881l-4.706-3.53A1.177 1.177 0 005.2 3.412z"
          data-name="Path 1125"
          transform="rotate(180 5.882 13.045)"
        ></path>
        <path
          d="M22.827 11.326h-9.154a1.173 1.173 0 110-2.346h9.154a1.173 1.173 0 010 2.346"
          data-name="Path 1126"
          transform="translate(0 -5.783)"
        ></path>
        <path
          d="M22.827 17.109h-7.154a1.173 1.173 0 110-2.346h7.154a1.173 1.173 0 110 2.346"
          data-name="Path 1127"
          transform="translate(0 -5.783)"
        ></path>
        <path
          d="M22.827 22.893h-5.154a1.173 1.173 0 110-2.346h5.154a1.173 1.173 0 110 2.346"
          data-name="Path 1128"
          transform="translate(0 -5.783)"
        ></path>
        <path
          d="M21.351 22.893H17.4a1.064 1.064 0 01-.9-1.173 1.064 1.064 0 01.9-1.173h3.951a1.064 1.064 0 01.9 1.173 1.064 1.064 0 01-.9 1.173"
          data-name="Path 1129"
          transform="translate(1.75 .001)"
        ></path>
      </g>
    </svg>
  );
}

export default IconSortByDown;
