import { Note } from "@/types";
import React, { useMemo } from "react";
import AddNoteForm from "./AddNoteForm";
import NoteListItem from "./NoteListItem";
import ViewAllNotesModal from "./ViewAllNotesModal";

interface Props {
  label: string;
  modalTitle: string;
  modalSubTitle?: string;
  notes: Note[];
  conversationBaseId?: number;
  onAddNote(text: string): void;
}

export default function Notes(props: Props) {
  const [showModal, setShowModal] = React.useState(false);

  const lastNote = useMemo(() => {
    if (props.notes.length === 0) {
      return undefined;
    }

    return props.notes[props.notes.length - 1];
  }, [props.notes]);

  return (
    <>
      <div>
        <div className="flex mb-2">
          <div className="font-bold dark:text-white">{props.label}</div>
          {props.notes.length > 1 && (
            <button
              className="ml-auto text-cornflower-blue-600 dark:text-cornflower-blue-200"
              onClick={() => setShowModal(true)}
            >
              View all
            </button>
          )}
        </div>
        {lastNote && (
          <div className="border border-cornflower-blue-100 rounded p-2 mb-2">
            {lastNote && <NoteListItem {...lastNote} />}
          </div>
        )}
        <AddNoteForm onSubmit={props.onAddNote} id={props.conversationBaseId} />
      </div>
      {showModal && (
        <ViewAllNotesModal
          title={props.modalTitle}
          subTitle={props.modalSubTitle}
          notes={props.notes}
          onClose={() => setShowModal(false)}
          onAddNote={props.onAddNote}
        />
      )}
    </>
  );
}
