import React, { useCallback, useEffect, useMemo } from "react";
import InboxContext from "@/context/InboxContext";
import inboxReducer from "@/reducers/inboxReducer";
import { defaultInboxFilters, defaultInboxState } from "@/constants";
import useApi from "@/hooks/useApi";
import {
  InboxSettings,
  InboxSortBy,
  conversationSources,
  ConversationListItem
} from "@/types";
import useSubscribe from "@/hooks/useSubscribe";
import useProfile from "@/hooks/useProfile";
import useLocalStorage from "@/hooks/useLocalStorage";
import useToastAlert from "@/hooks/useToastAlert";
import { Severity } from "@velaro/velaro-shared/src/components/ToastAlert";
import useSounds from "@/hooks/useSounds";
import useConnection from "@/hooks/useConnection";

function getSortField(sortBy: InboxSortBy) {
  switch (sortBy) {
    case "newest":
      return "id";
    case "oldest":
      return "id";
    case "longest":
      return "lastmessage";
    // case "priority":
    //   return "priority";
    case "agent":
      return "agent";
  }
}

function getSortDirection(sortBy: InboxSortBy) {
  switch (sortBy) {
    case "newest":
      return "desc";
    case "oldest":
      return "asc";
    case "longest":
      return "asc";
    // case "priority":
    //   return "asc";
    case "agent":
      return "asc";
  }
}

export default function InboxProvider(props: { children: React.ReactNode }) {
  const [state, dispatch] = React.useReducer(inboxReducer, defaultInboxState);
  const api = useApi();
  const subscribe = useSubscribe();
  const { profile } = useProfile();
  const toastAlert = useToastAlert();
  const { playSound } = useSounds();
  const { on, off } = useConnection();
  const [settings, setSettings] = useLocalStorage<InboxSettings>(
    "inbox_settings",
    {
      sortBy: "newest",
      filters: defaultInboxFilters,
      selectedTab: "mine",
      groupBy: "none",
      searchQuery: ""
    }
  );

  const fetchInboxData = useCallback(async () => {
    console.log("fetching inbox data");

    const resp = await api.messaging.post("Inbox", {
      filters: settings.filters || defaultInboxFilters,
      sortBy: getSortField(settings.sortBy),
      sortDir: getSortDirection(settings.sortBy)
    });

    if (!resp.ok) {
      toastAlert.displayToast(Severity.Error, "Failed to fetch inbox data");
      return;
    }

    const tabs = await resp.json();
    dispatch({ type: "SET_TABS", tabs });
  }, [api.messaging, settings.filters, settings.sortBy, toastAlert]);

  useEffect(() => {
    //if any of the arrays are undefined, assume it's the old filter settings and reset
    if (!settings.filters || settings.filters.assignment === undefined) {
      console.log("resetting filters");
      setSettings({ ...settings, filters: defaultInboxFilters });
      return;
    }
    fetchInboxData();
  }, [api.messaging, fetchInboxData, setSettings, settings, toastAlert]);

  //refresh inbox when connection is established or re-established to signalR
  useEffect(() => {
    on("connected", fetchInboxData);
    on("reconnected", fetchInboxData);

    return () => {
      off("connected", fetchInboxData);
      off("reconnected", fetchInboxData);
    };
  }, [fetchInboxData, off, on]);

  useEffect(
    () =>
      subscribe("contact-update", (contact) => {
        dispatch({
          type: "CONTACT_UPDATE",
          contact: contact
        });
      }),
    [subscribe]
  );

  useEffect(
    () =>
      subscribe("receive-message", (message) => {
        console.log("receive-message", message);
        dispatch({
          type: "RECEIVE_MESSAGE",
          id: message.message.conversationBaseId,
          lastMessageText: message.message.text,
          lastMessageTimestamp: message.message.timestamp
        });
        if (
          message.assignedUserId === profile.id &&
          message.message.user?.id != profile.id
        ) {
          playSound("newMessage");
        }
      }),
    [playSound, profile.id, subscribe]
  );

  useEffect(
    () =>
      subscribe("conversation-resolved", (message) => {
        console.log("conversation-resolved", message);
        dispatch({
          type: "CONVERSATION_RESOLVED",
          conversation: message.conversation,
          isMine: message.conversation.assignedUserId === profile.id,
          isUnassigned: !message.conversation.assignedUserId,
          settings: settings
        });
      }),
    [profile.id, settings, subscribe]
  );

  useEffect(
    () =>
      subscribe("conversation-reopened", (message) => {
        console.log("conversation-reopened", message);
        dispatch({
          type: "CONVERSATION_REOPENED",
          conversation: message.conversation,
          isMine: message.conversation.assignedUserId === profile.id,
          isUnassigned: !message.conversation.assignedUserId,
          settings: settings
        });
        playSound("newConversation");
      }),
    [playSound, profile.id, settings, subscribe]
  );

  useEffect(
    () =>
      subscribe("conversation-created", (message) => {
        console.log("conversation-created", message);
        dispatch({
          type: "CONVERSATION_CREATED",
          isMine: message.conversation.assignedUserId === profile.id,
          conversation: message.conversation,
          settings: settings
        });
        playSound("newConversation");
      }),
    [playSound, profile.id, settings, subscribe]
  );

  useEffect(
    () =>
      subscribe("conversation-unassigned", (message) => {
        console.log("conversation-unassigned", message);
        dispatch({
          type: "CONVERSATION_UNASSIGNED",
          wasMine: message.unassignedUserId === profile.id,
          conversation: message.conversation,
          settings: settings
        });
      }),
    [profile.id, settings, subscribe]
  );

  useEffect(
    () =>
      subscribe("conversation-assigned", (message) => {
        console.log("conversation-assigned", message);
        dispatch({
          type: "CONVERSATION_ASSIGNED",
          isMine: message.user.id === profile.id,
          wasMine: message.previousAssignedUserId === profile.id,
          wasUnassigned: !message.previousAssignedUserId,
          conversation: message.conversation,
          settings: settings
        });
      }),
    [profile.id, settings, subscribe]
  );

  useEffect(
    () =>
      subscribe("unassign-warning", (message) => {
        console.log("unassign-warning", message);
        dispatch({
          type: "UNASSIGN_WARNING",
          conversation: message.conversation
        });
      }),
    [profile.id, settings, subscribe]
  );

  useEffect(
    () =>
      subscribe("automation-done", (message) => {
        console.log("automation-done", message);
        dispatch({
          type: "AUTOMATION_DONE",
          conversation: message.conversation
        });
      }),
    [profile.id, settings, subscribe]
  );

  let groupings: string[] = [];
  let key = "" as keyof ConversationListItem;
  switch (settings.groupBy) {
    case "agent": {
      const groupedAgents = state.tabs[settings.selectedTab].conversations.map(
        (conversation, i) => {
          if (
            conversation.agentName === undefined ||
            conversation.agentName === null
          ) {
            return "Unassigned";
          }
          return conversation.agentName;
        }
      );
      const uniqueAgents = [
        // ...new Set(groupedAgents.filter((agent) => agent != undefined))
        ...new Set(groupedAgents)
      ];
      groupings = uniqueAgents.sort();
      key = "agentName";
      break;
    }

    case "channel": {
      groupings = conversationSources.sort();
      key = "source";
      break;
    }

    case "status": {
      groupings = ["Open", "Resolved"];
      key = "conversationStatus";
      break;
    }
    default:
      groupings = ["none"];
      break;
  }

  const searchedConversations = useMemo(() => {
    if (!settings.searchQuery) {
      return state.tabs[settings.selectedTab].conversations;
    }

    const query = settings.searchQuery.trim().toLowerCase();
    return state.tabs[settings.selectedTab].conversations.filter(
      (conversation) => {
        const agentName = (conversation.agentName || "").toLocaleLowerCase();
        const contactName = (conversation.name || "").toLocaleLowerCase();
        return agentName.includes(query) || contactName.includes(query);
      }
    );
  }, [settings.searchQuery, settings.selectedTab, state.tabs]);

  const groupedConversations = useMemo(() => {
    const result: Record<string, ConversationListItem[]> = {};
    groupings.forEach((group) => {
      if (group === "none") {
        result[group] = searchedConversations;
        return;
      }
      // Special case to account for verbiage change around unassigned chats when using agent filter
      if (group === "Unassigned" && settings.groupBy === "agent") {
        result[group] = searchedConversations.filter(
          (conversation) => conversation[key] === null
        );
      } else {
        result[group] = searchedConversations.filter(
          (conversation) => conversation[key] === group
        );
      }
    });
    return result;
  }, [groupings, key, searchedConversations, settings.groupBy]);

  return (
    <InboxContext.Provider
      value={{ state, dispatch, setSettings, settings, groupedConversations }}
    >
      {props.children}
    </InboxContext.Provider>
  );
}
