import React from "react";

interface Props {
  children: string;
  colSpan?: number;
}

export default function THeader({ children, colSpan }: Props) {
  return (
    <th
      colSpan={colSpan}
      className="border-b font-medium p-4 pl-8 text-slate-gray-200 bg-steel-blue-600 dark:text-slate-gray-200 text-left"
    >
      {children}
    </th>
  );
}
