import React from "react";
import { IconProps } from "./types";

function IconExpandMenuRight(props: IconProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      {...props}
    >
      <g
        fill="currentColor"
        data-name="Group 301"
        transform="rotate(180 1114.532 576.914)"
      >
        <path
          d="M17.836 11.709H7.511l4.059-4.875A1.119 1.119 0 009.848 5.4l-5.59 6.708a1.331 1.331 0 00-.1.168.142.142 0 01-.078.145 1.074 1.074 0 000 .805.142.142 0 00.078.145 1.33 1.33 0 00.1.168l5.59 6.708a1.119 1.119 0 101.722-1.431l-4.059-4.871h10.325a1.118 1.118 0 000-2.236z"
          data-name="Path 434"
          transform="translate(2203 1129.001)"
        ></path>
        <path
          d="M0 12.858V1.118a1.118 1.118 0 012.236 0v11.74a1.118 1.118 0 11-2.236 0z"
          data-name="Union 2"
          transform="translate(2224.889 1135.118)"
        ></path>
      </g>
      <path
        fill="none"
        d="M0 0H24V24H0z"
        data-name="Rectangle 535"
        transform="rotate(180 12 12)"
      ></path>
    </svg>
  );
}

export default IconExpandMenuRight;
