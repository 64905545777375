import useTranslations from "@/hooks/useTranslations";
import React from "react";

export default function ConversationStatus(props: { status: string }) {
  const translation = useTranslations();
  return (
    <div className="flex flex-end pr-4 ml-auto">
      {props.status == "Open" && (
        <div className="h-6 px-2 py-1 bg-cornflower-blue-100 rounded-[5px] border border-cornflower-blue-100 justify-start items-center gap-2 flex text-slate-gray-800 text-xs  font-['Rubik'] leading-none">
          {translation.key("open")}
        </div>
      )}
      {props.status == "Resolved" && (
        <div className="h-6 px-2 py-1 bg-success-green-100 rounded-[5px] border border-success-green-100 justify-start items-center gap-2 flex text-slate-gray-800 text-xs font-['Rubik'] leading-none">
          {translation.key("resolved")}
        </div>
      )}
    </div>
  );
}
