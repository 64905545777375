import React from "react";
import { IconProps } from "./types";

function IconPersonGroupTransfer(props: IconProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="25"
      viewBox="0 0 25 25"
      {...props}
    >
      <g fill="currentColor" transform="translate(.5 .5)">
        <path
          d="M-1356.755 147.451a.713.713 0 01.158-.995l3.8-2.763-1.89-.478a.71.71 0 01-.437-.327.705.705 0 01-.078-.541.716.716 0 01.271-.4.711.711 0 01.6-.114l3.385.857a.751.751 0 01.1.04.335.335 0 01.114.062.724.724 0 01.167.163.717.717 0 01.113.24.38.38 0 01.01.066v.023a.839.839 0 01.01.117v.022l-.23 3.464a.711.711 0 01-.292.53.718.718 0 01-.466.135.715.715 0 01-.665-.758l.129-1.946-3.8 2.762a.71.71 0 01-.418.136.712.712 0 01-.581-.295z"
          data-name="Union 2"
          transform="translate(1374.432 -141.811)"
        ></path>
        <path
          d="M12.489 12.675L10.818 11.3a.8.8 0 00-1.13.111.806.806 0 00.112 1.133l1.671 1.371A1.4 1.4 0 0111.984 15v5.324h1.606V15a3 3 0 00-1.1-2.326"
          data-name="Path 2019"
          transform="translate(-.941 1.275)"
        ></path>
        <path
          d="M3.9 11.413a.8.8 0 00-.542-.289.779.779 0 00-.587.179L1.1 12.675A3 3 0 000 15v5.324h1.607V15a1.4 1.4 0 01.513-1.086l1.671-1.373a.8.8 0 00.11-1.13"
          data-name="Path 2020"
          transform="translate(0 1.275)"
        ></path>
        <path
          d="M6.55 1.788a4.274 4.274 0 104.274 4.274A4.279 4.279 0 006.55 1.788m2.668 4.274A2.668 2.668 0 116.55 3.395a2.67 2.67 0 012.668 2.667"
          data-name="Path 2018"
          transform="translate(-.225 2.198)"
        ></path>
        <path
          d="M22.451 15.338l-1.671-1.373a.8.8 0 00-1.13.111.806.806 0 00.111 1.131l1.671 1.371a1.4 1.4 0 01.514 1.087v5.324h1.606v-5.324a3 3 0 00-1.1-2.326"
          data-name="Path 2023"
          transform="translate(-1.927 1.011)"
        ></path>
        <path
          d="M16.512 4.451a4.274 4.274 0 104.274 4.274 4.279 4.279 0 00-4.274-4.274m2.668 4.274a2.668 2.668 0 11-2.668-2.667 2.671 2.671 0 012.668 2.667"
          data-name="Path 2021"
          transform="translate(-1.211 1.934)"
        ></path>
      </g>
    </svg>
  );
}

export default IconPersonGroupTransfer;
