import React from "react";
import { IconProps } from "./types";

function IconSearch(props: IconProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      {...props}
    >
      <path fill="none" d="M0 0H24V24H0z" data-name="Rectangle 176"></path>
      <path
        fill="currentColor"
        d="M16 14.591l-3.321-3.321a6.89 6.89 0 001.409-4.226A7 7 0 007.044 0 7 7 0 000 7.044a7 7 0 007.044 7.044 6.89 6.89 0 004.226-1.409L14.591 16zM2.013 7.044a4.983 4.983 0 015.031-5.031 4.983 4.983 0 015.031 5.031 4.983 4.983 0 01-5.031 5.031 4.983 4.983 0 01-5.031-5.031z"
        data-name="Path 99"
        transform="translate(4 4)"
      ></path>
    </svg>
  );
}

export default IconSearch;
