import { Link } from "react-router-dom";
import React from "react";
import useTranslations from "@/hooks/useTranslations";

interface MenuLinkProps {
  to: string;
  icon: React.ComponentType<React.SVGProps<SVGSVGElement>>;
  label?: React.ReactNode;
  onClose?: () => void;
  className?: string;
}

export default function MenuLink(props: MenuLinkProps) {
  const {
    to,
    icon: Icon,
    label,
    onClose,
    className = "flex items-center h-12 hover:bg-slate-gray-200 rounded pr-4 py-2 my-4"
  } = props;

  const translation = useTranslations();

  const renderLabel =
    typeof label === "string" ? translation.key(label) : label;

  return (
    <Link to={to} onClick={onClose} className={`${className}`}>
      <Icon className="h-6 w-6" />
      <span className="ml-2 flex-1">{renderLabel}</span>
    </Link>
  );
}
