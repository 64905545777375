import { Tag } from "@/types";
import { useEffect, useState } from "react";
import useApi from "./useApi";
import useSubscribe from "./useSubscribe";
import { set } from "lodash";

export default function useContactTags(contactId?: number) {
  const [all, setAll] = useState<Tag[]>([]);
  const [selected, setSelected] = useState<Tag[]>([]);
  const [saving, setSaving] = useState(false);
  const api = useApi();
  const subscribe = useSubscribe();

  useEffect(() => {
    async function fetchAll() {
      const resp = await api.messaging.get("Tags/Contacts");
      setAll(await resp.json());
    }

    fetchAll();
  }, [api.messaging]);

  useEffect(() => {
    async function fetchSelected() {
      if (!contactId) {
        return;
      }
      const resp = await api.messaging.get(
        `TagAssociation/Contact/Selected?contactId=${contactId}`
      );
      setSelected(await resp.json());
    }

    fetchSelected();
  }, [api.messaging, contactId]);

  async function create(label: string) {
    const resp = await api.messaging.post("Tags/Contact/Create", {
      name: label
    });

    const tag = await resp.json();
    setAll([...all, tag]);
    return tag;
  }

  async function update(tag: Tag) {
    const resp = await api.messaging.put(`Tags/Contact/Update`, {
      id: tag.id,
      name: tag.name,
      color: tag.color
    });

    const updatedTag = await resp.json();
    setAll(all.map((t) => (t.id === updatedTag.id ? updatedTag : t)));
    return updatedTag;
  }

  function save(tags: Tag[]) {
    setSaving(true);
    if (!contactId) {
      return;
    }
    setSelected([...selected, ...tags]);

    api.messaging.post("TagAssociation/Contact/Associate", {
      contactId,
      contactTags: tags
    });
    setSaving(false);
  }

  function remove(tag: Tag) {
    if (!contactId) {
      return;
    }
    setSelected(selected.filter((t) => t.id !== tag.id));

    api.messaging.post("TagAssociation/Contact/Remove", {
      contactId,
      tagId: tag.id
    });
  }

  useEffect(
    () =>
      subscribe("contact-tags-updated", (message) => {
        setAll(message.allTags);

        if (message.contactId === contactId) {
          setSelected(message.selectedTags);
        }
      }),
    [contactId, subscribe]
  );

  return { all, selected, save, remove, create, update, saving };
}
