import React from "react";
import format from "date-fns/format";

interface Props {
  isoDate: string;
}

export default function FormattedTime(props: Props) {
  const startDate = format(new Date(props.isoDate), "M/d/yyyy");
  return <>{startDate}</>;
}
